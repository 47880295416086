/**
 * Custom hook for standardizing tables across the app using Material React Table.
 *
 * Provides:
 * - Consistent styling and behavior for all tables
 * - Customizable top toolbar (search, filters, column visibility)
 * - Customizable bottom toolbar (pagination controls)
 * - Common features: sorting, row expansion, selection, etc.
 * - Setting up common defaults for all tables
 *
 * Note: This NEEDS to be a hook since it's the only way to customize the top and bottom bar
 */

import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Select,
  Typography
} from '@mui/material';
import {
  type MRT_ColumnDef,
  type MRT_RowData,
  type MRT_TableInstance,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_Updater,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  type MRT_ExpandedState
} from 'material-react-table';
import { useState } from 'react';

const TableActions = ({ table }: { table: any }): React.ReactElement => {
  const [visibilityMenuAnchor, setVisibilityMenuAnchor] = useState<null | HTMLElement>(null);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const [pendingVisibility, setPendingVisibility] = useState<Record<string, boolean>>({});

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    const currentVisibility: Record<string, boolean> = {};
    table.getAllLeafColumns().forEach((column: any) => {
      currentVisibility[column.id] = column.getIsVisible();
    });
    setPendingVisibility(currentVisibility);
    setVisibilityMenuAnchor(event.currentTarget);
  };

  const handleVisibilityChange = (columnId: string): void => {
    setPendingVisibility((prev) => ({
      ...prev,
      [columnId]: !prev[columnId]
    }));
  };

  const handleSave = (): void => {
    // Apply pending changes
    Object.entries(pendingVisibility).forEach(([columnId, isVisible]) => {
      const column = table.getColumn(columnId);
      if (column != null && column.getIsVisible() !== isVisible) {
        column.toggleVisibility();
      }
    });
    setVisibilityMenuAnchor(null);
  };

  const handleCancel = (): void => {
    setVisibilityMenuAnchor(null);
  };

  const handleHideAll = (): void => {
    const newVisibility: Record<string, boolean> = {};
    table.getAllLeafColumns().forEach((column: any) => {
      newVisibility[column.id] = false;
    });
    setPendingVisibility(newVisibility);
  };

  const handleShowAll = (): void => {
    const newVisibility: Record<string, boolean> = {};
    table.getAllLeafColumns().forEach((column: any) => {
      newVisibility[column.id] = true;
    });
    setPendingVisibility(newVisibility);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <IconButton
        onClick={(event) => {
          setActionsMenuAnchor(event.currentTarget);
        }}
        sx={{ borderRadius: '4px', '&:hover': { backgroundColor: '#E0E0E0' } }}
      >
        <Icon icon="bx:filter" />
      </IconButton>
      <MuiMenu
        anchorEl={actionsMenuAnchor}
        open={Boolean(actionsMenuAnchor)}
        onClose={() => {
          setActionsMenuAnchor(null);
        }}
      ></MuiMenu>
      <IconButton
        onClick={handleMenuOpen}
        sx={{
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
          '&:hover': { backgroundColor: 'transparent' }
        }}
      >
        <Icon icon="bx:columns" />
      </IconButton>
      <MuiMenu anchorEl={visibilityMenuAnchor} open={Boolean(visibilityMenuAnchor)} onClose={handleCancel}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 6, padding: '8px 16px' }}
        >
          <Typography variant="subtitle1">Customize columns</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCancel} size="small" sx={{ padding: 0 }}>
              <Icon icon="mdi:close" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <MuiMenuItem onClick={handleHideAll}>Hide All</MuiMenuItem>
          <MuiMenuItem onClick={handleShowAll}>Show All</MuiMenuItem>
        </Box>

        {table.getAllLeafColumns().map((column: any) => (
          <MuiMenuItem
            key={column.id}
            sx={{ padding: '0 16px' }}
            onClick={(e) => {
              e.preventDefault(); // Prevent MenuItem click from interfering
              handleVisibilityChange(column.id);
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={pendingVisibility[column.id] ?? column.getIsVisible()}
                  onChange={() => {
                    handleVisibilityChange(column.id);
                  }}
                  size="small"
                  sx={{ color: '#BED903', '&.Mui-checked': { color: '#BED903' } }}
                />
              }
              label={column.columnDef.header}
            />
          </MuiMenuItem>
        ))}
        <Divider />
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1, padding: '8px 16px' }}
        >
          <MuiMenuItem
            onClick={handleCancel}
            sx={{ borderRadius: '6px', border: '1px solid #E0E0E0', padding: '4px 24px', fontWeight: 600 }}
          >
            Cancel
          </MuiMenuItem>
          <MuiMenuItem
            onClick={handleSave}
            sx={{ borderRadius: '6px', padding: '4px 24px', background: '#BED903', fontWeight: 600 }}
          >
            Save
          </MuiMenuItem>
        </Box>
      </MuiMenu>
    </Box>
  );
};

interface UseCustomMaterialTableProps<TData extends MRT_RowData> {
  columns: Array<MRT_ColumnDef<TData>>;
  data: TData[];
  rowCount: number;
  isLoading: boolean;
  isFetching: boolean;
  pagination: MRT_PaginationState;
  setPagination: (pagination: MRT_PaginationState) => void;
  onGlobalFilterChange?: (filter: string) => void;
  onSortingChange?: (sorting: Array<{ id: string; desc: boolean }>) => void;
  paginationSizes?: number[];
  enableExpanding?: boolean;
  getSubRows?: (row: TData) => any[];
  enableRowSelection?: boolean;
  enableMultiRowSelection?: boolean;
  enablePagination?: boolean;
  enableColumnFilters?: boolean;
  getRowId?: (row: TData) => string;
  renderDetailPanel?: ({ row }: { row: any }) => React.ReactNode;
  muiTableBodyRowProps?: ({ row }: { row: any }) => Record<string, any>;
  state?: {
    pagination: MRT_PaginationState;
    sorting?: Array<{ id: string; desc: boolean }>;
    globalFilter?: string;
  };
  positionActionsColumn?: 'first' | 'last';
  initialExpanded?: boolean;
  displayColumnDefOptions?: {
    'mrt-row-expand'?: {
      enableColumnActions?: boolean;
      enableSorting?: boolean;
      size?: number;
      muiTableHeadCellProps?: {
        align?: 'right' | 'left' | 'center';
      };
      muiTableBodyCellProps?: {
        align?: 'right' | 'left' | 'center';
      };
    };
  };
}

export const useCustomMaterialTable = <TData extends MRT_RowData & Record<string, any>>({
  columns,
  data,
  rowCount,
  isLoading,
  isFetching,
  pagination,
  setPagination,
  onGlobalFilterChange,
  onSortingChange,
  paginationSizes = [25, 50, 100, 300],
  enableExpanding = false,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  getSubRows,
  enablePagination = true,
  enableColumnFilters = false,
  getRowId = (row) => row.id,
  renderDetailPanel,
  muiTableBodyRowProps = ({ row }) => ({
    sx: {
      backgroundColor: row.depth > 0 ? '#FAFAFA' : 'inherit',
      '&:hover': {
        backgroundColor: row.depth > 0 ? '#F5F5F5' : '#F8F8F8'
      },
      transition: 'background-color 0.2s ease'
    }
  }),
  state,
  positionActionsColumn = 'first',
  initialExpanded = true,
  displayColumnDefOptions
}: UseCustomMaterialTableProps<TData>): {
  table: MRT_TableInstance<TData>;
  pagination: MRT_PaginationState;
  setPagination: (pagination: MRT_PaginationState) => void;
} => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);

  const handleGlobalFilterChange = (filter: string): void => {
    setGlobalFilter(filter);
    onGlobalFilterChange?.(filter);
  };

  const handlePaginationChange = (updater: MRT_PaginationState | MRT_Updater<MRT_PaginationState>): void => {
    const newPagination = typeof updater === 'function' ? updater(pagination) : updater;
    setPagination(newPagination);
  };

  const handleSortingChange = (updater: MRT_SortingState | MRT_Updater<MRT_SortingState>): void => {
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
    setSorting(newSorting);
    onSortingChange?.(newSorting);
  };

  const table = useMaterialReactTable({
    enableRowSelection,
    enableMultiRowSelection,
    enablePagination,
    enableColumnFilters,
    getRowId,
    positionActionsColumn,
    enableColumnOrdering: true,
    columns: columns as unknown as Array<MRT_ColumnDef<TData, any>>,
    data,
    initialState: {
      showGlobalFilter: true,
      showColumnFilters: false,
      density: 'compact',
      expanded: initialExpanded as unknown as MRT_ExpandedState,
      columnVisibility: {
        'mrt-row-select': false
      }
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: 'calc(100vh - 300px)',
        overflow: 'auto',
        // we don't use the smoothScrollbarStyles const because it gets too chonky
        '&::-webkit-scrollbar': {
          height: '8px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#E0E0E0',
          borderRadius: '4px'
        }
      }
    },
    muiTablePaperProps: {
      elevation: 0
    },
    muiPaginationProps: {
      rowsPerPageOptions: paginationSizes,
      showFirstButton: false,
      showLastButton: false
    },
    enableExpanding,
    manualFiltering: true,
    onGlobalFilterChange: handleGlobalFilterChange,
    manualPagination: true,
    onPaginationChange: handlePaginationChange,
    manualSorting: true,
    onSortingChange: handleSortingChange,
    state: {
      ...(globalFilter !== undefined && { globalFilter }),
      ...(pagination !== undefined && { pagination }),
      ...(sorting !== undefined && { sorting }),
      ...(isLoading !== undefined && { isLoading: isLoading || isFetching }),
      ...(columns?.length > 0 && {}),
      ...state
    },
    rowCount,
    enableHiding: true,
    enableTopToolbar: onGlobalFilterChange != null || onSortingChange != null,
    ...(getSubRows != null ? { getSubRows } : {}),
    displayColumnDefOptions,
    muiTableBodyRowProps,
    renderTopToolbar: ({ table }) => {
      return (
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              backgroundColor: '#F7F7F7',
              '& .MuiOutlinedInput-root': {
                borderColor: 'transparent',
                borderRadius: '4px'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent'
              },
              '& .MuiIconButton-sizeSmall': {
                color: 'black !important'
              }
            }}
          />
          <TableActions table={table} />
        </Box>
      );
    },
    renderBottomToolbar: ({ table }) => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderTop: '1px solid #e0e0e0'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, color: '#7C7E7E' }}>
            <IconButton
              disabled={!table.getCanPreviousPage()}
              onClick={() => {
                table.previousPage();
              }}
            >
              &lt;
            </IconButton>
            <Typography>Previous</Typography>

            {(() => {
              const currentPage = table.getState().pagination?.pageIndex;
              const totalPages = table.getPageCount();
              const pagesToShow = 2; // Show 2 pages before and after current page

              let startPage = Math.max(0, currentPage - pagesToShow);
              let endPage = Math.min(totalPages - 1, currentPage + pagesToShow);

              // If we're near the start, show first 5 pages
              if (currentPage < pagesToShow) {
                startPage = 0;
                endPage = Math.min(4, totalPages - 1);
              }

              // If we're near the end, show last 5 pages
              if (currentPage > totalPages - pagesToShow - 1) {
                startPage = Math.max(totalPages - 5, 0);
                endPage = totalPages - 1;
              }

              const pages: number[] = [];

              // Always add first page
              pages.push(0);

              // Add ellipsis after first page if needed
              if (startPage > 1) {
                pages.push(-1);
              }

              // Add pages in range (skip first page if it's in range since we already added it)
              for (let i = startPage; i <= endPage; i++) {
                if (i !== 0) {
                  pages.push(i);
                }
              }

              // Add ellipsis and last page if needed
              if (endPage < totalPages - 1) {
                pages.push(-1); // Add ellipsis
                pages.push(totalPages - 1); // Add last page
              }

              return pages.map((pageIndex) =>
                pageIndex < 0 ? (
                  <Typography key={pageIndex} sx={{ color: '#7C7E7E' }}>
                    ...
                  </Typography>
                ) : (
                  <Button
                    key={pageIndex}
                    variant={pageIndex === currentPage ? 'contained' : 'text'}
                    onClick={() => {
                      table.setPageIndex(pageIndex);
                    }}
                    sx={{
                      borderRadius: '50%',
                      minWidth: '32px',
                      width: '32px',
                      height: '32px',
                      padding: 0,
                      fontSize: '0.875rem',
                      backgroundColor: pageIndex === currentPage ? '#BED903' : 'transparent',
                      color: pageIndex === currentPage ? '#000' : 'inherit',
                      '&:hover': {
                        backgroundColor: pageIndex === currentPage ? '#BED903' : 'rgba(190, 217, 3, 0.1)'
                      }
                    }}
                  >
                    {pageIndex + 1}
                  </Button>
                )
              );
            })()}

            <Typography>Next</Typography>
            <IconButton
              disabled={!table.getCanNextPage()}
              onClick={() => {
                table.nextPage();
              }}
            >
              &gt;
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ color: '#7C7E7E' }}>Items / page</Typography>
            <Select
              value={pagination.pageSize}
              onChange={(e) => {
                setPagination({ ...pagination, pageIndex: 0, pageSize: parseInt(e.target.value as string) });
              }}
              sx={{
                height: '32px',
                '.MuiSelect-select': {
                  padding: '4px 14px'
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  borderRadius: '6px'
                },
                backgroundColor: '#FAFAFA'
              }}
            >
              {paginationSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 2, color: '#7C7E7E' }}>
              <Typography>
                {`${table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}-${Math.min(
                  (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                  rowCount
                )} of ${rowCount} items`}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    },
    muiTableProps: {
      sx: {
        minWidth: '750px',
        '& .MuiTableHead-root': {
          position: 'sticky',
          top: 0,
          zIndex: 2,
          backgroundColor: 'white'
        }
      }
    },
    renderDetailPanel
  });

  return {
    table: table as unknown as MRT_TableInstance<TData>,
    pagination,
    setPagination
  };
};
