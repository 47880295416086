import {
  Button,
  IconButton,
  Flex,
  Heading,
  VStack,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAsyncJob } from '@hooks/useAsyncJob';
import EditableField from '@features/profile-overview/components/EditableField/';
import EditableAddress from '@features/profile-overview/components/EditableField/EditableAddress';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useGetGendersQuery,
  useGetCountriesQuery,
  useGetEntityTypesQuery,
  useGetNatureOfEmploymentQuery,
  useGetIdTypesQuery,
  useUpdateProfileMutation,
  useNewProfileInquiryMutation
} from '@services/canaria.services';
import { scheduleScreening, useScreeningScheduled } from '@features/profile/profile_slice';
import { type AppDispatch } from '../../../state/store';
import { JOB_STATUS } from '@features/async-jobs/asyncJobsSlice';

const MotionVStack = motion.create(VStack as any);

export interface PrimaryPartyInformationRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

const RESCREEN_TRIGGER_FIELDS = [
  'fullLegalName',
  'dbaTradeName',
  'dateOfBirth',
  'dateOfEstablishment',
  'placeOfEstablishment',
  'citizenship',
  'placeOfBirth',
  'idNumber',
  'uniqueIdentificationNumber'
];

const useProfileUpdateWithDelayedScreening = (profile: IProfile) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const [isRescreenAlertOpen, setIsRescreenAlertOpen] = useState(false);
  const toast = useToast();
  const [fieldToUpdate, setFieldToUpdate] = useState<string | null>(null);
  const [valueToUpdate, setValueToUpdate] = useState<any>(null);
  const [updateProfile] = useUpdateProfileMutation();

  const screeningScheduled = useScreeningScheduled(profile?.id ?? '');
  const dispatch = useDispatch<AppDispatch>();
  const handleFieldChange = (name: string) => async (value) => {
    // Check if field requires rescreening
    if (RESCREEN_TRIGGER_FIELDS.includes(name) && !screeningScheduled) {
      setFieldToUpdate(name);
      setValueToUpdate(value);
      setIsRescreenAlertOpen(true);
      return;
    }

    try {
      await updateProfile({
        orgId: activeOrgID ?? '',
        profileId: profile?.id ?? '',
        ...(screeningScheduled ? { skip_screening: true } : {}),
        [name]: value
      }).unwrap();

      toast({
        title: 'Field updated',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Error updating field',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setFieldToUpdate(null);
      setValueToUpdate(null);
    }
  };

  const handleConfirmUpdate = async (delayed: boolean) => {
    setIsRescreenAlertOpen(false);
    if (delayed && profile?.id) {
      dispatch(scheduleScreening(profile.id, 5 * 60 * 1000));
    }
    try {
      await updateProfile({
        orgId: activeOrgID ?? '',
        profileId: profile?.id ?? '',
        [fieldToUpdate as string]: valueToUpdate,
        ...(delayed ? { delayed: true } : {})
      }).unwrap();
      toast({
        title: delayed ? 'Field updated, rescreen scheduled in 5 minutes' : 'Field updated, rescreening now',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Error updating field',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setFieldToUpdate(null);
      setValueToUpdate(null);
    }
  };

  const handleCloseAlert = () => {
    setIsRescreenAlertOpen(false);
    setFieldToUpdate(null);
    setValueToUpdate(null);
  };

  return { handleFieldChange, handleConfirmUpdate, isRescreenAlertOpen, handleCloseAlert };
};

const RescreenAlertDialog = ({ isOpen, onClose, onConfirm, cancelRef }) => (
  <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
    <AlertDialogOverlay>
      <AlertDialogContent mx={4}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Rescreening Required
        </AlertDialogHeader>

        <AlertDialogBody>
          You are about to update a critical field. How would you like to proceed with the required rescreening?
        </AlertDialogBody>

        <AlertDialogFooter gap={3}>
          <Button ref={cancelRef} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => onConfirm(true)} variant="primary">
            Rescreen Later
          </Button>
          <Button onClick={() => onConfirm(false)} variant="primary">
            Rescreen now
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
);

const ProfileFields = ({ profile, handleFieldChange, fieldConfig }) => {
  return (
    <MotionVStack
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      spacing={3}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
    >
      {fieldConfig.map((field) => {
        const isCritical = RESCREEN_TRIGGER_FIELDS.includes(field.name);

        if (field.type === 'address') {
          return (
            <EditableAddress
              key={field.name}
              label={field.label}
              value={profile?.[field.name]}
              onConfirmChange={handleFieldChange(field.name)}
              isCritical={isCritical}
            />
          );
        }

        return (
          <EditableField
            key={field.name}
            label={field.label}
            value={field.valueSelector ? field.valueSelector(profile) : profile?.[field.name]}
            onConfirmChange={handleFieldChange(field.name)}
            type={field.type}
            options={field.options}
            isCritical={isCritical}
            placeholder={field.placeholder}
          />
        );
      })}
    </MotionVStack>
  );
};

const PrimaryPartyInformationEntity = ({ profile }) => {
  const { handleFieldChange, handleConfirmUpdate, isRescreenAlertOpen, handleCloseAlert } =
    useProfileUpdateWithDelayedScreening(profile);
  const { data: countries = [] } = useGetCountriesQuery(null);
  const { data: entityTypes = [] } = useGetEntityTypesQuery(null);
  const cancelRef = useRef(null);
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const fieldConfig = [
    { name: 'fullLegalName', label: 'Full Legal Name', type: 'text', placeholder: 'Enter Full Legal Name' },
    { name: 'dbaTradeName', label: 'DBA/Trade Name', type: 'text', placeholder: 'Enter DBA or Trade Name' },
    { name: 'email', label: 'Email', type: 'text', placeholder: 'Enter Email Address' },
    {
      name: 'entityFormationType',
      label: 'Entity Formation Type',
      type: 'select',
      options: entityTypes,
      placeholder: 'Select Entity Formation Type'
    },
    { name: 'natureOfBusiness', label: 'Nature of Business', type: 'text', placeholder: 'Enter Nature of Business' },
    {
      name: 'placeOfEstablishment',
      label: 'Place of Establishment',
      type: 'select',
      options: mappedCountries,
      valueSelector: (profile) => profile?.placeOfEstablishment?.id,
      placeholder: 'Select Place of Establishment'
    },
    {
      name: 'dateOfEstablishment',
      label: 'Date of Establishment',
      type: 'date',
      placeholder: 'Select Date of Establishment'
    },
    {
      name: 'uniqueIdentificationNumber',
      label: 'Unique Identification Number',
      type: 'text',
      placeholder: 'Enter Unique Identification Number'
    },
    {
      name: 'registeredBusinessAddress',
      label: 'Registered Business Address',
      type: 'address',
      placeholder: 'Enter Registered Business Address'
    },
    { name: 'mailingAddress', label: 'Mailing Address', type: 'address', placeholder: 'Enter Mailing Address' },
    { name: 'physicalAddress', label: 'Physical Address', type: 'address', placeholder: 'Enter Physical Address' },
    { name: 'companyWebsite', label: 'Company Website', type: 'text', placeholder: 'Enter Company Website URL' },
    {
      name: 'pointOfContactName',
      label: 'Point of Contact Name',
      type: 'text',
      placeholder: 'Enter Point of Contact Name'
    },
    {
      name: 'ownershipPercentage',
      label: 'Ownership Percentage',
      type: 'text',
      placeholder: 'Enter Ownership Percentage'
    }
  ];

  return (
    <>
      <RescreenAlertDialog
        isOpen={isRescreenAlertOpen}
        onClose={handleCloseAlert}
        onConfirm={handleConfirmUpdate}
        cancelRef={cancelRef}
      />
      <ProfileFields profile={profile} handleFieldChange={handleFieldChange} fieldConfig={fieldConfig} />
    </>
  );
};

const PrimaryPartyInformationIndividual = ({ profile }) => {
  const { handleFieldChange, handleConfirmUpdate, isRescreenAlertOpen, handleCloseAlert } =
    useProfileUpdateWithDelayedScreening(profile);
  const { data: countries = [] } = useGetCountriesQuery(null);
  const { data: genders = [] } = useGetGendersQuery({});
  const { data: idTypes = [] } = useGetIdTypesQuery({});
  const { data: natureOfEmployment = [] } = useGetNatureOfEmploymentQuery({});
  const cancelRef = useRef(null);
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const fieldConfig = [
    { name: 'fullLegalName', label: 'Full Legal Name', type: 'text', placeholder: 'Enter Full Legal Name' },
    { name: 'email', label: 'Email', type: 'text', placeholder: 'Enter Email Address' },
    {
      name: 'gender',
      label: 'Gender',
      type: 'select',
      options: genders,
      valueSelector: (profile) => profile?.gender?.value,
      placeholder: 'Select Gender'
    },
    { name: 'dateOfBirth', label: 'Date of Birth', type: 'date', placeholder: 'Select Date of Birth' },
    {
      name: 'citizenship',
      label: 'Citizenship',
      type: 'select',
      options: mappedCountries,
      valueSelector: (profile) => profile?.citizenship?.id,
      placeholder: 'Select Citizenship'
    },
    {
      name: 'countryOfResidence',
      label: 'Country of Residence',
      type: 'select',
      options: mappedCountries,
      valueSelector: (profile) => profile?.countryOfResidence?.id,
      placeholder: 'Select Country of Residence'
    },
    { name: 'state', label: 'State', type: 'text', placeholder: 'Enter State' },
    { name: 'city', label: 'City', type: 'text', placeholder: 'Enter City' },
    { name: 'postalCode', label: 'Postal Code', type: 'text', placeholder: 'Enter Postal Code' },
    { name: 'address', label: 'Address', type: 'address' },
    {
      name: 'placeOfBirth',
      label: 'Place of Birth',
      type: 'select',
      options: mappedCountries,
      valueSelector: (profile) => profile?.placeOfBirth?.id,
      placeholder: 'Select Place of Birth'
    },
    {
      name: 'idIssuer',
      label: 'ID Issuer',
      type: 'select',
      options: mappedCountries,
      valueSelector: (profile) => profile?.idIssuer?.id,
      placeholder: 'Select ID Issuer'
    },
    {
      name: 'idType',
      label: 'ID Type',
      type: 'select',
      options: idTypes,
      valueSelector: (profile) => profile?.idType?.value,
      placeholder: 'Select ID Type'
    },
    { name: 'idNumber', label: 'ID Number', type: 'text', placeholder: 'Enter ID Number' },
    {
      name: 'natureOfEmployment',
      label: 'Nature of Employment',
      type: 'select',
      options: natureOfEmployment,
      placeholder: 'Select Nature of Employment'
    },
    {
      name: 'controlPersonTitle',
      label: 'Control Person Title',
      type: 'text',
      placeholder: 'Enter Control Person Title'
    },
    {
      name: 'ownershipPercentage',
      label: 'Ownership Percentage',
      type: 'text',
      placeholder: 'Enter Ownership Percentage'
    }
  ];

  return (
    <>
      <RescreenAlertDialog
        isOpen={isRescreenAlertOpen}
        onClose={handleCloseAlert}
        onConfirm={handleConfirmUpdate}
        cancelRef={cancelRef}
      />
      <ProfileFields profile={profile} handleFieldChange={handleFieldChange} fieldConfig={fieldConfig} />
    </>
  );
};

interface PrimaryPartyInformationProps {
  handleSectionHover: (section: string) => void;
  profile: IProfile;
  profileId: string;
}

const PrimaryPartyInformation = forwardRef<PrimaryPartyInformationRef, PrimaryPartyInformationProps>(
  ({ profile, handleSectionHover, profileId }, ref) => {
    const activeOrgID = useSelector(selectActiveOrgID);
    const [isOpen, setIsOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const toast = useToast();
    const newProfileInquiryHook = useNewProfileInquiryMutation();

    const { isLoading: isJobLoading, mutate: rescreenProfile } = useAsyncJob({
      mutationHook: newProfileInquiryHook,
      args: { orgId: activeOrgID ?? '', profileId },
      onComplete: (job) => {
        if (job.status === JOB_STATUS.FAILED) {
          toast({
            title: 'Error rescreening profile',
            description: 'Please try again later',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        } else {
          toast({
            title: 'Profile rescreened successfully',
            status: 'success',
            duration: 3000,
            isClosable: true
          });
        }
      }
    });

    useImperativeHandle(ref, () => ({
      expand: () => {
        setIsOpen(true);
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        containerRef.current?.scrollIntoView(options);
      }
    }));

    const handleRescreen = async () => {
      try {
        await rescreenProfile();
      } catch (error) {
        toast({
          title: 'Error initiating profile rescreening',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    };

    return (
      <VStack
        ref={containerRef}
        spacing={3}
        align="stretch"
        m={3}
        p={3}
        boxShadow="md"
        bg="white"
        w="100%"
        layerStyle="container"
        onMouseEnter={() => {
          handleSectionHover('Primary party information');
        }}
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Heading as="h1" size="md" textAlign="left">
            Primary Party Information
          </Heading>
          <Flex alignItems="center">
            <Button
              size="sm"
              color="#2081C3"
              bg="transparent"
              opacity={isJobLoading ? 0.7 : 1}
              isDisabled={isJobLoading}
              onClick={handleRescreen}
            >
              <Icon icon="bx:refresh" className={isJobLoading ? 'icon-spin' : ''} />
              {isJobLoading ? 'Rescreening...' : 'Rescreen'}
            </Button>
            <Link to={`/dashboard/profiles/${profileId}/screenings`}>
              <Box position="relative">
                <Button size="sm" color="#2081C3" bg="transparent">
                  <Icon icon="bx:search" />
                  Screening hits
                </Button>
                {profile.totalUnresolvedHits > 0 && (
                  <Box
                    position="absolute"
                    top="-4px"
                    right="2px"
                    bg="#D72638"
                    color="white"
                    borderRadius="full"
                    w="16px"
                    h="16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="10px"
                    fontWeight="bold"
                  >
                    {profile.totalUnresolvedHits}
                  </Box>
                )}
              </Box>
            </Link>
            <IconButton
              aria-label="Toggle Primary Party Information"
              icon={isOpen ? <Icon icon="fa6-solid:chevron-up" /> : <Icon icon="fa6-solid:chevron-down" />}
              size="sm"
              variant="ghost"
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            />
          </Flex>
        </Flex>
        <AnimatePresence>
          {isOpen && (
            <MotionVStack
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              spacing={3}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              {profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
                <PrimaryPartyInformationIndividual profile={profile} />
              ) : (
                <PrimaryPartyInformationEntity profile={profile} />
              )}
            </MotionVStack>
          )}
        </AnimatePresence>
      </VStack>
    );
  }
);

PrimaryPartyInformation.displayName = 'PrimaryPartyInformation';

export default PrimaryPartyInformation;
