import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useState, useMemo } from 'react';

interface RiskLevel {
  amount: number;
  percentage: number;
}

interface RiskLevels {
  Low: RiskLevel;
  Medium: RiskLevel;
  High: RiskLevel;
  Severe: RiskLevel;
}

interface RiskBarProps {
  risks: RiskLevels;
}

const RiskBar: React.FC<RiskBarProps> = ({ risks }) => {
  const riskLevels = ['Low', 'Medium', 'High', 'Severe'] as const;
  const SMALL_PERCENTAGE_THRESHOLD = 6;
  const [openTooltips, setOpenTooltips] = useState<Set<string>>(new Set());
  const [hoveredTooltip, setHoveredTooltip] = useState<string | null>(null);

  // Calculate visible risk levels and their cumulative positions
  const visibleRiskInfo = useMemo(() => {
    const visible = riskLevels.filter(level => risks[level].percentage > 0);
    let cumulative = 0;
    return visible.map((level, index) => {
      const start = cumulative;
      cumulative += risks[level].percentage;
      return {
        level,
        start,
        end: cumulative,
        isFirst: index === 0,
        isLast: index === visible.length - 1,
      };
    });
  }, [risks]);

  // Determine tooltip placement based on position and neighbors
  const getTooltipPlacement = (level: string) => {
    const currentIndex = visibleRiskInfo.findIndex(info => info.level === level);
    if (currentIndex === -1) return 'top';

    const info = visibleRiskInfo[currentIndex];
    
    // If it's the only segment, place on top
    if (visibleRiskInfo.length === 1) return 'top';
    
    // For first segment
    if (info.isFirst) return 'top-start';
    
    // For last segment
    if (info.isLast) return 'top-end';
    
    // Alternate between top and bottom for middle segments
    return currentIndex % 2 === 0 ? 'top' : 'bottom';
  };

  // Calculate tooltip offset based on placement
  const getTooltipOffset = (placement: string): [number, number] => {
    switch (placement) {
      case 'top-start': return [-10, 10];
      case 'top-end': return [10, 10];
      case 'top': return [0, 10];
      case 'bottom': return [0, 10];
      default: return [0, 10];
    }
  };

  return (
    <Box position="relative" overflow="visible">
      <Flex h="32px" w="100%" overflow="visible">
        {riskLevels.map((level) => {
          const risk = risks[level];
          if (risk.percentage === 0) return null;

          const isSmallPercentage = risk.percentage < SMALL_PERCENTAGE_THRESHOLD;
          const placement = getTooltipPlacement(level);

          return (
            <Tooltip
              id={`risk-bar-tooltip-${level}`}
              key={level}
              label={
                <Box>
                  <Text fontWeight="bold" color="black">
                    {level}
                  </Text>
                  <Text color="black">
                    ${risk.amount.toLocaleString()} ({risk.percentage.toFixed(1)}%)
                  </Text>
                </Box>
              }
              hasArrow
              isOpen={openTooltips.has(level) || hoveredTooltip === level}
              placement={placement}
              offset={getTooltipOffset(placement)}
              bg={`risk.solid.${level.toUpperCase()}`}
              color="white"
            >
              <Box
                onClick={() => {
                  setOpenTooltips((prev) => {
                    const newSet = new Set(prev);
                    if (newSet.has(level)) {
                      newSet.delete(level);
                    } else {
                      newSet.add(level);
                    }
                    return newSet;
                  });
                }}
                onMouseEnter={() => setHoveredTooltip(level)}
                onMouseLeave={() => setHoveredTooltip(null)}
                bg={`risk.light.${level.toUpperCase()}`}
                borderColor={`risk.${level.toUpperCase()}`}
                borderWidth="1px"
                w={`${risk.percentage}%`}
                minW={isSmallPercentage ? '8px' : '0'}
                h="100%"
                position="relative"
                cursor="pointer"
                _before={
                  isSmallPercentage
                    ? {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: '100%',
                        background: `repeating-linear-gradient(
                    -45deg,
                    var(--chakra-colors-risk-light-${level.toUpperCase()}),
                    var(--chakra-colors-risk-light-${level.toUpperCase()}) 2px,
                    var(--chakra-colors-risk-${level.toUpperCase()}) 2px,
                    var(--chakra-colors-risk-${level.toUpperCase()}) 4px
                  )`
                      }
                    : undefined
                }
              >
                {!isSmallPercentage && (
                  <Text
                    position="absolute"
                    inset={0}
                    px={2}
                    fontSize="xs"
                    color="gray.700"
                    fontWeight="medium"
                    lineHeight="32px"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    <strong>{level}</strong> ${risk.amount.toLocaleString()} ({risk.percentage.toFixed(1)}%)
                  </Text>
                )}
              </Box>
            </Tooltip>
          );
        })}
      </Flex>
    </Box>
  );
};

export default RiskBar;
