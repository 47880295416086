import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import WalletsPanel, { type WalletsPanelRef } from '@features/panels/walletsPanel/WalletsPanel.component';
import { defaultMaterialTheme } from '@utils/consts';
import { useSelector } from 'react-redux';
import { selectUser } from '@features/user-settings/userSlice';
import analyticsInstance from '@features/analytics/analytics';

const WalletsPage: React.FC = () => {
  const navigate = useNavigate();
  const walletsPanelRef = useRef<WalletsPanelRef>(null);
  const user = useSelector(selectUser);
  useEffect(() => {
    analyticsInstance.trackWalletsOpened(user);
  }, [user]);

  return (
    <>
      <Flex ml={10} mr={10} gap={4} alignItems="center" justifyContent="space-between">
        <Flex gap={4} alignItems="center">
          <Button
            px={0}
            onClick={() => {
              navigate(-1);
            }}
            variant="secondary"
          >
            <Icon icon="ion:arrow-back-outline" />
          </Button>
          <Heading as="h3" size="md">
            Wallets
          </Heading>
        </Flex>

        <Flex gap={4} alignItems="center">
          <Button
            leftIcon={<Icon icon="bx:export" />}
            aria-label="Export Selected Rows"
            onClick={() => walletsPanelRef.current?.exportSelectedRows()}
            variant="secondary"
          >
            Export Selected Rows
          </Button>
          <Button
            leftIcon={<Icon icon="bx:wallet" />}
            aria-label="Register New Wallet"
            onClick={() => walletsPanelRef.current?.openWalletModal()}
            variant="primary"
          >
            Register New Wallet
          </Button>
        </Flex>
      </Flex>

      <Box m={4} p={5} boxShadow="md" bg="white" layerStyle="container">
        <ThemeProvider theme={defaultMaterialTheme}>
          <Box overflowX="auto" width="100%">
            <WalletsPanel ref={walletsPanelRef} />
          </Box>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default WalletsPage;
