import { SUMMARY_TYPE, updateNotificationByJobId } from '@features/ai-chat/aiChatSlice';
import { updateJob, type JobState } from '@features/async-jobs/asyncJobsSlice';
import { selectAccessToken } from '@features/auth/authSlice';
import { selectActiveOrgID } from '@features/user-settings/userSlice';

import { BACKEND_URL, WS_URL } from '../env';
import { convertObjectKeysToCamelCase } from './utils';
import { store } from '../state/store';
interface JobNotification {
  type: string;
  message: JobState;
}

interface AiChatNotification {
  type: SUMMARY_TYPE;
  message: string;
  job_id: string;
  profile_id?: string;
  wallet_id?: string;
  status: string;
  content: string;
}

// Keep track of accumulated content for each job_id
const accumulatedContentByJobId: Record<string, string> = {};

const handleJobNotification = (notification: JobNotification): void => {
  store.dispatch(updateJob(convertObjectKeysToCamelCase(notification.message)));
};

const handleAiChatNotification = (notification: AiChatNotification): void => {
  const { job_id: jobId, content } = notification;

  // Initialize or update accumulated content for this job_id
  if (accumulatedContentByJobId[jobId] === undefined) {
    // First message for this job_id
    accumulatedContentByJobId[jobId] = content;
  } else {
    // we should only replace the content if the content is larger than the accumulatedContentByJobId[jobId]
    // since in theory it could come unordered
    if (content.length > accumulatedContentByJobId[jobId].length) {
      accumulatedContentByJobId[jobId] = content;
    }
  }

  // Use the new reducer with accumulated content
  store.dispatch(
    updateNotificationByJobId({
      job_id: notification.job_id,
      type: notification.type,
      id: notification.profile_id ?? notification.wallet_id ?? '',
      content: accumulatedContentByJobId[jobId],
      status: notification.status
    })
  );
};

const refreshTokenRequest = async (refresh): Promise<{ access: string; refresh: string }> => {
  try {
    const response = await fetch(`${BACKEND_URL}/api/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refresh })
    });

    if (!response.ok) {
      throw new Error(`Token refresh failed: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Token refresh error:', error);
    throw error;
  }
};

const keysToIgnore = ['connection_established', 'authentication_successful'];

class WebSocketService {
  private ws: WebSocket | null = null;
  private token: string | null = null;
  private refreshToken: string | null = null;
  private reconnectAttempts = 0;
  private readonly maxReconnectAttempts = 5;
  private reconnectTimeout: number | null = null;
  private isReconnecting = false; // to account for potential race conditions
  private isAuthenticated = false;
  private currentOrgId: string | undefined;
  public connectionState: 'connecting' | 'connected' | 'disconnected' = 'disconnected';

  constructor() {
    const state = store.getState();
    this.token = state.auth.access;
    this.refreshToken = state.auth.refresh;
    this.currentOrgId = selectActiveOrgID(state);

    // Subscribe to store changes to handle org changes
    store.subscribe(() => {
      const currentState = store.getState();
      const newOrgId = selectActiveOrgID(currentState);
      const newToken = selectAccessToken(currentState);
      if (newToken !== this.token) {
        this.token = newToken;
      }
      // Only subscribe if the org ID has actually changed
      if (
        newOrgId !== undefined &&
        newOrgId !== '' &&
        newOrgId !== this.currentOrgId &&
        this.connectionState === 'connected' &&
        this.isAuthenticated
      ) {
        this.currentOrgId = newOrgId;
        this.subscribeToOrganization(Number(newOrgId));
      }
    });
  }

  private subscribeToActiveOrgIfExists(): void {
    if (
      this.currentOrgId !== undefined &&
      this.currentOrgId !== '' &&
      this.connectionState === 'connected' &&
      this.isAuthenticated
    ) {
      this.subscribeToOrganization(Number(this.currentOrgId));
    }
  }

  connect(): void {
    if (this.connectionState === 'connecting' || this.connectionState === 'connected') {
      // eslint-disable-next-line no-console
      console.log('Already connected or connecting');
      return;
    }

    this.connectionState = 'connecting';
    this.isAuthenticated = false;

    const wsUrl = WS_URL;

    this.ws = new WebSocket(wsUrl);

    this.ws.onopen = (): void => {
      this.connectionState = 'connected';
      this.reconnectAttempts = 0;
      this.isReconnecting = false;

      if (this.token != null && this.ws != null) {
        this.ws.send(JSON.stringify({ token: this.token }));
      }
    };

    this.ws.onmessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'authentication_failed') {
          this.handleAuthFailure();
          return;
        }

        if (data.type === 'authentication_successful') {
          this.isAuthenticated = true;
          // Subscribe to active org after successful authentication
          this.subscribeToActiveOrgIfExists();
          return;
        }

        if (data.type === 'org_subscription_successful') {
          return;
        }

        if (keysToIgnore.includes(data.type)) {
          return;
        }

        // Check if this is an AI chat notification
        // The structure can be either data.message being the notification or data itself
        if (
          (data.message?.type != null &&
            [SUMMARY_TYPE.PROFILE_SUMMARY, SUMMARY_TYPE.WALLET_SUMMARY].includes(data.message.type)) ||
          (data.type != null && [SUMMARY_TYPE.PROFILE_SUMMARY, SUMMARY_TYPE.WALLET_SUMMARY].includes(data.type))
        ) {
          // Handle the notification based on where the data is located
          if (data.message?.type != null) {
            handleAiChatNotification(data.message);
          } else {
            handleAiChatNotification(data);
          }
          return;
        }

        // If not an AI chat notification, treat as a job notification
        handleJobNotification(data);
      } catch (error) {
        console.error('WebSocket message error:', error);
      }
    };

    this.ws.onclose = (event) => {
      this.connectionState = 'disconnected';
      // eslint-disable-next-line no-console
      console.log(`WebSocket closed: ${event.code} ${event.reason}`);

      if (this.ws !== null && !this.isReconnecting) {
        this.attemptReconnect();
      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  private async handleAuthFailure(): Promise<void> {
    try {
      if (this.refreshToken === null || this.refreshToken === '') {
        console.error('No refresh token available');
        return;
      }

      this.isReconnecting = true;

      const response = await refreshTokenRequest(this.refreshToken);
      this.token = response.access;
      this.refreshToken = response.refresh;

      this.disconnect();

      if (this.ws !== null && this.ws.readyState !== WebSocket.CLOSED) {
        this.ws.onclose = () => {
          this.connect();
        };
      } else {
        this.connect();
      }
    } catch (error) {
      this.isReconnecting = false;
      console.error('Failed to refresh authentication token:', error);
    }
  }

  private attemptReconnect(): void {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error('Max reconnect attempts reached');
      return;
    }

    this.reconnectAttempts++;
    const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);

    // eslint-disable-next-line no-console
    console.log(`Attempting to reconnect in ${delay}ms (attempt ${this.reconnectAttempts})`);

    this.reconnectTimeout = window.setTimeout(() => {
      this.connect();
    }, delay);
  }

  subscribeToOrganization(orgId: number): void {
    if (this.ws != null && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: 'subscribe_org',
          org_id: orgId
        })
      );
    }
  }

  send(message: any): void {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not connected');
    }
  }

  disconnect(): void {
    if (this.reconnectTimeout !== null) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }

    if (this.ws != null) {
      if (this.ws.readyState !== WebSocket.CLOSED) {
        this.ws.close();
      }
      this.ws = null;
    }

    this.connectionState = 'disconnected';
  }
}

export const websocketService = new WebSocketService();
