import { type ValidatorType } from '@components/FormField';

import EditableAddress from './EditableAddress';
import EditableDate from './EditableDate';
import EditableSelect from './EditableSelect';
import EditableText from './EditableText';

interface EditableFieldProps {
  label: string;
  value: string | number | Date | Array<string | number>;
  type: 'text' | 'select' | 'date' | 'multipleSelect' | 'address';
  options?: Array<{ name: string; value: string | number }>;
  onConfirmChange: (value: string | number | Date | Array<string | number>) => Promise<any>;
  isDisabled?: boolean;
  allowNull?: boolean;
  validate?: ValidatorType | ValidatorType[];
  isCritical?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  type,
  options = [],
  onConfirmChange,
  isDisabled,
  allowNull,
  validate,
  isCritical = false,
  hideLabel = false,
  placeholder
}) => {
  switch (type) {
    case 'text':
      return (
        <EditableText
          label={label}
          value={value as string}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          validate={validate}
          isCritical={isCritical}
          hideLabel={hideLabel}
          placeholder={placeholder}
        />
      );
    case 'select':
      return (
        <EditableSelect
          label={label}
          value={value as string}
          options={options}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          allowNull={allowNull}
          isCritical={isCritical}
          placeholder={placeholder}
        />
      );
    case 'date':
      return (
        <EditableDate 
          label={label} 
          value={value as Date} 
          onConfirmChange={onConfirmChange} 
          isDisabled={isDisabled}
          isCritical={isCritical}
          placeholder={placeholder}
        />
      );
    case 'multipleSelect':
      return (
        <EditableSelect
          label={label}
          value={value as string}
          options={options}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          allowNull={allowNull}
          isMulti
          isCritical={isCritical}
          placeholder={placeholder}
        />
      );
    case 'address':
      return <EditableAddress 
        label={label} 
        value={value as string} 
        onConfirmChange={onConfirmChange}
        isCritical={isCritical}
      />;
    default:
      return null;
  }
};

export default EditableField;
