import { Box, Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Icon as ChakraIcon, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import ExposureFlow from './ExposureFlow.component';
import RiskPercentBar from './RiskPercentBar.component';
import { capitalize } from '../utils';
import { useInfoPanel } from '../InfoPanelContext';
import { toCamelCase } from '@services/utils';

interface ExposureItem {
  riskCategory: string;
  riskLevel: 'Low' | 'Medium' | 'High' | 'Severe';
  riskType: string;
  totalUSDAmount: number;
  percentage: number;
}

export interface RiskThreshold {
  risk: 'LOW' | 'MEDIUM' | 'HIGH' | 'SEVERE';
  min: number;
  max: number;
  origin: string;
}

interface RiskThresholds {
  [key: string]: {
    // this string is the risk category
    counterparty?: RiskThreshold[];
    indirect?: RiskThreshold[];
    ownership?: RiskThreshold[];
  };
}

const RiskWeight = {
  Low: 1,
  Medium: 2,
  High: 3,
  Severe: 4
};

interface ExposureTableProps {
  title: string;
  items: ExposureItem[];
  type: 'ownership' | 'counterparty' | 'indirect';
  total?: {
    value: number;
    percent: number;
  };
  riskThresholds: RiskThresholds;
  riskFilter: string;
}

const ExposureTable: React.FC<ExposureTableProps> = ({ title, items, type, total, riskThresholds, riskFilter }) => {
  const { openInfo } = useInfoPanel();

  const filteredAndSortedItems = items
    .filter((item) => riskFilter === 'all' || item.riskLevel.toLowerCase() === riskFilter.toLowerCase())
    .sort((a, b) => {
      const riskWeightA = RiskWeight[a.riskLevel];
      const riskWeightB = RiskWeight[b.riskLevel];
      if (riskWeightA === riskWeightB) {
        return b.totalUSDAmount - a.totalUSDAmount;
      }
      return riskWeightB - riskWeightA;
    });

  const filteredTotal = filteredAndSortedItems.reduce(
    (acc, item) => ({
      value: acc.value + item.totalUSDAmount,
      percent: acc.percent + item.percentage
    }),
    { value: 0, percent: 0 }
  );

  const renderRiskBadge = (risk: string) => {
    return (
      <Box
        px={2}
        py={0.5}
        borderRadius="md"
        bg={`risk.${risk.toUpperCase()}`}
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
        display="inline-block"
      >
        {risk.toUpperCase()}
      </Box>
    );
  };
  const renderPercentBar = (riskCategory: string, percent: number, riskThresholds: RiskThresholds, riskLevel: string) => {
    const riskThreshold = riskThresholds[toCamelCase(riskCategory)];
    const ranges = (riskThreshold?.[type] ?? []).reduce(
      (acc, curr) => {
        acc[curr.risk] = { min: curr.min, max: curr.max };
        return acc;
      },
      {
        LOW: { min: 0, max: 0 },
        MEDIUM: { min: 0, max: 0 },
        HIGH: { min: 0, max: 0 },
        SEVERE: { min: 0, max: 0 }
      }
    );

    if (!ranges) {
      return null;
    }

    return <RiskPercentBar riskCategory={riskCategory} ranges={ranges} value={percent} thresholds={riskThreshold[type] ?? []} riskLevel={riskLevel} />;
  };

  return (
    <Box>
      <Flex align="center" gap={2} mb={4}>
        <Flex align="center" gap={2}>
          <Text fontWeight="medium">{title}</Text>
          <ExposureFlow type={type} />
        </Flex>
        <InfoIcon color="gray.400" fontSize="sm" cursor="pointer" onClick={() => openInfo(`${type}-exposure`)} />
      </Flex>

      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th width="30%">Category</Th>
            <Th width="15%">Risk</Th>
            <Th width="20%" isNumeric>
              Value
            </Th>
            <Th width="35%">Percent (%)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredAndSortedItems.map((item) => (
            <Tr key={item.riskCategory}>
              <Td>
                <Flex align="center" gap={2}>
                  {capitalize(item.riskCategory)}
                  <InfoIcon
                    color="gray.400"
                    fontSize="sm"
                    cursor="pointer"
                    onClick={() => openInfo(`category-${item.riskCategory.toLowerCase().split(' ').join('-')}`)}
                  />
                </Flex>
              </Td>
              <Td>{renderRiskBadge(item.riskLevel)}</Td>
              <Td isNumeric>${item.totalUSDAmount.toLocaleString()}</Td>
              <Td>{renderPercentBar(item.riskCategory, item.percentage, riskThresholds, item.riskLevel)}</Td>
            </Tr>
          ))}
          {filteredTotal.value > 0 && (
            <Tr fontWeight="bold">
              <Td>{type.toUpperCase()} TOTAL</Td>
              <Td></Td>
              <Td isNumeric>${filteredTotal.value.toLocaleString()}</Td>
              <Td>{Math.round(filteredTotal.percent * 100) / 100}%</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ExposureTable;
