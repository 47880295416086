import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectJobById,
  clearJobsByType,
  type JobState,
  updateJob,
  invalidateProfileInquiryTags,
  invalidateWalletInquiryTags,
  JOB_STATUS
} from '@features/async-jobs/asyncJobsSlice';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useWebSocket } from '@hooks/useWebSocket';
import { useGetJobStatusQuery } from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';

import { type RootState } from '../state/store';

const mutationToCacheBust = {
  newProfileInquiry: invalidateProfileInquiryTags,
  newWalletInquiry: invalidateWalletInquiryTags
};

interface UseAsyncJobOptions<TArgs> {
  mutationHook: any; // Note: I've again, tried almost everything to get this to work but let's just use any
  args?: TArgs;
  onComplete?: (result: JobState) => void;
  pollingInterval?: number;
  pollingTimeout?: number;
}

interface UseAsyncJobResult {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  mutate: (args?: any) => Promise<any>;
  latestJob: JobState | undefined;
}

export function useAsyncJob<TArgs = any>({
  mutationHook,
  args,
  onComplete,
  pollingInterval = 2000,
  pollingTimeout = 60000
}: UseAsyncJobOptions<TArgs>): UseAsyncJobResult {
  const dispatch = useDispatch();
  const [
    triggerMutation,
    { isLoading: isMutationLoading, isError: isMutationError, error: mutationError, endpointName: endpoint }
  ] = mutationHook;
  const [jobId, setJobId] = useState<string | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const wsUnavailable = useRef(false);
  const pollingStartTime = useRef<number | null>(null);
  const activeOrgID = useSelector(selectActiveOrgID);
  const websocketService = useWebSocket();
  const latestJob = useSelector((state: RootState) => (jobId != null ? selectJobById(state, jobId) : undefined));
  const shouldPoll = isRunning && wsUnavailable.current && jobId != null;

  const memoizedOnComplete = useCallback(
    (result: JobState) => {
      onComplete?.(result);

      // cleanup
      setIsRunning(false);
      setJobId(null);
      if (result.jobId != null) {
        dispatch(clearJobsByType(result.type));
      }
    },
    [onComplete, dispatch]
  );

  const { data: polledJobStatus } = useGetJobStatusQuery(
    { orgId: activeOrgID ?? '', taskId: jobId ?? '' },
    {
      skip: !shouldPoll,
      pollingInterval,
      refetchOnMountOrArgChange: false
    }
  );

  // Check websocket availability so we can poll or not
  useEffect(() => {
    const checkWebSocketAvailability = (): void => {
      wsUnavailable.current = websocketService.connectionState !== 'connected';
    };

    checkWebSocketAvailability();
    const interval = setInterval(checkWebSocketAvailability, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [websocketService]);

  // Update Redux store with polled job status
  useEffect(() => {
    if (
      polledJobStatus != null &&
      wsUnavailable.current &&
      jobId != null &&
      ['success', 'failed'].includes(polledJobStatus.status)
    ) {
      const normalizedJobStatus = {
        ...polledJobStatus,
        jobId
      };

      dispatch(updateJob(normalizedJobStatus as JobState));
      if (mutationToCacheBust[endpoint] != null && args != null && typeof args === 'object') {
        if ('profileId' in args) {
          mutationToCacheBust[endpoint](args.profileId);
        } else if ('walletId' in args) {
          mutationToCacheBust[endpoint](args.walletId);
        }
      }
    }
  }, [polledJobStatus, dispatch, jobId, wsUnavailable]);

  // Cleanup effect so that we don't store old jobs in the redux store
  useEffect(() => {
    return () => {
      if (jobId != null && latestJob != null) {
        dispatch(clearJobsByType(latestJob.type));
      }
    };
  }, [dispatch, jobId, latestJob]);

  // Update isRunning based on job status
  useEffect(() => {
    if (latestJob?.status === JOB_STATUS.SUCCESS || latestJob?.status === JOB_STATUS.FAILED) {
      memoizedOnComplete(latestJob);
    }
  }, [latestJob, memoizedOnComplete]);

  // Check if polling has timed out
  useEffect(() => {
    if (shouldPoll && pollingStartTime.current === null) {
      pollingStartTime.current = Date.now();
    } else if (!shouldPoll) {
      pollingStartTime.current = null;
    }

    // Check for timeout
    if (shouldPoll && pollingStartTime.current !== null) {
      const pollingDuration = Date.now() - pollingStartTime.current;
      if (pollingDuration > pollingTimeout) {
        // Polling has timed out, handle as error
        const timeoutError = {
          jobId,
          status: JOB_STATUS.FAILED
        };
        dispatch(updateJob(timeoutError as JobState));
        pollingStartTime.current = null;
      }
    }
  }, [shouldPoll, pollingTimeout, jobId, latestJob?.type, dispatch]);

  const mutate = async (customArgs?: TArgs): Promise<any> => {
    const argsToUse = customArgs ?? args;
    if (argsToUse == null) {
      throw new Error('No arguments provided for mutation');
    }

    const result = await triggerMutation(argsToUse);
    const transformedResult = convertObjectKeysToCamelCase(result.data);
    setJobId(transformedResult.taskId);
    setIsRunning(true);
    return result;
  };

  return {
    isLoading: (isMutationLoading as boolean) || isRunning,
    isError: (isMutationError as boolean) || latestJob?.status === JOB_STATUS.FAILED,
    error: mutationError,
    mutate,
    latestJob
  };
}
