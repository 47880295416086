import { Box, Heading, Button, useToast, Flex, Divider, IconButton } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import * as Sentry from '@sentry/browser';
import { useState, useContext, useEffect } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { NotesContext } from './NotesContext';

const NotesDisplay: React.FC<{
  notes: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
  isExpanded?: boolean;
  onExpand?: () => void;
}> = ({ notes, walletId, orgId, updateWallet, updateWalletIsLoading, isExpanded, onExpand }) => {
  const context = useContext(NotesContext);
  const toast = useToast();

  const [quillValue, setQuillValue] = useState(context?.quillValue ?? notes);
  const [isEditingNotes, setIsEditingNotes] = useState(context?.isEditingNotes ?? false);

  useEffect(() => {
    return () => {
      context?.setQuillValue(quillValue);
      context?.setIsEditingNotes(isEditingNotes);
    };
  }, [context, quillValue, isEditingNotes]);

  const handleClear = async (): Promise<void> => {
    try {
      await updateWallet({
        orgId,
        walletId,
        notes: ''
      }).unwrap();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Oh no, there was an error!',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };

  const handleSave = async (): Promise<void> => {
    try {
      await updateWallet({
        orgId,
        walletId,
        notes: quillValue
      }).unwrap();
      setIsEditingNotes(false);
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'There was an error saving your notes',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };

  return (
    <Box bg="white">
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Heading size="sm">Notes</Heading>
        {context != null && (
          <IconButton
            aria-label={isExpanded != null ? (isExpanded ? 'Collapse notes' : 'Expand notes') : 'Expand notes'}
            icon={<Icon icon={isExpanded != null ? (isExpanded ? 'bx:collapse' : 'bx:expand') : 'bx:expand'} />}
            size="sm"
            variant="ghost"
            onClick={() => {
              if (onExpand != null) {
                onExpand();
              }
            }}
          />
        )}
      </Flex>
      <Box
        className="quill-container"
        width="100%"
        sx={{
          '.ql-container, .ql-editor': {
            minHeight: '100px',
            border: 'none !important'
          },
          '.quill': {
            width: '100%'
          },
          '.ql-container': {
            backgroundColor: '#F7F7F7',
            borderRadius: isEditingNotes ? '0 0 md md' : 'md'
          },
          '.ql-toolbar': {
            display: isEditingNotes ? 'block' : 'none',
            border: 'none !important',
            backgroundColor: '#F7F7F7',
            borderRadius: 'md md 0 0'
          }
        }}
      >
        {!isEditingNotes ? (
          <ReactQuill
            key={`read-${isEditingNotes}`}
            theme="snow"
            readOnly={true}
            placeholder="No notes added"
            modules={{ toolbar: false }}
            value={notes ?? ''}
          />
        ) : (
          <ReactQuill
            key={`edit-${isEditingNotes}`}
            theme="snow"
            value={quillValue}
            onChange={setQuillValue}
            placeholder="Enter notes here"
          />
        )}
      </Box>
      <Divider mt={4} mb={4} />
      <Flex justifyContent="stretch" width="100%" gap={2}>
        {typeof updateWallet === 'function' && (
          <>
            {!isEditingNotes ? (
              <>
                <Button
                  size="sm"
                  onClick={handleClear}
                  isDisabled={updateWalletIsLoading ?? notes === ''}
                  flex={1}
                  variant="secondary"
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    setIsEditingNotes(true);
                  }}
                  flex={1}
                  variant="primary"
                >
                  {notes != null ? 'Edit Notes' : 'Add Notes'}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  onClick={() => {
                    setIsEditingNotes(false);
                    setQuillValue(notes);
                  }}
                  flex={1}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button size="sm" isLoading={updateWalletIsLoading} onClick={handleSave} flex={1} variant="primary">
                  Save
                </Button>
              </>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default NotesDisplay;
