import { Box, Flex, Spinner, Text, VStack } from '@chakra-ui/react';

import AlertDetailScreeningHits from './AlertDetailScreeningHits';
import AddressIdentification from '@features/wallets/components/AddressIdentification.component';
import { DynamicNotesContainer } from '@features/wallets/components/DynamicNotesContainer';
import { NotesProvider } from '@features/wallets/NotesContext';
import WalletLeftColumn from '@features/wallets/components/WalletLeftColumn.component';
import { InfoPanelProvider } from '@features/wallets/InfoPanelContext';
import { useGetWalletByIDQuery, useNewWalletInquiryMutation, useUpdateWalletMutation } from '@services/canaria.services';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useSelector } from 'react-redux';

const WalletAlertBody = ({
  alert,
}: {
  alert: any;
}): React.ReactNode => {
  const orgId = useSelector(selectActiveOrgID);
  const { data, error, isLoading } = useGetWalletByIDQuery({
    orgId: orgId as string,
    walletId: alert.wallet.id
  });

  const [updateWallet, { isLoading: updateWalletIsLoading }] = useUpdateWalletMutation();
  const [newWalletInquiry, { isLoading: newWalletInquiryLoading }] = useNewWalletInquiryMutation();

  if (isLoading) {
    return <Spinner />;
  }

  if (error != null) {
    return <Text>Error found loading data</Text>;
  }

  if (data == null) {
    return null;
  }

  return (
    <InfoPanelProvider>
      <Flex justifyContent="space-between" width="100%" gap={4}>
        <WalletLeftColumn
          data={{
            lastInquiry: alert.currentInquiryResult,
            profiles: data?.profiles,
            createdAt: data?.createdAt,
            schedule: data?.schedule
          }}
          updateWallet={updateWallet}
          updateWalletIsLoading={updateWalletIsLoading}
        />

        <Box flex="1" minW="0">
          <VStack spacing={4}>
            <Box w="100%" bg="white" p={1} borderRadius="md" boxShadow="sm">
              <AddressIdentification addressIdentifications={alert.currentInquiryResult?.result?.walletAssociations} />
            </Box>

            <Box w="100%" bg="white" p={5} borderRadius="md" boxShadow="sm">
              <NotesProvider initialNotes={alert.notes} initialIsEditingNotes={false}>
                <DynamicNotesContainer
                  data={alert}
                  walletID={alert.wallet?.id}
                  orgId={alert.orgId}
                  updateWallet={updateWallet}
                  updateWalletIsLoading={updateWalletIsLoading}
                  newWalletInquiry={newWalletInquiry}
                  newWalletInquiryLoading={newWalletInquiryLoading}
                />
              </NotesProvider>
            </Box>
          </VStack>
        </Box>
      </Flex>
    </InfoPanelProvider>
  );
};

const WatchlistAlertBody = ({
  alert,
  onResolveMatch
}: {
  alert: any;
  onResolveMatch: (id: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
}): React.ReactNode => {
  return <AlertDetailScreeningHits alert={alert} onResolveMatch={onResolveMatch} />;
};

const AlertBody = ({
  alert,
  onResolveMatch
}: {
  alert: any;
  onResolveMatch: (id: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
}): React.ReactNode => {
  if (alert.resourcetype === 'WatchlistAlert') {
    return <WatchlistAlertBody alert={alert} onResolveMatch={onResolveMatch} />;
  }
  return <WalletAlertBody alert={alert} />;
};

export default AlertBody;
