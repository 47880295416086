export interface GlossaryItem {
  id: string;
  term?: string;
  definition?: string;
}

// Missing `fee` `unspent`

export const glossary: GlossaryItem[] = [
  {
    id: 'exposure',
    term: 'Exposure',
    definition:
      'The relationship between the screened address and other entities, determined by the flow of funds into and out of the address. It analyzes the last known source of funds and the first known destination of funds for all transactions to assess potential risks and connections.'
  },
  {
    id: 'divisor'
  },
  {
    id: 'ownership-exposure',
    term: 'Ownership Exposure',
    definition: "The risk associated with the screened wallet itself. This captures the screened wallet's flags."
  },
  {
    id: 'counterparty-exposure',
    term: 'Counterparty Exposure',
    definition:
      'The risk associated with immediate, first-degree transactions where funds move directly between the screened wallet and another entity without any intermediaries. This represents transactions where the screened wallet either sends funds to or receives funds from a flagged address in a single hop.'
  },
  {
    id: 'indirect-exposure',
    term: 'Indirect Exposure',
    definition:
      "The risk arising from transactions that connect the screened wallet to high-risk entities through one or more intermediate addresses. This captures the wallet's connection to risky entities even when the funds flow through multiple hops or intermediate wallets, creating a chain of transactions that ultimately links to flagged addresses or services."
  },

  {
    id: 'category-atm',
    term: 'ATM',
    definition: `ATMs facilitate the conversion of physical cash into cryptocurrency, or cryptocurrency into physical cash. They operate similar to normal fiat ATMs and typically have a KYC requirement (with smaller amounts requiring less KYC info and larger amounts requiring more KYC info).
ATMs typically charge a premium for their service, which allows convenience and speed in buying and selling cryptocurrency compared to online exchanges. 
The possibility for exploitation is often dependent on the ATM’s KYC requirements. Without KYC, individuals with influxes of physical cash from drug sales and other illicit activity are able to convert funds into cryptocurrency with relative ease. Besides money laundering, attackers who want to receive cryptocurrency by exploiting those who are not technically savvy will often direct their victims to send the funds via ATMs because they’re easy to understand.`
  },
  {
    id: 'category-bridge',
    term: 'Bridge',
    definition:
      'Blockchain bridges are software protocols that enable communication and interoperability between different blockchain networks. By connecting multiple blockchain networks, blockchain bridges allow the sharing of information and assets.'
  },
  {
    id: 'category-child-abuse-material',
    term: 'Child Abuse Material',
    definition: `Child abuse material includes forums and sites operating on the dark web which facilitate the
buying, selling, and the spread of child sexual abuse material. These sites are often coded and
difficult to access.`
  },
  {
    id: 'category-dark-web-market',
    term: 'Dark Web Market',
    definition: `Darknet markets are commercial websites that operate on the dark web, which can be accessed
via anonymizing browsers or software such as Tor or I2P. These sites function as black markets
by selling or advertising illicit goods and services such as drugs, fraud materials, and weapons,
among others. Darknet markets use cryptocurrency payment systems, often with escrow
services and feedback systems to help develop trust between the vendor and customer. Darknet
markets have become more security conscious over the past few years due to multiple law
enforcement shutdowns.`
  },
  {
    id: 'category-decentralized-exchange',
    term: 'Decentralized Exchange',
    definition: `Decentralized exchanges are services which allow cryptocurrency holders to exchange assets by
using smart contracts and without needing to trust an intermediary, third party or central
authority.`
  },
  {
    id: 'category-dust',
    term: 'Dust',
    definition: `Dust refers to fractional values from a unit of cryptocurrency. These values often fall below
trading limits and transaction fees and sit idle in wallets. The conditions that determine whether
we will categorize the exposure of a transfer as Dust depend on the asset's maximum price it
has reached in its lifetime.`
  },
  {
    id: 'category-exchange',
    term: 'Exchange',
    definition: `Exchanges allow users to buy, sell, and trade cryptocurrency. They represent the most important
and widely-used entity category in the cryptocurrency industry, accounting for 90% of all funds
sent by services.`
  },
  {
    id: 'category-fee',
    term: 'Fee',
    definition: `Fees refer to the portions of cryptocurrency awarded to miners and stakers during coin
generation and transaction validation.`
  },
  {
    id: 'category-fraud-shop',
    term: 'Fraud Shop',
    definition: `Financially motivated shops selling different types of data including, PII (Personally Identifiable
Information), credit card data, stolen accounts, and more. Unlike darknet markets, fraud shops
are normally operated by a single actor/team and are the sole merchant within the service.
Fraud shops also tend to have behavioral differences from darknet markets such as top-up
depositing of funds (incremental increases to the total amount), as well as no customer
withdrawals. Therefore, most outgoing transactions can be linked to the operators of the fraud
shop.`
  },
  {
    id: 'category-gambling',
    term: 'Gambling',
    definition: `Online gambling can take many forms from resembling a typical casino where you can play card
games like blackjack and poker, slot games and the like, to sites for wagering bets on sports or
eSports outcomes. The industry has been an early adopter of cryptocurrency. Users will send
cryptocurrency as a convenient alternative to fiat, and get started betting. Gambling is treated
differently depending on the jurisdiction, and many sites have lax KYC requirements. Because of
this, there’s potential for these sites to be used for laundering money. Many of these companies
are located in/operating out of island nation-states (such as Curaçao, Cyprus, or Malta).`
  },
  {
    id: 'category-hosted-wallet',
    term: 'Hosted Wallet',
    definition: `Hosted wallets are an alternative to core wallets (full node wallets). Wallet software allows
users to store their public and private keys, and connects to blockchain nodes to transfer funds
and check balances. Wallets that control the user’s private keys are considered custodial, or
hosted, while software that allows users to retain full control of private keys is considered non-
custodial. Hosted wallets can be risky because the user doesn’t actually hold their funds, thus
opening the possibility of being scammed. It’s also possible the service does not implement
sufficient security measures, and is vulnerable to attack. However, a reputable hosted wallet
service that takes advanced security measures is likely more reliable and convenient than a non-
technical or careless individual`
  },
  {
    id: 'category-ico',
    term: 'ICO',
    definition: `An ICO (Initial Coin Offering) is a means of crowdfunding for new cryptocurrency or related
projects, similar to an IPO in the traditional market. The entity behind the new cryptocurrency
makes their pitch and sells units of the token to investors in exchange for fiat currency or more
mainstream cryptocurrencies like Bitcoin or Ether. Many ICOs have proven to be scams. There
are countless examples of bad actors who build a flashy site promoting an ambitious project,
raise funds through an ICO, then pocket the money and walk away.`
  },
  {
    id: 'category-illicit-actor-org',
    term: 'Illicit actor-org',
    definition: `Individuals and/or organizations that operate directly or indirectly in various forms of illicit
activities. These entities are directly or indirectly involved with risky entities such as darknet
markets, fraud shops, extremist financing, hacking, etc.`
  },
  {
    id: 'category-infrastructure-as-a-service',
    term: 'Infrastructure as a service',
    definition: `The infrastructure as a service category comprise of all infrastructure surrounding computing
and information services, including but not limited to VPN, VPS, Domain Registrar and other
popular types of cyber infrastructure. The sending of funds to infrastructure as a service entities
could be payment for bulletproof hosts or other infrastructure that could be used for illicit
purposes. Conversely, receipt of funds from this category could indicate a cyber infrastructure
business account.`
  },
  {
    id: 'category-lending',
    term: 'Lending',
    definition: `Lending protocols are platforms that allow users to borrow and lend cryptocurrencies. While
centralized lending platforms may engage directly in, or facilitate, a lending transaction with a
borrower, decentralized lending platforms provide mechanisms for participants to loan and
borrow assets without intermediaries. This category covers a wide variety of lending
arrangements, however, most common to both centralized and decentralized lending platforms
are opportunities for borrowers to take out loans against collateral, and lenders to earn interest
on assets deposited into the depository or protocol.`
  },
  {
    id: 'category-malware',
    term: 'Malware',
    definition: `Malware can be a type of malicious software that is specifically designed to harm or disrupt
computer systems, networks, and devices.
Malware encompasses a wide range of malicious programs, including viruses, worms, trojans,
ransomware, spyware, adware, and can be designed for a variety of purposes which may
encompass stealing sensitive information, compromising system security, manipulating or
destroying data, or taking control of a system for nefarious purposes. Malware can spread
through various vectors, including email attachments, software downloads, infected websites,
and more.`
  },
  {
    id: 'category-merchant-services',
    term: 'Merchant Services',
    definition: `Merchant services are financial services that enable businesses to accept payments on their
customer’s behalf. They are also known as payment gateways or payment processors. These
services allow merchants to accept cryptocurrency for invoicing and online or in-person
payments. This often includes conversion to local fiat currency and settling funds to the
merchant’s bank account. Merchant services is generally a low-risk category. Users mostly
comprise mainstream, traditional businesses on one end and their customers on another.
However, it’s worth noting that scammers sometimes integrate merchant services with a
malicious website to accept cryptocurrency payments from their victims.`
  },
  {
    id: 'category-mining',
    term: 'Mining',
    definition: `Mining is the process by which cryptocurrency is generated. Mining is used for coin generation,
when new coins are minted from the mining process.`
  },
  {
    id: 'category-mining-pool',
    term: 'Mining pool',
    definition: `Mining pools are special services where miners can pool their resources - typically GPU or
specialized ASIC mining hardware - together towards mining cryptocurrency. By pooling mining
resources the pool has a bigger chance of mining a block and the returns are divided among all
the miners according to how much mining power each contributed.
Mining pools typically only receive funds from direct mining activity, and as such are typically low
risk. However, a pool that accepts deposits from sources other than mining can be exploited for
money laundering.`
  },
  {
    id: 'category-mixing',
    term: 'Mixing',
    definition: `Mixers are websites or software used to create a disconnection between a user’s deposit and
withdrawal. Mixing is done either as a general privacy measure or for covering up the movement
of funds obtained from theft, darknet markets, or other illicit sources. Mixers typically pool
incoming funds from many users and re-distribute those funds with no direct connection back to
the original source.`
  },
  {
    id: 'category-nft-platform---collection',
    term: 'NFT Platform - Collection',
    definition: `NFT platforms refer to online marketplaces or websites where creators and collectors can buy,
sell, and trade non-fungible tokens (NFTs). These platforms provide a space for artists,
musicians, and others to showcase and monetize their digital creations, while also providing
collectors with the opportunity to purchase unique, one-of-a-kind digital assets.
NFT collections are unique sets of digital assets that have been created by a particular individual
or group of individuals. NFT collections can take many forms, ranging from digital art and music
to virtual real estate and gaming items. Each NFT within a collection is unique and is tracked on
a blockchain, making it easily verifiable and transferable.`
  },
  {
    id: 'category-no-kyc-exchange',
    term: 'No KYC Exchange',
    definition: `No KYC Exchange refers to c en t r al i z e d exchanges which have no known process for collecting
customer information before allowing any level of deposit or withdrawal, or that require a name,
phone number, or email address but make no apparent attempt to verify this information prior to transacting.`
  },
  {
    id: 'category-online-pharmacy',
    term: 'Online Pharmacy',
    definition: `Online pharmacies are online businesses that may engage in the sale and distribution of
medications and other substances without proper credentials to operate. In addition to vendors
who sell medications that may otherwise require a prescription, this category also includes
clearnet vendors of research chemicals, anabolic steroids, and substances that may be illegal.`
  },
  {
    id: 'category-org',
    term: 'Org',
    definition: `This category is only visible in custom clusters. This category comprises any exposure a custom
cluster has to clusters with a given a Shared name. Use the Browse categories feature to see all
clusters with a Shared name`
  },
  {
    id: 'category-other',
    term: 'Other',
    definition: `This category is used when the entity does not represent a widely popular field of operation or is
a particular type of operation or entity such as donation addresses, social network bots, seized
funds, among others. This category does not have any inherent risk but may contain risky
entities`
  },
  {
    id: 'category-p2p-exchange',
    term: 'P2P Exchange',
    definition: `Peer to peer (P2P) exchanges are online sites that facilitate the buying, selling, and trading of
cryptocurrency between two individuals while, usually, not being directly in possession of the
funds. Some P2P exchanges will not require any KYC (Know Your Customer), making them
attractive for money laundering activities.`
  },
  {
    id: 'category-protocol-privacy',
    term: 'Protocol Privacy',
    definition: `Protocol privacy refers to protocols and entities which use sophisticated features to ensure
confidentiality and anonymity. Those features include:
Zero-knowledge proofs: These allow the validation of transactions without revealing
additional information, ensuring privacy while maintaining security.
Stealth addresses: These generate unique, one-time addresses for each transaction,
preventing linkability, enhancing receiver privacy, and mitigating potential privacy risks.
Shielded pools built into the Zcash blockchain: These allow users to encrypt blockchain
activity ensuring that only intended recipients can access transaction details.
These features create a robust framework for secure and private digital transactions.`
  },
  {
    id: 'category-ransomware',
    term: 'Ransomware',
    definition: `Ransomware is special malware designed to encrypt a victim’s computer data and
automatically request a ransom to be paid in order to decrypt the data. Attackers employ social
engineering and phishing schemes that trick people and organizations into downloading the
malicious software.`
  },
  {
    id: 'category-sanctioned-entity',
    term: 'Sanctioned Entity',
    definition: `Sanctions refer to entities listed on economic/trade embargo lists, including but not limited to,
sanctions lists imposed by the US, EU, or UN, with which anyone subject to those jurisdictions is
prohibited from dealing.`
  },
  {
    id: 'category-sanctioned-jurisdiction',
    term: 'Sanctioned Jurisdiction',
    definition: `The Sanctioned Jurisdiction category comprises cryptocurrency entities based in jurisdictions
that have been comprehensively sanctioned by OFAC. The Office of Foreign Assets Control
(OFAC) has enumerated the following as comprehensively sanctioned jurisdictions: Iran, North
Korea, Cuba, Syria, the Crimea region of Ukraine and the so-called Donetsk/Luhansk People’s
Republics. Due to the expansive government-related sanctions in Venezuela, Chainalysis has
additionally chosen to include Venezuela in this category. We will continue to add entities to this
category over time, as new services are identified in these jurisdictions.`
  },
  {
    id: 'category-scam',
    term: 'Scam',
    definition: `Scams can impersonate a variety of services, including exchanges, mixers, ICOs, and gambling
sites. This category also encompasses scam emails, extortion emails, and fake investment
services. They usually offer unrealistic returns on investment, many times trying to mask a
pyramid scheme, or pretend to have incriminating personal data on the victim and ask for money
in order to not disclose it.`
  },
  {
    id: 'category-seized-funds',
    term: 'Seized Funds',
    definition: `Seized Funds is a category that comprises publicly disclosed addresses that contain funds that
law enforcement has seized. While these funds may have been previously associated with
criminal activity, they are no longer subject to criminal activity once in the hands of law
enforcement.`
  },
  {
    id: 'category-smart-contract',
    term: 'Smart Contract',
    definition: `Some cryptocurrency variants have a built-in functionality for smart contracts. Smart contracts
can store information related to a deal and automatically self-execute when the terms of the
contract are fulfilled. Smart contracts can be agreed upon and enforced between two parties
without the need for a third, since they don’t actually execute until each side has fulfilled their
obligations.`
  },
  {
    id: 'category-special-measures',
    term: 'Special Measures',
    definition: `Special measures refers to entities or addresses identified by the U.S. Department of the
Treasury’s Financial Crimes Enforcement Network (FinCEN) as “primary money laundering
concern[s]” under its “Section 9714” or USA PATRIOT Act (section 311) authorities. Under these
authorities, FinCEN may impose individually or in combination one of a series of special
measures, including record keeping and reporting requirements, or prohibition of transmittal of
funds or conditions on opening or maintaining certain types of accounts. As other countries or
jurisdictions introduce and leverage similar authorities, this category may be updated to include
those authorities as well.`
  },
  {
    id: 'category-stolen-funds',
    term: 'Stolen Funds',
    definition: `Stolen funds comprise instances of hacked exchanges and services. Attackers engage in
sophisticated and persistent social engineering, and exploit pre-existing vulnerabilities to
transfer funds from exchange hot wallets to their control. The payoff for actors can be enormous
with single incidents often resulting in tens of millions of dollars in losses.`
  },
  {
    id: 'category-terrorist-financing',
    term: 'Terrorist Financing',
    definition: `Terrorist financing pertains to the funding of designated terrorist groups and affiliates of terrorist
groups, entities, and individuals. Financing is fundamental for the survival and operation of
terrorist groups and is used to support a multitude of their activities, including recruitment,
propaganda, day-to-day activities, and military operations. Terrorist groups and their affiliates
secure the flow of funds in a variety of ways, including through the use of cryptocurrencies.`
  },
  {
    id: 'category-token-smart-contract',
    term: 'Token Smart Contract',
    definition: `Tokens are a blockchain-based asset that can be sent and received using a wallet. There are
different technical standards for the different types of smart contracts on various blockchain,
enabling token issuance for ICOs (a crowdfunding mechanism).`
  },
  {
    id: 'category-unnamed-service',
    term: 'Unnamed Service',
    definition: `This category refers to currently unidentified clusters that show the behavior expected of a
service. These behaviors differ depending on the type of blockchain.`
  },
  {
    id: 'category-unspent',
    term: 'Unspent',
    definition: `This category refers to a sent value that is held in balances that are not part of a named or
unnamed service.`
  },
  {
    id: 'category-untraced',
    term: 'Untraced',
    definition: `This category represents the indirect exposure from recent transfers that haven't yet been
calculated. The untraced amount is the difference between the current traced volume of the
cluster and its total volume. Once the calculation is completed, the untraced amount will reflect
the involved counterparties.`
  }
];
