import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import analyticsInstance from '@features/analytics/analytics';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Attachments } from '@features/profile-overview/';
import AuditLogModal from '@features/profile-overview/components/AuditLogModal.component';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID, selectFeatureFlags, selectUser } from '@features/user-settings/userSlice';
import { PROFILE_TYPE } from '@models/profileTypes';
import {
  useExportProfilePDFMutation,
  useGetCountriesQuery,
  useGetProfileByIdQuery,
  useGetProfileGroupsQuery,
  useGetProfileRiskRatingsQuery,
  useUpdateProfileMutation
} from '@services/canaria.services';
import { smoothScrollbarStyles } from '@utils/consts';
import { adverseMediaForEntity } from '@utils/ai.consts';
import { useAsyncJob } from '@hooks/useAsyncJob';
import { JOB_STATUS, JOB_TYPE } from '@features/async-jobs/asyncJobsSlice';

import BusinessRegulatoryCompliance, {
  type BusinessRegulatoryComplianceRef
} from './components/BusinessRegulatoryCompliance';
import ExpectedTransactionActivity, {
  type ExpectedTransactionActivityRef
} from './components/ExpectedTransactionActivity';
import NavigationTabs from './components/NavigationTabs';
import Overview from './components/Overview';
import PepInformation, { type PepInformationRef } from './components/PepInformation';
import PrimaryPartyInformation, { type PrimaryPartyInformationRef } from './components/PrimaryPartyInformation';
import RelatedParties, { type RelatedPartiesRef } from './components/RelatedParties';
import Wallets, { type WalletsRef } from './components/Wallets';
import ProfileNotes from './ProfileNotes';
import { ChevronDownIcon, ChevronUpIcon  } from '@chakra-ui/icons';
import { AISummaryChat } from '../../components/AISummaryChat';

interface SectionRef {
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
  expand?: () => void;
}

const NotesAndAttachmentsPanel: React.FC<{ profileId: string; orgId: string }> = ({ profileId, orgId }) => {
  return (
    <Tabs variant="soft-rounded" colorScheme="gray" w="100%">
      <TabList mb="1em" w="100%" h="32px">
        <Tab
          flex={1}
          h="32px"
          bg="#F7F7F7"
          color="#7C7E7E"
          _selected={{
            color: 'black',
            bg: 'white',
            border: '1px solid',
            borderColor: '#BED903'
          }}
          _focus={{ boxShadow: 'none' }}
        >
          Notes
        </Tab>
        <Tab
          flex={1}
          h="32px"
          bg="#F7F7F7"
          color="#7C7E7E"
          _selected={{
            color: 'black',
            bg: 'white',
            border: '1px solid',
            borderColor: '#BED903'
          }}
          _focus={{ boxShadow: 'none' }}
        >
          Attachments
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0}>
          <ProfileNotes profileId={profileId} />
        </TabPanel>
        <TabPanel p={0}>
          <Attachments profileId={profileId} orgId={orgId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const ProfileOverview: React.FC = () => {
  const { profileId } = useParams();
  const activeOrgID = useSelector(selectActiveOrgID);
  const user = useSelector(selectUser);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  useEffect(() => {
    analyticsInstance.trackProfileOpened(user, profileId ?? '');
  }, [user, profileId]);

  const featureFlags = useSelector(selectFeatureFlags);

  const [updateProfile] = useUpdateProfileMutation();
  const exportProfilePDFHook = useExportProfilePDFMutation();
  const [isAuditLogOpen, setIsAuditLogOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('Overview');
  const navigate = useNavigate();

  const primaryPartyRef = useRef<PrimaryPartyInformationRef>(null);
  const expectedTransactionActivityRef = useRef<ExpectedTransactionActivityRef>(null);
  const businessRegulatoryComplianceRef = useRef<BusinessRegulatoryComplianceRef>(null);
  const relatedPartiesRef = useRef<RelatedPartiesRef>(null);
  const walletsRef = useRef<WalletsRef>(null);
  const pepInformationRef = useRef<PepInformationRef>(null);
  const rightContainerRef = useRef<HTMLDivElement>(null);
  const [rightContainerHeight, setRightContainerHeight] = useState<number | null>(null);
  const toast = useToast();

  const { isLoading: isExporting, mutate: exportPDF } = useAsyncJob({
    mutationHook: exportProfilePDFHook,
    args: { orgId: activeOrgID, profileId },
    onComplete: (job) => {
      if (job.status === JOB_STATUS.FAILED) {
        toast({
          title: 'Error downloading PDF',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      } else if (job.status === JOB_STATUS.SUCCESS && job.type === JOB_TYPE.PROFILE_REPORT && 'downloadUrl' in job) {
        // Open the PDF in a new tab
        window.open(job.downloadUrl, '_blank');
      }
    }
  });

  useEffect(() => {
    const updateHeight = (): void => {
      if (rightContainerRef.current != null) {
        setRightContainerHeight(rightContainerRef.current.offsetHeight);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    if (rightContainerRef.current != null) {
      resizeObserver.observe(rightContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSectionChange = (section: string): void => {
    const sectionRefs: Record<string, SectionRef | null> = {
      'Primary Party Information': {
        scrollIntoView: () => primaryPartyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => primaryPartyRef.current?.expand()
      },
      'Expected Transaction Activity': {
        scrollIntoView: () =>
          expectedTransactionActivityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => expectedTransactionActivityRef.current?.expand()
      },
      'PEP Information': {
        scrollIntoView: () => pepInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => pepInformationRef.current?.expand()
      },
      'Business Regulatory Compliance': {
        scrollIntoView: () =>
          businessRegulatoryComplianceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        expand: () => businessRegulatoryComplianceRef.current?.expand()
      },
      'Related Parties': relatedPartiesRef.current,
      Wallets: walletsRef.current
    };

    const targetRef = sectionRefs[section];
    if (targetRef?.scrollIntoView != null) {
      targetRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
      targetRef.expand?.();
    }
  };

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: profile, isLoading } = useGetProfileByIdQuery({
    orgId: activeOrgID,
    profileId
  });

  const { data: profileGroups = [] } = useGetProfileGroupsQuery({
    orgId: activeOrgID
  });

  const { data: countries = [] } = useGetCountriesQuery({});
  const { data: profileRiskRatings = [] } = useGetProfileRiskRatingsQuery({});

  if (isLoading || profile == null) {
    return <Loading message="Loading Profile details..." />;
  }
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId,
      [name]: value
    }).unwrap();
  };
  const relatedPartiesStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Related Parties');
  const relatedPartiesEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? relatedPartiesStep?.individual : relatedPartiesStep?.entity;

  const walletsStep = profile.profileGroup.stepsProfileForm.steps.find(({ step }) => step === 'Add Wallets');
  const walletsEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? walletsStep?.individual : walletsStep?.entity;

  const expectedTransactionActivityStep = profile.profileGroup.stepsProfileForm.steps.find(
    ({ step }) => step === 'Expected Transaction Activity'
  );
  const expectedTransactionActivityEnabled =
    profile.resourcetype === PROFILE_TYPE.INDIVIDUAL
      ? expectedTransactionActivityStep?.individual
      : expectedTransactionActivityStep?.entity;

  const businessRegulatoryComplianceStep = profile.profileGroup.stepsProfileForm.steps.find(
    ({ step }) => step === 'Business Regulatory Compliance'
  );
  const businessRegulatoryComplianceEnabled =
    profile.resourcetype === PROFILE_TYPE.ENTITY && businessRegulatoryComplianceStep?.entity;

  const handleSectionHover = (section: string): void => {
    // No-op function to prevent unnecessary re-renders
    //setActiveSection(section);
  };

  const handleExportPDF = async (): Promise<void> => {
    try {
      await exportPDF();
    } catch (error) {
      toast({
        title: 'Error downloading PDF',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const hasFeatureFlag = (flagName: string): boolean => {
    return Array.isArray(featureFlags) && featureFlags.some((flag) => flag.name === flagName);
  };

  const ActionsDropdown = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const user = useSelector(selectUser);
    const [showAISummary, setShowAISummary] = useState(() => {
      // Initialize state from localStorage
      const savedState = localStorage.getItem('showAISummary');
      return savedState ? JSON.parse(savedState) : false;
    });

    useEffect(() => {
      // The hover over different section of the profile makes renderization of components
      // and the state of the AI summary is lost. This is a workaround to save the state of the AI summary
      // however it is not a efficient solution as it may not track the last position of the window neither
      // the messages in the AI summary.
      localStorage.setItem('showAISummary', JSON.stringify(showAISummary));
    }, [showAISummary]);

    useEffect(() => {
      const handleUnload = () => {
        localStorage.setItem('showAISummary', JSON.stringify(false));
      };

      window.addEventListener('beforeunload', handleUnload);
      return () => window.removeEventListener('beforeunload', handleUnload);
    }, []);

    return (
      <>
        <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <MenuButton
            as={Button}
            variant="primary"
            color="black"
            rightIcon={
              isOpen ? <ChevronUpIcon width="24px" height="24px" /> : <ChevronDownIcon width="24px" height="24px" />
            }
          >
            Actions{' '}
          </MenuButton>
          <MenuList bg="black" color="white">
            {hasFeatureFlag('AI_SUMMARY') && (
              <MenuItem
                icon={<Icon icon="fa-solid:robot" />}
                bg="black"
                color="white"
                _hover={{ bg: 'gray.700' }}
                onClick={() => setShowAISummary(true)}
              >
                AI Summary
              </MenuItem>
            )}
            {hasFeatureFlag('ADVERSE_MEDIA_SEARCH') && (
              <MenuItem
                icon={<Icon icon="material-symbols:breaking-news-alt-1-rounded" />}
                bg="black"
                color="white"
                _hover={{ bg: 'gray.700' }}
                onClick={() => {
                  // track adverse media search
                  analyticsInstance.trackSearchForAdverseMedia(profile.id, user.userId, user.username, {
                    orgId: user.orgId ?? '',
                    orgName: user.orgName
                  });
                  const entityName = profile.fullLegalName ?? profile.name ?? '';
                  const prompt = adverseMediaForEntity(entityName);
                  const encodedPrompt = encodeURIComponent(prompt);
                  window.open(`https://www.perplexity.ai/?q=${encodedPrompt}`, '_blank');
                }}
              >
                Adverse Media Search
              </MenuItem>
            )}
            {hasFeatureFlag('AI_PROFILE_REPORT') && (
              <MenuItem
                icon={<Icon icon="bxs:file-pdf" />}
                bg="black"
                color="white"
                _hover={{ bg: 'gray.700' }}
                onClick={handleExportPDF}
                isDisabled={isExporting}
                >
                {isExporting ? 'Generating PDF...' : 'Export PDF'}
              </MenuItem>
            )}
            <MenuItem
              icon={<Icon icon="material-symbols:playlist-add-check" />}
              bg="black"
              color="white"
              _hover={{ bg: 'gray.700' }}
              onClick={() => {
                setIsAuditLogOpen(true);
              }}
            >
              Audit Log
            </MenuItem>
            <MenuItem
              as={Link}
              to={`/dashboard/profile-wizard?profileId=${profileId}&step=1&from=profile`}
              icon={<Icon icon="fa-solid:magic" />}
              bg="black"
              color="white"
              _hover={{ bg: 'gray.700' }}
            >
              Go to Wizard
            </MenuItem>
          </MenuList>
        </Menu>
        {showAISummary && <AISummaryChat 
          entityType="profile" 
          entityId={profileId} 
          onClose={() => setShowAISummary(false)} 
        />}
      </>
    );
  };

  return (
    <>
      <Flex justifyContent="center" alignItems="center" width="full" paddingX={7} mb={8}>
        <Flex alignItems="center" flex={1}>
          <Button
            px={0}
            onClick={() => {
              navigate(-1);
            }}
            variant="secondary"
          >
            <Icon icon="ion:arrow-back-outline" />
          </Button>
          <Heading
            ml={2}
            as="h2"
            size="lg"
            textAlign="left"
            display="flex"
            alignItems="center"
            maxW="600px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            title={profile.name ?? ''}
          >
            {profile.name ?? ''}
          </Heading>
          <Box flex={1} />
        </Flex>
        <Box flex={1} />
        <Box display="flex" flex={1} justifyContent="end">
          <ActionsDropdown />
        </Box>
      </Flex>

      <NavigationTabs
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        relatedPartiesEnabled={relatedPartiesEnabled ?? false}
        walletsEnabled={walletsEnabled ?? false}
        expectedTransactionActivityEnabled={expectedTransactionActivityEnabled ?? false}
        businessRegulatoryComplianceEnabled={businessRegulatoryComplianceEnabled ?? false}
        onSectionChange={handleSectionChange}
      />

      <Flex justifyContent="space-between" width="100%" gap={1} px={4}>
        <Box
          flex="2"
          minW="0"
          maxH={'calc(100vh - 150px)'}
          overflowY="auto"
          overflowX="hidden"
          position="sticky"
          top="20px"
          pr={4}
          sx={smoothScrollbarStyles}
        >
          <PrimaryPartyInformation
            ref={primaryPartyRef}
            profileId={profileId}
            profile={profile}
            handleSectionHover={handleSectionHover}
          />
          {expectedTransactionActivityEnabled === true && (
            <ExpectedTransactionActivity
              ref={expectedTransactionActivityRef}
              profile={profile}
              handleFieldChange={handleFieldChange}
              handleSectionHover={handleSectionHover}
            />
          )}
          <PepInformation
            ref={pepInformationRef}
            profile={profile}
            handleFieldChange={handleFieldChange}
            mappedCountries={mappedCountries}
            handleSectionHover={handleSectionHover}
          />
          {businessRegulatoryComplianceEnabled === true && (
            <BusinessRegulatoryCompliance
              ref={businessRegulatoryComplianceRef}
              profile={profile}
              handleFieldChange={handleFieldChange as any}
              mappedCountries={mappedCountries}
              handleSectionHover={handleSectionHover}
            />
          )}
          {relatedPartiesEnabled === true && (
            <RelatedParties
              ref={relatedPartiesRef}
              profileId={profileId}
              orgId={activeOrgID}
              handleSectionHover={handleSectionHover}
              profileGroupId={profile.profileGroup.id}
            />
          )}
          {walletsEnabled === true && (
            <Wallets ref={walletsRef} profileId={profileId} handleSectionHover={handleSectionHover} />
          )}
        </Box>
        <Box ref={rightContainerRef} flex="1" minW="0" py={3}>
          <VStack spacing={4} align="stretch" w="100%" bg="white" boxShadow="md" layerStyle="container">
            <Overview
              profile={profile}
              profileGroups={profileGroups?.results}
              profileRiskRatings={profileRiskRatings}
              handleSectionHover={handleSectionHover}
              handleFieldChange={handleFieldChange}
            />
            <NotesAndAttachmentsPanel profileId={profileId} orgId={activeOrgID} />
          </VStack>
        </Box>
      </Flex>
      <AuditLogModal
        isOpen={isAuditLogOpen}
        onClose={() => {
          setIsAuditLogOpen(false);
        }}
        profileId={profileId}
      />
    </>
  );
};

export default ProfileOverview;
