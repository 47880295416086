import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// eslint-disable-next-line
import { store } from './state/store'; // Importing store before the features to load the api
// Features
import { ErrorNotFound404 } from '@features/errors/http';
import AlertDetail from '@features/panels/alertsPanel/AlertDetail.component';
import AlertsPanel from '@features/panels/alertsPanel/AlertsPanel.component';
import ScreeningPanel from '@features/panels/screeningPanel/ScreeningPanel.component';
import ProtectedRoute from '@features/routes/ProtectedRoute.component';
import { Loading } from '@features/shared/components';
import CookieConsentBanner from '@features/shared/components/CookieConsent';
import { chakraTheme } from '@utils/consts';
import { AdminRedirect } from '@features/admin-redirect';

import { USE_SENTRY } from './env';
// Pages
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';
import OrganizationsPage from './pages/Organizations';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProfileGroups from './pages/ProfileGroups';
import ProfileOverview from './pages/ProfileOverview';
import ProfileScreeningOverview from './pages/ProfileOverview/ProfileScreeningOverview';
import WatchlistScreening from './pages/ProfileOverview/WatchlistScreening';
import ProfilesPage from './pages/Profiles';
import SidePanel from './pages/Sidepanel';
import WalletsPage from './pages/Wallets';
// Settings
import WizardSettingsPage from './pages/Settings/Wizard';
import WizardProfileGroupDetails from './pages/Settings/Wizard/ProfileGroupDetails';

// Code splitting for smaller bundles
const FormPage = lazy(async () => await import('./pages/Forms'));
const RFIFormPage = lazy(async () => await import('./pages/RFIForm'));
const ProfileWizard = lazy(async () => await import('./pages/ProfileWizard'));
const Wallet = lazy(async () => await import('./features/wallets/Wallet.component'));
const RelationshipChart = lazy(async () => await import('./pages/RelationshipChart'));

const DashboardLayout: React.FC = () => {
  return (
    <SidePanel>
      <Outlet />
    </SidePanel>
  );
};

// In order to redirect to profiles without getting rid of the home page
const RootRedirect: React.FC = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  
  if (isLoggedIn) {
    return <Navigate to="/dashboard/profiles" replace />;
  }
  
  return <HomePage />;
};

const GOOGLE_CLIENT_ID = '531474279263-qgro75v6o0hs1v69jppgjk8d1ofdr02t.apps.googleusercontent.com';

const SentryRoutes = USE_SENTRY ? Sentry.withSentryRouting(Routes) : Routes;

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={chakraTheme}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <div className="App">
            <BrowserRouter>
              <SentryRoutes>
                <Route path="/" element={<RootRedirect />}></Route>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/logout" element={<LogoutPage />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/organizations" element={<OrganizationsPage />}></Route>
                <Route path="/admin" element={<AdminRedirect />}></Route>
                <Route
                  path="/forms/:uniqueId"
                  element={
                    <Suspense fallback={<Loading />}>
                      <FormPage />
                    </Suspense>
                  }
                ></Route>
                <Route
                  path="/rfi/:rfiLink"
                  element={
                    <Suspense fallback={<Loading />}>
                      <RFIFormPage />
                    </Suspense>
                  }
                ></Route>
                <Route element={<ProtectedRoute />}>
                  <Route element={<DashboardLayout />}>
                    <Route path="/dashboard/profiles" element={<ProfilesPage />}></Route>
                    <Route
                      path="/dashboard/profile-wizard"
                      element={
                        <Suspense fallback={<Loading />}>
                          <ProfileWizard />
                        </Suspense>
                      }
                    ></Route>
                    <Route path="/dashboard/profile-groups" element={<ProfileGroups />}></Route>
                    <Route path="/dashboard/wallets" element={<WalletsPage />}></Route>
                    <Route
                      path="/dashboard/wallets-old/:walletID"
                      element={
                        <Suspense fallback={<Loading message="Loading Wallet details..." />}>
                          <Wallet />
                        </Suspense>
                      }
                    ></Route>
                    <Route
                      path="/dashboard/wallets/:walletID"
                      element={
                        <Suspense fallback={<Loading message="Loading Wallet details..." />}>
                          <Wallet />
                        </Suspense>
                      }
                    ></Route>
                    <Route path="/dashboard/screening" element={<ScreeningPanel />}></Route>
                    <Route path="/dashboard/profiles/:profileId" element={<ProfileOverview />}></Route>
                    <Route path="/dashboard/profiles/:profileId/screenings" element={<WatchlistScreening />}></Route>
                    <Route
                      path="/dashboard/profiles/:profileId/screenings/:inquiryId"
                      element={<ProfileScreeningOverview />}
                    ></Route>
                    <Route path="/dashboard/alerts" element={<AlertsPanel />}></Route>
                    <Route path="/dashboard/alerts/:alertID" element={<AlertDetail />}></Route>
                    <Route
                      path="/dashboard/relationship-chart/:profileId"
                      element={
                        <Suspense fallback={<Loading message="Loading relationship chart..." />}>
                          <RelationshipChart />
                        </Suspense>
                      }
                    ></Route>
                    {/* settings related routes */}
                    <Route path="/dashboard/settings/wizard" element={<WizardSettingsPage />}></Route>
                    <Route
                      path="/dashboard/settings/wizard/:profileGroupId"
                      element={<WizardProfileGroupDetails />}
                    ></Route>
                  </Route>
                </Route>
                <Route path="*" element={<ErrorNotFound404 />} />
              </SentryRoutes>
            </BrowserRouter>
            <CookieConsentBanner />
          </div>
        </GoogleOAuthProvider>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
