import {
  Button,
  Input,
  Text,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Box,
  Divider,
  VStack
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRegisterWalletMutation } from '@services/canaria.services';

interface RegisterWalletModalProps {
  address: string;
  orgId: string;
  onClose: () => void;
  isOpen: boolean;
}

interface RegisterWalletParams {
  name: string;
  address: string;
  orgId: string;
  walletnotes?: Array<{
    content: string;
  }>;
}
const RegisterWalletModal: React.FC<RegisterWalletModalProps> = (props) => {
  const [formState, setFormState] = useState({
    name: '',
    address: props.address,
    walletnotes: ''
  });

  const handleChange = ({ target: { name, value } }): void => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const nameIsError = formState.name === '';
  const addressIsError = formState.address === '';

  const [registerWallet, { isLoading }] = useRegisterWalletMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const handleClick = async (e): Promise<void> => {
    try {
      e.preventDefault();
      e.preventDefault();
      const params: RegisterWalletParams = {
        orgId: props.orgId,
        name: formState.name,
        address: formState.address
      };
      if (formState.walletnotes?.trim() !== '') {
        params.walletnotes = [{ content: formState.walletnotes.trim() }];
      }
      const result = await registerWallet(params).unwrap();
      props.onClose();
      navigate('/dashboard/wallets/' + result.id);
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Error while trying to register wallet',
        isClosable: true
      });
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader>Register Blockchain Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" gap={3} bg="#EBF8FF" p={4} alignItems="center">
            <Box color="#2081C3">
              <Icon icon="ri:error-warning-fill" />
            </Box>
            <Text fontSize="sm" fontWeight="medium">
              In order to search for detailed information of a specific blockchain address, the system needs to register
              it first.
            </Text>
          </Box>
          <VStack spacing={6} mt={6}>
            <FormControl isRequired>
              <FormLabel>Name (required)</FormLabel>
              <Input
                isInvalid={nameIsError}
                type="text"
                placeholder="Use a descriptive name that will be searchable"
                onChange={handleChange}
                name="name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Address (required)</FormLabel>
              <Input
                isInvalid={addressIsError}
                type="text"
                placeholder="Blockchain Address"
                onChange={handleChange}
                name="address"
                defaultValue={props.address}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea onChange={handleChange} name="notes" />
            </FormControl>
          </VStack>
        </ModalBody>
        <Divider mt={6} />
        <ModalFooter>
          <Button mr={3} isLoading={isLoading} onClick={props.onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            mr={3}
            isDisabled={nameIsError || addressIsError}
            isLoading={isLoading}
            onClick={handleClick}
            variant="primary"
          >
            Register
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterWalletModal;
