import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Input, useToast, Text } from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-final-form';

import { FormField, type ValidatorType } from '@components/FormField';

interface EditableDateProps {
  label: string;
  value: Date | string | null;
  onConfirmChange: (value: string) => Promise<any>;
  isDisabled?: boolean;
  isCritical?: boolean;
  validate?: ValidatorType | ValidatorType[];
  placeholder?: string;
}

const EditableDate: React.FC<EditableDateProps> = ({
  label,
  value,
  onConfirmChange,
  isDisabled = false,
  isCritical = false,
  validate,
  placeholder
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
  };

  const handleSubmit = async (values: { field: string }): Promise<any> => {
    if (values.field !== value) {
      try {
        await onConfirmChange(values.field);
        setIsEditing(false);
      } catch (error) {
        toast({
          title: 'Error',
          description: `Error while editing ${label}`,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        if (error != null && typeof error === 'object' && 'data' in error) {
          const errorData = (error as { data: object }).data;
          const [firstKey] = Object.keys(errorData);
          return { field: errorData[firstKey] };
        }
        return error;
      }
    } else {
      setIsEditing(false);
    }
  };

  const initialDate = value === null ? null : value instanceof Date ? value : parseISO(value);

  return (
    <Form
      key={isEditing ? 'editing' : 'not-editing'}
      onSubmit={handleSubmit}
      initialValues={{ field: initialDate }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column">
            <Flex align="center">
              <FormField
                name="field"
                label={label}
                direction="horizontal"
                isDisabled={isDisabled || !isEditing}
                maxW={null}
                disableOptionalText
              >
                {({ input }) => (
                  <DatePicker
                    {...input}
                    selected={input.value != null && input.value !== '' ? dayjs(input.value).toDate() : null}
                    onChange={(date) => {
                      input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
                    }}
                    dateFormat="yyyy-MM-dd"
                    autoComplete="off"
                    placeholderText={placeholder}
                    customInput={<Input size="sm" opacity="1 !important" />}
                    disabled={isDisabled || !isEditing}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                )}
              </FormField>
              {isEditing ? (
                <>
                  <IconButton ml="1" size="sm" icon={<CheckIcon />} type="submit" aria-label="confirm" />
                  <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
                </>
              ) : (
                <Box w={!isDisabled ? 'auto' : '32px'}>
                  {!isDisabled && (
                    <IconButton ml="1" size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />
                  )}
                </Box>
              )}
            </Flex>
            {isEditing && isCritical && (
              <Text color="red.500" fontSize="sm" mt="1" ml="2">
                This is a critical field in the profile; updating this information will trigger re-screenings.
              </Text>
            )}
          </Flex>
        </form>
      )}
    />
  );
};

export default EditableDate;
