import mixpanel from 'mixpanel-browser';

import { loadCookiePreferences } from '@features/shared/utils/cookieConsent';
import { type IInitialState } from '@features/user-settings/userSlice';

import { USE_SENTRY, MIXPANEL_KEY } from '../../env';

let instance: Analytics | null = null;

// Define common event types for better type safety
export type TrackingEventType =
  | 'PROFILES_OPENED'
  | 'WALLETS_OPENED'
  | 'ALERTS_OPENED'
  | 'PROFILE_OPENED'
  | 'WALLET_OPENED'
  | 'WALLET_EXPLORER_OPENED'
  | 'SEARCH_FOR_ADVERSE_MEDIA'
  | 'INTAKE_FORM_PAGE_CHANGE'
  | 'INTAKE_FORM_QUESTION_ANSWERED'
  | 'LOGOUT'
  | 'LOGIN';

// Define common properties interface
interface CommonTrackingProps {
  user_id: string;
  user_name: string;
  org_id: string;
  org_name: string;
}

class Analytics {
  initialized: boolean = false;
  constructor() {
    if (instance != null) throw new Error('New instance cannot be created!!');
    this.initializeMixpanel();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    instance = this;
  }

  initializeMixpanel(): void {
    const cookiePreferences = loadCookiePreferences();

    if (USE_SENTRY && cookiePreferences?.consentProps?.performanceCookies === true) {
      mixpanel.init(MIXPANEL_KEY);
      this.initialized = true;
    }
  }

  // Helper method to extract common properties from user
  private static getUserProperties(user: IInitialState): CommonTrackingProps {
    const properties = {
      user_id: user.userId,
      user_name: user.username,
      org_id: user.orgId ?? '',
      org_name: user.orgName
    };
    return properties;
  }

  // Generic tracking method with common initialization check
  private track<T extends Record<string, any>>(eventName: TrackingEventType, properties: T): void {
    if (!this.initialized) return;
    mixpanel.track(eventName, properties);
  }

  // Generic page view tracking method
  trackPageView(eventName: TrackingEventType, user: IInitialState, additionalProps: Record<string, any> = {}): void {
    this.track(eventName, {
      ...Analytics.getUserProperties(user),
      ...additionalProps
    });
  }

  trackLoggedIn(userId: string, orgId: string, orgName: string): void {
    this.track('LOGIN', {
      user_id: userId,
      org_id: orgId,
      org_name: orgName
    });
  }

  trackLoggedOut(userId: string, orgId: string, orgName: string): void {
    this.track('LOGOUT', {
      user_id: userId,
      org_id: orgId,
      org_name: orgName
    });
    if (USE_SENTRY && this.initialized) {
      mixpanel.reset();
    }
  }

  trackIdentify(email: string, firstName: string, lastName: string, created): void {
    if (!this.initialized) return;
    mixpanel.identify(email);
    mixpanel.register({
      email,
      first_name: firstName,
      last_name: lastName
    });
    mixpanel.people.set({
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      $distinct_id: email,
      $created: created
    });
  }

  trackOrganization(orgId: string | undefined, orgName: string): void {
    if (!this.initialized) return;
    mixpanel.register({
      org_id: orgId ?? '',
      org_name: orgName
    });
  }

  trackSurveyPageChange(surveyId: string, oldPage: string | undefined, newPage: string | undefined): void {
    this.track('INTAKE_FORM_PAGE_CHANGE', {
      survey_id: surveyId,
      old_page: oldPage,
      new_page: newPage
    });
  }

  trackSurveyQuestionAnswered(surveyId: string, questionName: string): void {
    this.track('INTAKE_FORM_QUESTION_ANSWERED', {
      survey_id: surveyId,
      question_name: questionName
    });
  }

  trackSearchForAdverseMedia(
    profileId: string,
    userId: string,
    userName: string,
    searchParams: { orgId: string; orgName: string }
  ): void {
    this.track('SEARCH_FOR_ADVERSE_MEDIA', {
      profile_id: profileId,
      user_id: userId,
      user_name: userName,
      org_id: searchParams.orgId,
      org_name: searchParams.orgName
    });
  }

  trackWalletExplorerOpened(
    walletId: string,
    params: { userId: string; userName: string; orgId: string; orgName: string }
  ): void {
    this.track('WALLET_EXPLORER_OPENED', {
      wallet_id: walletId,
      user_id: params.userId,
      user_name: params.userName,
      org_id: params.orgId,
      org_name: params.orgName
    });
  }

  trackProfilesOpened(user: IInitialState): void {
    this.trackPageView('PROFILES_OPENED', user);
  }

  trackWalletsOpened(user: IInitialState): void {
    this.trackPageView('WALLETS_OPENED', user);
  }

  trackAlertsPanelOpened(user: IInitialState): void {
    this.trackPageView('ALERTS_OPENED', user);
  }

  trackProfileOpened(user: IInitialState, profileId: string): void {
    this.trackPageView('PROFILE_OPENED', user, { profile_id: profileId });
  }

  trackWalletOpened(user: IInitialState, walletId: string): void {
    this.trackPageView('WALLET_OPENED', user, { wallet_id: walletId });
  }
}

const analyticsInstance = new Analytics();

export default analyticsInstance;
