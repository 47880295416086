import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { Box, VStack, IconButton, Spinner, useToast } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import ReactMarkdown from 'react-markdown';
import { selectUser, selectActiveOrgID } from '../../features/user-settings/userSlice';
import { useProfileSummaryMutation, useWalletSummaryMutation } from '@services/canaria.services';
import { RootState } from '../../state/store';
import { selectChatHistory, selectNotificationByJobId, SUMMARY_TYPE } from '../../features/ai-chat/aiChatSlice';

export interface AISummaryChatProps {
  /** Function to call when the chat window is closed */
  onClose: () => void;
  /** The type of entity being summarized: 'profile' or 'wallet' */
  entityType: 'profile' | 'wallet';
  /** The ID of the entity (profile or wallet) */
  entityId: string;
  /** Initial position of the chat window */
  initialPosition?: { x: number; y: number };
}

/**
 * A draggable chat window component for AI-powered summaries of profiles or wallets
 */
export const AISummaryChat: React.FC<AISummaryChatProps> = ({
  onClose,
  entityType,
  entityId,
  initialPosition = { x: -300, y: 400 }
}) => {
  const type = entityType === 'wallet' ? SUMMARY_TYPE.WALLET_SUMMARY : SUMMARY_TYPE.PROFILE_SUMMARY;
  const activeOrgID = useSelector(selectActiveOrgID);
  const toast = useToast();

  const messages = useSelector((state: RootState) => selectChatHistory(state, type, entityId));
  const [currentJobId, setCurrentJobId] = useState<string | null>(null);
  // Get notification for the current job ID
  const currentJobNotification = useSelector((state: RootState) =>
    currentJobId ? selectNotificationByJobId(state, currentJobId) : null
  );

  const draggableRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [windowPosition, setWindowPosition] = useState<{ x: number; y: number }>(initialPosition);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const userState = useSelector(selectUser);
  const [profileSummary] = useProfileSummaryMutation();
  const [walletSummary] = useWalletSummaryMutation();

  // Scroll to bottom of messages when new ones arrive
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Effect to handle loading state based on job notification
  useEffect(() => {
    // If we're loading and have a notification for the current job, we can stop loading
    if (isLoading && currentJobId && currentJobNotification) {
      setIsLoading(false);
      setCurrentJobId(null);
    }
  }, [isLoading, currentJobId, currentJobNotification]);

  // Effect to handle timeout for loading state
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    // Only start timeout if we're loading, have a job ID, but no notification yet
    if (isLoading && currentJobId && !currentJobNotification) {
      // Set a timeout to stop loading after 30 seconds if no messages are received
      timeoutId = setTimeout(() => {
        setIsLoading(false);
        setCurrentJobId(null);
        toast({
          title: 'Summary taking longer than expected',
          description: 'The summary is taking longer than expected. It may still arrive shortly.',
          status: 'warning',
          duration: 5000,
          isClosable: true
        });
      }, 30000); // 30 seconds timeout
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading, currentJobId, currentJobNotification, toast]);

  const [bounds, setBounds] = useState({ left: 0, top: 0, right: 0, bottom: 0 });

  // Update bounds when window resizes
  useEffect(() => {
    const updateBounds = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      setBounds({
        left: -windowWidth,
        top: 0,
        right: 0,
        bottom: windowHeight
      });
    };

    updateBounds();
    window.addEventListener('resize', updateBounds);
    return () => window.removeEventListener('resize', updateBounds);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  if (!isVisible) return null;

  const handleSendSummaryMessage = () => {
    // Don't allow multiple requests while loading
    if (isLoading) return;

    setIsLoading(true);

    if (entityType === 'profile') {
      // For profiles
      profileSummary({
        orgId: activeOrgID,
        profileId: entityId
      })
        .unwrap()
        .then((response) => {
            setCurrentJobId(response?.jobId);
        })
        .catch((error) => {
          console.error('Error requesting profile summary:', error);
          setIsLoading(false);
          toast({
            title: 'Error requesting summary',
            description: 'There was an error requesting the profile summary. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        });
    } else {
      // For wallets
      walletSummary({
        orgId: activeOrgID,
        walletId: entityId
      })
        .unwrap()
        .then((response) => {
          setCurrentJobId(response?.jobId);
        })
        .catch((error) => {
          setIsLoading(false);
          toast({
            title: 'Error requesting summary',
            description: 'There was an error requesting the wallet summary. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        });
    }
  };

  return (
    <Draggable
      nodeRef={draggableRef}
      handle=".drag-handle"
      position={windowPosition}
      bounds={bounds}
      onStop={(_, data) => {
        setWindowPosition({ x: data.x, y: data.y });
      }}
    >
      <div ref={draggableRef} style={{ position: 'fixed', zIndex: 9999 }}>
        <VStack spacing={4} align="stretch" w="550px" maxHeight="80vh" bg="white" boxShadow="md" layerStyle="container">
          <Box
            p={5}
            borderRadius="md"
            boxShadow="xl"
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            overflowY="auto"
            minHeight="auto"
            maxHeight="900px"
          >
            <IconButton
              aria-label="Close"
              icon={<Icon icon="material-symbols:close" />}
              onClick={handleClose}
              position="absolute"
              top={2}
              right={2}
              size="sm"
              color="gray.600"
              variant="ghost"
            />
            <Box
              as="div"
              className="drag-handle"
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="100%"
              p={2}
              bg="gray.200"
              borderRadius="md"
              mb={4}
              cursor="move"
            >
              <IconButton
                aria-label="Drag"
                icon={<Icon icon="material-symbols:drag-indicator" />}
                variant="ghost"
                size="sm"
                color="gray.700"
              />
            </Box>
            {messages.length === 0 && (
              <Box as="span" fontSize="2xl" fontWeight="bold" mb={4} textAlign="center">
                Hello, {userState.firstName}! 👋
                <Box as="span" fontSize="sm" fontWeight="normal" color="gray.500" textAlign="center">
                  <br />
                  How can I help you today?
                </Box>
              </Box>
            )}

            <Box
              onClick={handleSendSummaryMessage}
              cursor={isLoading ? 'not-allowed' : 'pointer'}
              display="flex"
              alignItems="center"
              bg={isLoading ? 'gray.50' : 'gray.100'}
              p={4}
              borderRadius="md"
              boxShadow="md"
              _hover={{ bg: isLoading ? 'gray.50' : 'gray.200' }}
              w="100%"
              mb={4}
              opacity={isLoading ? 0.7 : 1}
            >
              <Box display="flex" backgroundColor="white" borderRadius="md" mr={2} height="40px">
                {isLoading ? (
                  <Spinner size="md" color="teal.500" m={2} />
                ) : (
                  <IconButton
                    aria-label={`Summarize ${entityType}`}
                    icon={<Icon icon="material-symbols:summarize-rounded" />}
                    variant="ghost"
                    size="md"
                    color="gray.500"
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" height="32px">
                <Box as="span" fontSize="sm" fontWeight="bold">
                  {isLoading
                    ? currentJobNotification
                      ? 'Processing summary...'
                      : 'Requesting summary...'
                    : 'Summarize content'}
                </Box>

                <Box as="span" fontSize="sm" fontWeight="normal" color="gray.400">
                  {isLoading
                    ? currentJobNotification
                      ? 'AI is analyzing data'
                      : 'Please wait'
                    : `of this ${entityType}`}
                </Box>
              </Box>
            </Box>

            <Box
              bg="gray.50"
              w="100%"
              p={4}
              borderRadius="md"
              boxShadow="md"
              maxHeight="calc(80vh - 200px)"
              overflowY="auto"
              mb={4}
            >
              {messages.map((msg, index) => {
                const isAssistant = msg.startsWith('Assistant:');
                const text = msg.replace(/^Assistant: /, '').replace(/^You: /, '');

                return (
                  <Box
                    key={index}
                    mb={2}
                    p={2}
                    borderRadius="md"
                    bg={isAssistant ? 'gray.200' : 'teal.100'}
                    color={isAssistant ? 'gray.800' : 'teal.800'}
                    ref={index === messages.length - 1 ? messagesEndRef : null}
                  >
                    {isAssistant ? <ReactMarkdown>{text}</ReactMarkdown> : text}
                  </Box>
                );
              })}

              {isLoading && messages.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" p={4} flexDirection="column">
                  <Spinner size="lg" color="teal.500" mb={4} />
                  <Box textAlign="center" color="gray.600">
                    {currentJobNotification ? 'Processing summary data...' : 'Requesting AI summary...'}
                    <Box fontSize="sm" color="gray.400" mt={1}>
                      {currentJobNotification ? 'Analyzing information' : 'Connecting to AI service'}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {/* Input field for future implementation
            <Box display="flex" w="100%" alignItems="center">
              <Input
                ref={inputRef}
                placeholder="Enter your prompt here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleOnKeyDown}
                size="lg"
                mr={2}
                focusBorderColor="teal.500"
                borderColor="teal.300"
                _hover={{ borderColor: 'teal.500' }}
              />
              <Button onClick={handleSendMessage} variant="primary" size="lg" isDisabled={isLoading}>
                Send
              </Button>
            </Box> */}
          </Box>
        </VStack>
      </div>
    </Draggable>
  );
};
