import { authLogoutAction, setTokens } from './authSlice';
import { store } from '../../state/store';
import analytics from '../analytics/analytics';

class AuthAPI {
  private readonly dispatch: (action: any) => void;
  private unsubscribe: (() => void) | null = null;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  login(refreshToken, accessToken, rememberMe): void {
    this.dispatch(setTokens({ refresh: refreshToken, access: accessToken, rememberMe }));

    // Clean up any existing subscription
    if (this.unsubscribe !== null) {
      this.unsubscribe();
      this.unsubscribe = null;
    }

    // Subscribe to the store to wait for user data to be loaded
    // We don't want to do this inmediatly after login because the user needs to choose an organization
    // (or if there's only one organization, it will be selected automatically but regardless we'll receive it after the subscription)
    this.unsubscribe = store.subscribe(() => {
      const state = store.getState();

      // Check if user data and organization data are available
      if (state.user?.userId != null && state.user?.orgId != null && state.user?.orgName != null) {
        // Track login event
        analytics.trackLoggedIn(state.user.userId, state.user.orgId, state.user.orgName);

        // Unsubscribe after tracking to avoid duplicate events
        if (this.unsubscribe !== null) {
          this.unsubscribe();
          this.unsubscribe = null;
        }
      }
    });
  }

  logout(): void {
    if (!store.getState().auth.isLoggedIn) return;

    analytics.trackLoggedOut(
      store.getState().user.userId,
      store.getState().user.orgId ?? '',
      store.getState().user.orgName ?? ''
    );
    localStorage.clear();
    this.dispatch(authLogoutAction());
  }
}

export default AuthAPI;
