import { extendTheme } from '@chakra-ui/react';
import { createTheme } from '@mui/material';

import { CATEGORY_NAMES } from './walletRisks.const';
import { MODE } from '../env';
export enum RISK_BG_COLORS {
  SEVERE = '#d72638C8',
  HIGH = '#f34213C8',
  MEDIUM = '#f5c800C8',
  LOW = '#00a676C8'
}

export enum RISK_BORDER_COLORS {
  SEVERE = '#d72638',
  HIGH = '#f34213',
  MEDIUM = '#f5c800',
  LOW = '#00a676'
}

export enum Risk {
  SEVERE = 'Severe',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low'
}

export const RiskRank = {
  Severe: 4,
  High: 3,
  Medium: 2,
  Low: 1
};

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const RELATION_TYPE_OPTIONS = {
  'PARENT_ENTITY/CONTROLLING_ENTITY': 'Parent Entity/Controlling Entity',
  ULTIMATE_BENEFICIAL_OWNER: 'Ultimate Beneficial Owner',
  CONTROL_PERSON: 'Control Person',
  COMPLIANCE_OFFICER: 'Compliance Officer',
  OTHER: 'Other'
};

export { CATEGORY_NAMES };

export const defaultMaterialTheme = createTheme({
  palette: {
    mode: 'light'
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    button: {
      fontFamily: "'Poppins', sans-serif"
    },
    body1: {
      fontFamily: "'Poppins', sans-serif"
    },
    body2: {
      fontFamily: "'Poppins', sans-serif"
    }
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Poppins', sans-serif"
        }
      }
    }
  }
});

export const statusStyles: Record<string, { color: string; background: string; borderColor: string }> = {
  PR: {
    color: '#4A5568',
    background: 'transparent',
    borderColor: '#4A5568'
  },
  UR: {
    color: '#FFFFFF',
    background: '#718096',
    borderColor: '#718096'
  },
  RS: {
    color: '#2C7A7B',
    background: 'transparent',
    borderColor: '#2C7A7B'
  },
  RR: {
    color: '#FFFFFF',
    background: '#319795',
    borderColor: '#319795'
  },
  ES: {
    color: '#744209',
    background: '#FEEBCB',
    borderColor: '#FEEBCB'
  },
  AP: {
    color: '#FFFFFF',
    background: '#00A676',
    borderColor: '#00A676'
  },
  DE: {
    color: '#FFFFFF',
    background: '#D72638',
    borderColor: '#D72638'
  },
  AR: {
    color: '#0A202C',
    background: '#EDF2F7',
    borderColor: '#EDF2F7'
  }
};

export const chakraTheme = extendTheme({
  components: {
    Form: {
      baseStyle: {
        requiredIndicator: {
          display: 'none'
        }
      }
    },
    Input: {
      baseStyle: {
        field: {
          backgroundColor: '#F7F7F7 !important',
          _focus: {
            backgroundColor: '#FFFFFF !important'
          },
          border: 'none !important',
          fontSize: 'sm'
        }
      }
    },
    Select: {
      baseStyle: {
        field: {
          backgroundColor: '#F7F7F7 !important',
          border: 'none !important',
          fontSize: 'sm'
        }
      }
    },
    Textarea: {
      baseStyle: {
        background: '#F7F7F7',
        fontSize: 'sm'
      },
      defaultProps: {
        bg: '#F7F7F7'
      }
    },
    ChakraSelect: {
      baseStyle: {
        container: {
          bg: '#F7F7F7'
        },
        control: {
          border: 'none !important',
          bg: '#F7F7F7',
          _focus: {
            border: 'none !important'
          },
          fontSize: 'sm'
        },
        input: {
          bg: '#F7F7F7',
          fontSize: 'sm'
        },
        option: {
          _hover: {
            bg: '#E0E0E0'
          },
          fontSize: 'sm'
        }
      }
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: '#BED903',
            borderColor: '#BED903',
            color: '#BED903',
            _hover: {
              bg: '#BED903',
              borderColor: '#BED903'
            }
          }
        }
      }
    },
    Button: {
      variants: {
        primary: {
          bg: 'button.primary'
        },
        primaryLarge: {
          bg: 'button.primary',
          px: 16
        },
        secondary: {
          bg: 'button.secondary',
          border: '1px solid',
          borderColor: 'button.secondaryBorder'
        },
        secondaryLarge: {
          bg: 'button.secondary',
          border: '1px solid',
          borderColor: 'button.secondaryBorder',
          px: 16
        },
        action: {
          bg: 'button.action',
          color: 'button.actionText'
        }
      }
    }
  },
  styles: {
    global: {
      textarea: {
        background: '#F7F7F7 !important',
        border: 'none !important',
        fontSize: 'sm !important'
      },
      input: {
        border: 'none !important',
        fontSize: 'sm !important'
      },
      select: {
        border: 'none !important',
        fontSize: 'sm !important'
      },
      '.chakra-form__label': {
        fontSize: 'sm !important'
      },
      '.chakra-form__error-message': {
        fontSize: 'xs !important'
      }
    }
  },
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`
  },
  semanticTokens: {
    colors: {
      background: '#F7F7F7',
      border: '#E0E0E0',
      container: '#FFFFFF',
      link: '#2081C3',
      divider: '#E0E0E0',
      title: '#2C2E2E',
      description: '#7C7E7E',
      text: {
        secondary: '#7C7E7E'
      },
      button: {
        primary: '#BED903',
        secondary: '#FFFFFF',
        sidebarHover: '#7C7E7E',
        secondaryBorder: '#E0E0E0',
        action: '#000000',
        actionText: '#FFFFFF'
      },
      risk: {
        LOW: '#00a676C8',
        MEDIUM: '#f5c800C8',
        HIGH: '#f34213C8',
        SEVERE: '#d72638C8',
        light: {
          LOW: '#d8efe8C8',
          MEDIUM: '#fff4c5C8',
          HIGH: '#ffe9e7C8',
          SEVERE: '#fed8d7C8'
        },
        solid: {
          LOW: '#00a676',
          MEDIUM: '#f5c800',
          HIGH: '#f34213',
          SEVERE: '#d72638'
        }
      },
      sidebar: {
        background: '#2C2E2E',
        text: '#FFFFFF',
        secondaryText: '#E0E0E0',
        icon: '#7C7E7E',
        userMenu: {
          text: '#000000',
          hover: '#000000'
        }
      },
      stepper: {
        completed: '#BED903',
        pending: '#F7F7F7',
        text: {
          completed: '#2C2E2E',
          pending: '#7C7E7E'
        },
        checkIcon: '#00A676'
      },
      footer: {
        background: '#F7F7F7',
        text: '#7C7E7E'
      },
      modal: {
        dragAndDropBg: '#F7F7F7',
        scanText: '#7C7E7E'
      },
      wizard: {
        addRelatedParty: {
          button: {
            bg: '#F3F3F3',
            color: '#2CE2E'
          }
        },
        forms: {
          required: {
            text: '#E53E3E'
          },
          optional: {
            text: '#7C7E7E'
          }
        }
      }
    }
  },
  layerStyles: {
    container: {
      border: '1px solid #E0E0E0',
      borderRadius: '12px',
      padding: '12px'
    },
    subContainer: {
      border: '1px solid #E0E0E0',
      borderRadius: '8px',
      padding: '12px'
    }
  }
});

export const smoothScrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: 'transparent'
  },
  '&::-webkit-scrollbar-track': {
    width: '6px',
    backgroundColor: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    }
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  '&::-webkit-scrollbar-thumb:vertical': {
    display: 'none'
  },
  '&:hover::-webkit-scrollbar-thumb:vertical': {
    display: 'block'
  }
};
export const baseUrl =
  MODE === 'development'
    ? 'http://127.0.0.1:8000'
    : MODE === 'staging'
      ? 'https://api-dev.cryptocomply.co'
      : 'https://api.cryptocomply.co';
