import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from '../../state/store';

// Define an enum for summary types
// Using ALL_CAPS for enum names is a common convention for constants
export enum SUMMARY_TYPE {
  PROFILE_SUMMARY = 'PROFILE_SUMMARY',
  WALLET_SUMMARY = 'WALLET_SUMMARY'
}

interface AIChatState {
  chatHistory: Record<string, string[]>;
  // Store notifications by job_id
  notificationsByJobId: Record<
    string,
    {
      type: SUMMARY_TYPE;
      id: string; // profile_id or wallet_id
      content: string;
      status: string;
      accumulatedContent: string; // Track accumulated content for streaming
    }
  >;
}

const initialState: AIChatState = {
  chatHistory: {},
  notificationsByJobId: {}
};

export const generateChatKey = (type: SUMMARY_TYPE, id: string): string => `${type}_${id}`;

const aiChatSlice = createSlice({
  name: 'aiChat',
  initialState,
  reducers: {
    setChatHistory: (state, action: PayloadAction<{ type: SUMMARY_TYPE; id: string; messages: string[] }>) => {
      const { type, id, messages } = action.payload;
      state.chatHistory[generateChatKey(type, id)] = messages;
    },
    clearChatHistory: (state, action: PayloadAction<{ type: SUMMARY_TYPE; id: string }>) => {
      const { type, id } = action.payload;
      state.chatHistory[generateChatKey(type, id)] = [];
    },
    updateOrCreateChatHistory: (state, action: PayloadAction<{ type: SUMMARY_TYPE; id: string; message: string }>) => {
      const { type, id, message } = action.payload;
      if (state.chatHistory[generateChatKey(type, id)] != null) {
        state.chatHistory[generateChatKey(type, id)] = [...state.chatHistory[generateChatKey(type, id)], message];
      } else {
        state.chatHistory[generateChatKey(type, id)] = [message];
      }
    },
    // Enhanced reducer to handle notifications by job_id with streaming support
    updateNotificationByJobId: (
      state,
      action: PayloadAction<{
        job_id: string;
        type: SUMMARY_TYPE;
        id: string;
        content: string;
        status: string;
      }>
    ) => {
      const { job_id: jobId, type, id, content, status } = action.payload;
      const chatKey = generateChatKey(type, id);

      // Get existing notification or create a new one
      const existingNotification = state.notificationsByJobId[jobId];

      // If this is a new notification
      if (existingNotification == null) {
        // Create new notification entry
        state.notificationsByJobId[jobId] = {
          type,
          id,
          content,
          status,
          accumulatedContent: content // Initialize accumulated content
        };

        // Add to chat history
        if (state.chatHistory[chatKey] == null) {
          state.chatHistory[chatKey] = [`Assistant: ${content}`];
        } else {
          state.chatHistory[chatKey] = [...state.chatHistory[chatKey], `Assistant: ${content}`];
        }
      } else {
        // This is an update to an existing notification
        // Accumulate the content (similar to the HTTP streaming example)
        const accumulatedContent = content; // For complete replacements

        // Update the notification
        state.notificationsByJobId[jobId] = {
          ...existingNotification,
          content,
          status,
          accumulatedContent
        };

        // Update the chat history
        if (state.chatHistory[chatKey] != null) {
          const lastMessageIndex = state.chatHistory[chatKey].length - 1;
          const lastMessage = state.chatHistory[chatKey][lastMessageIndex];

          if (lastMessage.startsWith('Assistant:')) {
            // Update the last message with accumulated content
            state.chatHistory[chatKey] = [
              ...state.chatHistory[chatKey].slice(0, lastMessageIndex),
              `Assistant: ${accumulatedContent}`
            ];
          } else {
            // Add as a new message if the last message wasn't from the assistant
            state.chatHistory[chatKey] = [...state.chatHistory[chatKey], `Assistant: ${accumulatedContent}`];
          }
        } else {
          // Create a new chat history entry if it doesn't exist
          state.chatHistory[chatKey] = [`Assistant: ${accumulatedContent}`];
        }
      }
    }
  }
});

export const { setChatHistory, clearChatHistory, updateOrCreateChatHistory, updateNotificationByJobId } =
  aiChatSlice.actions;

export const selectChatHistory = (state: RootState, type: SUMMARY_TYPE, id: string): string[] =>
  state.aiChat.chatHistory[generateChatKey(type, id)] ?? [];

// Add a selector to get notifications by job_id
export const selectNotificationByJobId = (state: RootState, jobId: string): any =>
  state.aiChat.notificationsByJobId[jobId] ?? null;

export default aiChatSlice.reducer;
