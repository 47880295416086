import {
  NetworkAlgorand,
  NetworkArbitrumOne,
  NetworkAvalanche,
  NetworkBase,
  NetworkBinanceSmartChain,
  NetworkBitcoin,
  NetworkCardano,
  NetworkCelo,
  NetworkCosmos,
  NetworkDogechain,
  NetworkElastos,
  NetworkEthereum,
  NetworkEthereumClassic,
  NetworkFilecoin,
  NetworkHederaHashgraph,
  NetworkLisk,
  NetworkLitecoin,
  NetworkMultiversx,
  NetworkNearProtocol,
  NetworkOptimism,
  NetworkPolkadot,
  NetworkSolana,
  NetworkSonic,
  NetworkStellar,
  NetworkTron,
  NetworkXrp,
  NetworkZilliqa,
  TokenBCH,
  TokenBNB,
  TokenBTM,
  TokenDASH,
  TokenENS,
  TokenFTM,
  TokenICX,
  TokenIOST,
  TokenIOTA,
  TokenKLAY,
  TokenMATIC,
  TokenNEO,
  TokenOMNI,
  TokenONT,
  TokenPAI,
  TokenQTUM,
  TokenROSE,
  TokenSTEEM,
  TokenXMR,
  TokenXTZ,
  TokenZEC
} from '@web3icons/react';

// This component is absolutely necessary, having all of the possible options
// otherwise we'd have no way to tree shake the library aka we'd have to include it in
// it's entirety in the final bundle
export const BlockChainIcon = ({ 
  network, 
  variant = 'branded' 
}: { 
  network: string;
  variant?: 'mono' | 'branded' | 'background';
}) => {
  const DefaultFallback = () => (
    <img 
      src="/resources/other-blockchain-icon-final-simple.svg" 
      alt="Default blockchain icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
  switch (network) {
    case 'ARB':
      return <NetworkArbitrumOne variant={variant} />;
    case 'AVAX_C':
      return <NetworkAvalanche variant={variant} />;
    case 'BASE':
      return <NetworkBase variant={variant} />;
    case 'BNB':
      return <TokenBNB variant={variant} />;
    case 'BSC':
      return <NetworkBinanceSmartChain variant={variant} />;
    case 'BTC':
      return <NetworkBitcoin variant={variant} />;
    case 'BCH':
      return <TokenBCH variant={variant} />;
    case 'BTM':
      return <TokenBTM variant={variant} />;
    case 'ETH':
      return <NetworkEthereum variant={variant} />;
    case 'ETC':
      return <NetworkEthereumClassic variant={variant} />;
    case 'SOL':
      return <NetworkSolana variant={variant} />;
    case 'ALGO':
      return <NetworkAlgorand variant={variant} />;
    case 'AVAX':
      return <NetworkAvalanche variant={variant} />;
    case 'ADA':
      return <NetworkCardano variant={variant} />;
    case 'CELO':
      return <NetworkCelo variant={variant} />;
    case 'COSMOS':
      return <NetworkCosmos variant={variant} />;
    case 'DASH':
      return <TokenDASH variant={variant} />;
    case 'DOGE':
      return <NetworkDogechain variant={variant} />;
    case 'ELAS':
      return <NetworkElastos variant={variant} />;
    case 'ENS':
      return <TokenENS variant={variant} />;
    case 'FTM':
      return <TokenFTM variant={variant} />;
    case 'FIL':
      return <NetworkFilecoin variant={variant} />;
    case 'HBAR':
      return <NetworkHederaHashgraph variant={variant} />;
    case 'ICX':
      return <TokenICX variant={variant} />;
    case 'MIOTA':
      return <TokenIOTA variant={variant} />;
    case 'IOST':
      return <TokenIOST variant={variant} />;
    case 'KLAY':
      return <TokenKLAY variant={variant} />;
    case 'LSK':
      return <NetworkLisk variant={variant} />;
    case 'LTC':
      return <NetworkLitecoin variant={variant} />;
    case 'XMR':
      return <TokenXMR variant={variant} />;
    case 'NAS':
      return <DefaultFallback />;
    case 'NEO':
      return <TokenNEO variant={variant} />;
    case 'OASIS':
      return <TokenROSE variant={variant} />;
    case 'OMNI':
      return <TokenOMNI variant={variant} />;
    case 'MULTIVERSX':
      return <NetworkMultiversx variant={variant} />;
    case 'NEAR':
      return <NetworkNearProtocol variant={variant} />;
    case 'ONT':
      return <TokenONT variant={variant} />;
    case 'OP':
      return <NetworkOptimism variant={variant} />;
    case 'PAI':
      return <TokenPAI variant={variant} />;
    case 'DOT':
      return <NetworkPolkadot variant={variant} />;
    case 'MATIC':
      return <TokenMATIC variant={variant} />;
    case 'STEEM':
      return <TokenSTEEM variant={variant} />;
    case 'SONIC':
      return <NetworkSonic variant={variant} />;
    case 'XLM':
      return <NetworkStellar variant={variant} />;
    case 'TRX':
      return <NetworkTron variant={variant} />;
    case 'ZEC':
      return <TokenZEC variant={variant} />;
    case 'ZIL':
      return <NetworkZilliqa variant={variant} />;
    case 'QTUM':
      return <TokenQTUM variant={variant} />;
    case 'XRP':
      return <NetworkXrp variant={variant} />;
    case 'XTZ':
      return <TokenXTZ variant={variant} />;
    default:
      return <DefaultFallback />;
  }
};
