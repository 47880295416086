import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import { MODE, SENTRY_DSN, USE_SENTRY } from './env';

if (USE_SENTRY) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false
      }),
      Sentry.replayCanvasIntegration()
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: MODE
  });
} else {
  // eslint-disable-next-line no-console
  console.log('Sentry not active');
}

// Because a user can be pushed to a page by the router
// which will attempt to load a module that COULD no longer exist
// due to an update. Since this occurs when loading a js bundle for a page
// then reloading the page is a fine option.
// PS: the event was added in Vite 4 and comes straight up from the docs
window.addEventListener('vite:preloadError', (event) => {
  // Prevent the error from propagating
  // This is okay because we're literally reloading the page
  event.preventDefault();
  event.stopPropagation();

  // eslint-disable-next-line no-console
  console.log('vite:preloadError', event);

  // Unmount React app first to prevent React errors during reload
  // This is also okay because we're literally reloading the page
  if (root) {
    try {
      root.unmount();
    } catch (e) {
      // Ignore unmount errors
    }
  }

  window.location.reload();
});

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
