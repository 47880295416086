import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import {
  useGetProfileNotesQuery,
  useCreateProfileNoteMutation,
  useUpdateProfileNoteMutation,
  useDeleteProfileNoteMutation
} from '@services/canaria.services';
import NotesManager from '@components/common/NotesManager';

interface ProfileNotesProps {
  profileId: string;
}

const ProfileNotes: React.FC<ProfileNotesProps> = ({ profileId }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }
  const { data: notes = [], refetch } = useGetProfileNotesQuery({
    orgId: activeOrgID,
    profileId
  });
  const [createProfileNote] = useCreateProfileNoteMutation();
  const [updateProfileNote] = useUpdateProfileNoteMutation();
  const [deleteProfileNote] = useDeleteProfileNoteMutation();

  const handleAddNote = async (content: string): Promise<void> => {
    await createProfileNote({
      orgId: activeOrgID,
      profileId,
      content
    }).unwrap();
    await refetch();
  };

  const handleUpdateNote = async (noteId: string, content: string): Promise<void> => {
    await updateProfileNote({
      orgId: activeOrgID,
      profileId,
      noteId,
      content
    }).unwrap();
    await refetch();
  };

  const handleDeleteNote = async (noteId: string): Promise<void> => {
    await deleteProfileNote({
      orgId: activeOrgID,
      profileId,
      noteId
    }).unwrap();
    await refetch();
  };

  return (
    <NotesManager
      notes={notes}
      onAddNote={handleAddNote}
      onUpdateNote={handleUpdateNote}
      onDeleteNote={handleDeleteNote}
    />
  );
};

export default ProfileNotes;
