import { IconButton, Flex, Heading, VStack } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';

import EditableField from '@features/profile-overview/components/EditableField/';
import {
  AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS,
  AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS,
  LICENSING_REGISTRATION_STATUS_OPTIONS,
  REGULATORY_INVESTIGATION_STATUS_OPTIONS
} from '@features/profile-wizard/components/entity/entityOptions';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { type IProfile } from '@models/profileTypes';
import { useGetRelatedProfileQuery } from '@services/canaria.services';

const MotionVStack = motion.create(VStack as any);

export interface BusinessRegulatoryComplianceRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

interface BusinessRegulatoryComplianceProps {
  profile: IProfile;
  handleFieldChange: (field: string) => (value: string | number | Date | Array<string | number>) => Promise<void>;
  mappedCountries: Array<{ value: number; name: string }>;
  handleSectionHover?: (section: string) => void;
}

const BusinessRegulatoryCompliance = forwardRef<BusinessRegulatoryComplianceRef, BusinessRegulatoryComplianceProps>(
  ({ profile, handleFieldChange, mappedCountries, handleSectionHover }, ref) => {
    const [isOpen, setIsOpen] = useState(true);
    const activeOrgID = useSelector(selectActiveOrgID);
    const { data: relatedProfiles } = useGetRelatedProfileQuery({
      orgId: activeOrgID as string,
      profileId: profile.id,
      query: {
        relation_types: 'COMPLIANCE_OFFICER'
      }
    });

    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      expand: () => {
        setIsOpen(true);
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        containerRef.current?.scrollIntoView(options);
      }
    }));

    return (
      <VStack
        ref={containerRef}
        spacing={6}
        align="stretch"
        m={3}
        p={3}
        boxShadow="md"
        bg="white"
        w="100%"
        layerStyle="container"
        onMouseEnter={() => {
          handleSectionHover?.('Business Regulatory Compliance');
        }}
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Heading as="h1" size="md" textAlign="left">
            Business Regulatory Compliance
          </Heading>
          <IconButton
            aria-label="Toggle Business Regulatory Compliance"
            icon={isOpen ? <Icon icon="fa6-solid:chevron-up" /> : <Icon icon="fa6-solid:chevron-down" />}
            size="sm"
            variant="ghost"
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          />
        </Flex>

        <AnimatePresence>
          {isOpen && (
            <MotionVStack
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              spacing={6}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <EditableField
                label="Regulatory Investigation Status"
                value={profile?.regulatoryInvestigationStatus}
                onConfirmChange={handleFieldChange('regulatoryInvestigationStatus')}
                type="select"
                options={REGULATORY_INVESTIGATION_STATUS_OPTIONS.map((option) => ({
                  value: option.value,
                  name: option.label
                }))}
                placeholder="Select Regulatory Investigation Status"
              />
              <EditableField
                label="Regulatory Investigation Details"
                value={profile?.regulatoryInvestigationDetails}
                onConfirmChange={handleFieldChange('regulatoryInvestigationDetails')}
                type="text"
                placeholder="Enter Regulatory Investigation Details"
              />
              <EditableField
                label="Licensing/Registration Status"
                value={profile?.licensingRegistrationStatus}
                onConfirmChange={handleFieldChange('licensingRegistrationStatus')}
                type="select"
                options={LICENSING_REGISTRATION_STATUS_OPTIONS.map((option) => ({
                  value: option.value,
                  name: option.label
                }))}
                placeholder="Select Licensing/Registration Status"
              />
              <EditableField
                label="Licensing/Registration Information"
                value={profile?.licensingRegistrationInformation}
                onConfirmChange={handleFieldChange('licensingRegistrationInformation')}
                type="text"
                placeholder="Enter Licensing/Registration Information"
              />
              <EditableField
                label="AML/Sanctions Program Required?"
                value={Number(profile?.amlSanctionsProgramRequired)}
                onConfirmChange={handleFieldChange('amlSanctionsProgramRequired')}
                type="select"
                options={AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS.map((option) => ({
                  value: Number(option.value),
                  name: option.label
                }))}
                placeholder="Select AML/Sanctions Program Required"
              />
              {/* <MultipleChoiceField
                label="AML/Sanctions Program Elements"
                placeholder="Select an element..."
                profile={profile}
                options={AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS}
                handleFieldChange={handleFieldChange as any}
                fieldLabel="amlSanctionsProgramElements"
                labelMapping={true}
              /> */}
              <EditableField
                label="AML/Sanctions Program Elements"
                value={profile?.amlSanctionsProgramElements}
                onConfirmChange={handleFieldChange('amlSanctionsProgramElements')}
                type="multipleSelect"
                options={AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS.map((option) => ({
                  value: option.value,
                  name: option.label
                }))}
                placeholder="Select AML/Sanctions Program Elements"
              />
              <EditableField
                label="AML/Sanctions Program Comments"
                value={profile?.amlSanctionsProgramComments}
                onConfirmChange={handleFieldChange('amlSanctionsProgramComments')}
                type="text"
                placeholder="Enter AML/Sanctions Program Comments"
              />
              <EditableField
                label="Business Operations Footprint"
                value={profile?.businessOperationsFootprint.map((country) => country.id)}
                onConfirmChange={handleFieldChange('businessOperationsFootprint')}
                type="multipleSelect"
                options={mappedCountries}
                placeholder="Select Business Operations Footprint"
              />
              <EditableField
                label="High Risk Jurisdiction Footprint"
                value={profile?.highRiskJurisdictionFootprint.map((country) => country.id)}
                onConfirmChange={handleFieldChange('highRiskJurisdictionFootprint')}
                type="multipleSelect"
                options={mappedCountries}
                placeholder="Select High Risk Jurisdiction Footprint"
              />
              <EditableField
                label="Privacy Compliance Existence"
                value={profile?.privacyComplianceExistence?.toString()}
                onConfirmChange={handleFieldChange('privacyComplianceExistence')}
                type="select"
                options={[
                  { value: 'true', name: 'Yes' },
                  { value: 'false', name: 'No' }
                ]}
                placeholder="Select Privacy Compliance Existence"
              />
              <EditableField
                label="Age Eligibility Compliance"
                value={profile?.ageEligibilityCompliance?.toString()}
                onConfirmChange={handleFieldChange('ageEligibilityCompliance')}
                type="select"
                options={[
                  { value: 'true', name: 'Yes' },
                  { value: 'false', name: 'No' }
                ]}
                placeholder="Select Age Eligibility Compliance"
              />
              <EditableField
                label="Compliance Officer"
                value={relatedProfiles?.results?.[0]?.profile?.fullLegalName}
                onConfirmChange={async () => {}}
                type="text"
                isDisabled
                placeholder="Register Compliance Officer"
              />
            </MotionVStack>
          )}
        </AnimatePresence>
      </VStack>
    );
  }
);

BusinessRegulatoryCompliance.displayName = 'BusinessRegulatoryCompliance';

export default BusinessRegulatoryCompliance;
