import { Box, Flex, Text, Tooltip, Button } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { PROFILE_TYPE } from '@models/profileTypes';
import { useNavigate } from 'react-router-dom';
interface LinkedProfile {
  id: number;
  name: string;
  full_legal_name: string;
  entity_type: string;
}

interface LinkedProfilesProps {
  linkedProfiles: LinkedProfile[];
}

const LinkedProfiles: React.FC<LinkedProfilesProps> = ({ linkedProfiles }) => {
  const navigate = useNavigate();
  return (
    <Box bg="white" p={5} borderRadius="md" boxShadow="sm" mb={4}>
      <Flex alignItems="center" mb={4}>
        <Text fontWeight="bold" color="gray.700">
          Linked Profiles
        </Text>
        <Tooltip label="Information about linked profiles">
          <InfoIcon ml={2} color="gray.400" fontSize="sm" />
        </Tooltip>
      </Flex>

      <Box>
        {linkedProfiles.map((profile) => (
          <Flex key={profile.id} justify="space-between" align="center" bg="gray.50" p={3} mb={2} borderRadius="md">
            <Box>
              <Text fontWeight="medium">{profile.name}</Text>
              <Text fontSize="sm" color="gray.500">
                {profile.entity_type === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity'} | {profile.full_legal_name}
              </Text>
            </Box>
            <Button
              size="sm"
              colorScheme="green"
              variant="solid"
              color="gray.700"
              bg="button.primary"
              _hover={{ bg: '#8BB52E' }}
              onClick={() => {
                navigate(`/dashboard/profiles/${profile.id}`);
              }}
            >
              View Profile
            </Button>
          </Flex>
        ))}
        {linkedProfiles.length === 0 && (
          <Text fontSize="md" color="gray.500">
            This wallet has no linked profiles yet.
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default LinkedProfiles;
