import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import type { RootState } from '../../state/store';

interface ScreeningScheduled {
  expiresAt: number | null; // Unix timestamp in milliseconds
}

interface ProfileState {
  screeningSchedules: Record<string, ScreeningScheduled>;
}

const initialState: ProfileState = {
  screeningSchedules: {}
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setScreeningScheduled: (state, action: PayloadAction<{ profileId: string; expiresInMs?: number }>) => {
      const { profileId, expiresInMs } = action.payload;
      state.screeningSchedules[profileId] ||= { expiresAt: null };
      state.screeningSchedules[profileId].expiresAt = expiresInMs !== undefined ? Date.now() + expiresInMs : null;
    },
    clearExpiredScreening: (state, action: PayloadAction<{ profileId: string }>) => {
      const { profileId } = action.payload;
      const schedule = state.screeningSchedules[profileId];
      if (schedule?.expiresAt != null && Date.now() > schedule.expiresAt) {
        schedule.expiresAt = null;
      }
    }
  }
});

export const { setScreeningScheduled, clearExpiredScreening } = profileSlice.actions;

export const scheduleScreening = (profileId: string, expiresInMs?: number) => (dispatch) => {
  dispatch(setScreeningScheduled({ profileId, expiresInMs }));

  if (expiresInMs !== undefined) {
    setTimeout(() => {
      dispatch(clearExpiredScreening({ profileId }));
    }, expiresInMs);
  }
};

export const selectScreeningScheduled = (state: RootState, profileId: string): number | null => {
  const profileSchedules = state.profile.screeningSchedules;
  const schedule = profileId in profileSchedules ? profileSchedules[profileId] : null;
  if (schedule === null) {
    return null;
  }

  const { expiresAt } = schedule;
  if (expiresAt != null && Date.now() > expiresAt) {
    return null;
  }

  return expiresAt;
};

// Custom hook to use the screening scheduled selector
export const useScreeningScheduled = (profileId: string): number | null => {
  return useSelector((state: RootState) => selectScreeningScheduled(state, profileId));
};

export default profileSlice.reducer;
