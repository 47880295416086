import { useEffect } from 'react';

import { websocketService } from '../services/websocket';

// Keep track of how many components are using the WebSocket
let activeConnections = 0;

export const useWebSocket = (): typeof websocketService => {
  useEffect(() => {
    // Only connect if this is the first component using the WebSocket
    if (activeConnections === 0) {
      websocketService.connect();
    }

    activeConnections++;

    return () => {
      activeConnections--;

      // Only disconnect when the last component unmounts
      if (activeConnections === 0) {
        websocketService.disconnect();
      }
    };
  }, []);

  return websocketService;
};
