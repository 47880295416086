import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

interface AddressIdentification {
  name: string;
  category: string;
  risk: string;
  source: string;
}

interface AddressIdentificationProps {
  addressIdentifications: AddressIdentification[];
}

const AddressIdentification: React.FC<AddressIdentificationProps> = ({ addressIdentifications }) => {
  const renderRiskBadge = (risk: string) => {
    let riskName = risk;
    let bgColor = `risk.${riskName?.toUpperCase()}`;
    if (risk == null || risk == '') {
      riskName = 'UNKNOWN';
      bgColor = 'gray.300';
    }
    return (
      <Box
        px={2}
        py={0.5}
        borderRadius="md"
        bg={bgColor}
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
        display="inline-block"
      >
        {riskName}
      </Box>
    );
  };

  return (
    <Box bg="white" p={4}>
      <Flex align="center" gap={2} mb={6}>
        <Text fontWeight="bold" color="gray.700">
          Address Identification
        </Text>
        <Tooltip label="Information about address identification">
          <InfoIcon color="gray.400" fontSize="sm" />
        </Tooltip>
      </Flex>

      <Box
        maxH="300px"
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.300',
            borderRadius: '24px'
          }
        }}
      >
        {addressIdentifications?.length === 0 && (
          <Text fontSize="md" color="gray.500">
            No address identifications found yet.
          </Text>
        )}
        {addressIdentifications?.map((identification) => (
          <Flex key={identification.name} direction="column" gap={4} bg="gray.50" p={2} mb={2} borderRadius="md">
            <Box>
              <Text fontSize="sm" color="gray.500" mb={1}>
                Identified Entity
              </Text>
              <Text fontWeight="medium">{identification.name}</Text>
            </Box>

            <Box>
              <Text fontSize="sm" color="gray.500" mb={1}>
                Category
              </Text>
              <Text fontWeight="medium" textTransform="capitalize">
                {identification.category}
              </Text>
            </Box>
            {identification.source && (
              <Box>
                <Text fontSize="sm" color="gray.500" mb={1}>
                  Identification Source
                </Text>
                <Text fontWeight="medium">{identification.source}</Text>
              </Box>
            )}

            <Box>
              <Text fontSize="sm" color="gray.500" mb={1}>
                Risk
              </Text>
              {renderRiskBadge(identification.risk)}
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default AddressIdentification;
