import React, { createContext, useContext, useState } from 'react';
import InfoPanel from './components/InfoPanel.component';

interface InfoPanelContextType {
  isInfoOpen: boolean;
  openInfo: (termId?: string) => void;
  closeInfo: () => void;
  highlightedTerm?: string;
}

const InfoPanelContext = createContext<InfoPanelContextType | undefined>(undefined);

export const InfoPanelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedTerm, setHighlightedTerm] = useState<string>();

  const openInfo = (termId?: string) => {
    setHighlightedTerm(termId);
    setIsOpen(true);
  };

  const closeInfo = () => {
    setIsOpen(false);
    setHighlightedTerm(undefined);
  };

  return (
    <InfoPanelContext.Provider value={{ isInfoOpen: isOpen, openInfo, closeInfo, highlightedTerm }}>
      {children}
      <InfoPanel isOpen={isOpen} onClose={closeInfo} highlightedTerm={highlightedTerm} />
    </InfoPanelContext.Provider>
  );
};

export const useInfoPanel = () => {
  const context = useContext(InfoPanelContext);
  if (context === undefined) {
    throw new Error('useInfoPanel must be used within a InfoPanelProvider');
  }
  return context;
}; 