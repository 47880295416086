import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { canariaApi } from '@services/canaria.services';

import { store, type RootState } from '../../state/store';

// Define an enum for job status values
export enum JOB_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILURE'
}

// Define an enum for job types
export enum JOB_TYPE {
  PROFILE_REPORT = 'profile_report',
  PROFILE_SCREENING = 'profile_screening',
  WALLET_SCREENING = 'wallet_screening'
}

// Base type for all job notifications
interface BaseJobNotification {
  jobId: string;
  type?: JOB_TYPE;
  status: JOB_STATUS;
}

// Profile Report State
export interface ProfileReportState extends BaseJobNotification {
  type: JOB_TYPE.PROFILE_REPORT;
  profileId: number;
  downloadUrl?: string;
}

// Profile Screening State
interface ProfileScreeningState extends BaseJobNotification {
  type: JOB_TYPE.PROFILE_SCREENING;
  profileId: number;
}

// Wallet Screening State
interface WalletScreeningState extends BaseJobNotification {
  type: JOB_TYPE.WALLET_SCREENING;
  walletId: number;
}

export type JobState = ProfileReportState | ProfileScreeningState | WalletScreeningState;

interface AsyncJobsState {
  jobsById: Record<string, JobState>;
}

const initialState: AsyncJobsState = {
  jobsById: {}
};

/**
 * Here we invalidate, the inquiries related to the entity
 * the entity itself and finally the list of entities
 * because the risk or any property may've changed
 */

export const invalidateProfileInquiryTags = (profileId: number): void => {
  store.dispatch(
    canariaApi.util.invalidateTags([
      { type: 'Profile', id: profileId.toString() },
      { type: 'ProfileInquiries', id: profileId.toString() },
      'ProfileList'
    ])
  );
};

export const invalidateWalletInquiryTags = (walletId: number): void => {
  store.dispatch(
    canariaApi.util.invalidateTags([
      { type: 'Wallet', id: walletId.toString() },
      { type: 'WalletInquiry', id: walletId.toString() },
      'Wallet'
    ])
  );
};

const asyncJobsSlice = createSlice({
  name: 'asyncJobs',
  initialState,
  reducers: {
    updateJob: (state, action: PayloadAction<JobState>) => {
      // Simply update the job by its ID
      state.jobsById[action.payload.jobId] = action.payload;

      // Call invalidateProfileInquiryTags when a profile_screening job is received
      if (action.payload.type === JOB_TYPE.PROFILE_SCREENING) {
        const screeningJob = action.payload;
        // We need to defer this call since we can't dispatch inside a reducer
        queueMicrotask(() => {
          invalidateProfileInquiryTags(screeningJob.profileId);
        });
      }

      // Handle wallet screening jobs
      if (action.payload.type === JOB_TYPE.WALLET_SCREENING) {
        const walletJob = action.payload;
        queueMicrotask(() => {
          invalidateWalletInquiryTags(walletJob.walletId);
        });
      }
    },
    clearJobs: (state) => {
      state.jobsById = {};
    },
    clearJobsByType: (state, action: PayloadAction<JOB_TYPE>) => {
      state.jobsById = Object.fromEntries(
        Object.entries(state.jobsById).filter(([_, job]) => job.type !== action.payload)
      );
    }
  }
});

export const { updateJob, clearJobs, clearJobsByType } = asyncJobsSlice.actions;

// Selectors
export const selectJobsByType = (state: RootState, type: JOB_TYPE): JobState[] =>
  Object.values(state.asyncJobs.jobsById).filter((job) => job.type === type);

export const selectJobById = (state: RootState, jobId: string): JobState | undefined => state.asyncJobs.jobsById[jobId];

export const selectJobByTypeAndResourceId = (
  state: RootState,
  type: JOB_TYPE,
  resourceId: number
): JobState | undefined =>
  Object.values(state.asyncJobs.jobsById).find((job) => {
    if (job.type !== type) return false;
    switch (type) {
      case JOB_TYPE.PROFILE_REPORT:
      case JOB_TYPE.PROFILE_SCREENING:
        return (job as ProfileScreeningState | ProfileReportState).profileId === resourceId;
      case JOB_TYPE.WALLET_SCREENING:
        return (job as WalletScreeningState).walletId === resourceId;
    }
    return false;
  });

export default asyncJobsSlice.reducer;
