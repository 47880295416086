import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Divider, type UseToastOptions } from '@chakra-ui/react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  FormControl,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
  InputGroup,
  InputRightElement,
  IconButton
} from '@chakra-ui/react';
import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FormField } from '@components/FormField';

import AuthAPI from '@features/auth/api';
import { Footer, Loading } from '@features/shared/components';
import { useLoginMutation, useLoginWithGoogleMutation } from '@services/canaria.services';
import { baseUrl } from '@utils/consts';

const toastInvalidCredentials: UseToastOptions = {
  status: 'error',
  title: 'Invalid Credentials',
  description: 'The account specified does not exist or the password is incorrect.',
  isClosable: true
};

const toastInvalidGoogleCredentials: UseToastOptions = {
  status: 'error',
  title: 'Invalid Credentials',
  description:
    'The Google account specified is not authorized for our system. Please contact info@canariaconsulting.com for assistance.',
  isClosable: true
};

const toastDefaultError: UseToastOptions = {
  status: 'error',
  title: 'Error',
  description: 'Oh no, there was an error!',
  isClosable: true
};

const toastDefaultGoogleError: UseToastOptions = {
  status: 'error',
  title: 'Error',
  description: 'Oh no, there was an error logging in with Google. Please try again.',
  isClosable: true
};

const RightPanel: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [loginWithGoogle, { isLoading: isLoadingGoogle }] = useLoginWithGoogleMutation();
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (values): Promise<void> => {
    try {
      const authApi = new AuthAPI(dispatch);
      authApi.logout();
      const tokens = await login(values).unwrap();
      authApi.login(tokens.refresh, tokens.access, rememberMe);
      navigate('/dashboard/profiles');
    } catch (err: any) {
      if (err.status === 401) {
        toast(toastInvalidCredentials);
      } else {
        toast(toastDefaultError);
      }
    }
  };

  return (
    <Box 
      bg="background" 
      w="100%" 
      px={{ base: 4, md: 8, lg: 52 }} 
      py={10} 
      display="flex" 
      alignItems="center" 
      justifyContent="center"
    >
      <VStack
        spacing={1}
        align="flex-start"
        w="100%"
        bg="container"
        borderRadius="xl"
        padding={{ base: '16px 20px', md: '24px 40px' }}
        border="1px solid"
        borderColor="border"
        maxWidth="400px"
        boxShadow="0px 1px 2px 0px #1018280D"
      >
        <Center w="100%" mb={10}>
          <VStack spacing={1} gap={3}>
            <img src="/resources/logo.webp" alt="logo"></img>
            <Text fontSize="2xl">Log In</Text>
            <Text fontSize="sm" color="gray.500">
              Please enter your details.
            </Text>
          </VStack>
        </Center>
        <Form
          onSubmit={handleLogin}
          render={({ handleSubmit }) => (
            <FormControl as="form" onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormField name="username" label="Email Address" validate={['required', 'email']} hideLabel>
                  <Input bg="#F7F7F7" placeholder="Email Address" />
                </FormField>

                <FormField name="password" label="Password" validate={['required']} hideLabel>
                  <InputGroup>
                    <Input type={showPassword ? 'text' : 'password'} bg="#F7F7F7" placeholder="Password" />
                    <InputRightElement>
                      <IconButton
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        variant="ghost"
                        size="sm"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormField>

                <Flex justifyContent="space-between" alignItems="center">
                  <Checkbox
                    fontSize="sm"
                    onChange={() => {
                      setRememberMe((prev) => !prev);
                    }}
                    isChecked={rememberMe}
                  >
                    <Text fontSize="sm">Remember me</Text>
                  </Checkbox>
                  <Link
                    href={`${baseUrl}/acct/password-reset/`}
                    fontSize="sm"
                    color="blue.500"
                    isExternal
                    fontWeight="bold"
                  >
                    Forgot your password?
                  </Link>
                </Flex>
                <Button fontSize="sm" variant="primary" type="submit" isLoading={isLoading}>
                  Sign in
                </Button>
                <Center>
                  <Link fontSize="sm" color="blue.500" href="mailto:info@canariaconsulting.com" fontWeight="bold">
                    Request an Account
                  </Link>
                </Center>
                <Flex align="center">
                  <Divider />
                  <Text padding="2" fontSize="sm" color="gray.500">
                    or
                  </Text>
                  <Divider />
                </Flex>
                {isLoadingGoogle ? (
                  <Loading message="Signing you in... Please wait." />
                ) : (
                  <Center w="100%">
                    <GoogleLogin
                      onSuccess={async (credentialResponse) => {
                        const authApi = new AuthAPI(dispatch);
                        authApi.logout();
                        try {
                          const tokens = await loginWithGoogle(credentialResponse).unwrap();
                          authApi.login(tokens.refresh, tokens.access, rememberMe);

                          navigate('/dashboard/profiles');
                        } catch (err: any) {
                          if (err.status === 401) {
                            toast(toastInvalidGoogleCredentials);
                          } else {
                            toast(toastDefaultError);
                          }
                        }
                      }}
                      onError={() => {
                        toast(toastDefaultGoogleError);
                      }}
                      useOneTap
                      containerProps={{
                        style: {
                          boxShadow: '0px 1px 2px 0px #1018280D',
                          width: '100%'
                        }
                      }}
                    />
                  </Center>
                )}
              </Stack>
            </FormControl>
          )}
        />
      </VStack>
    </Box>
  );
};

const SignInForm: React.FC = () => {
  return (
    <Flex flexDirection="column" minH="100vh">
      <Flex flex="1">
        <RightPanel />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default SignInForm;
