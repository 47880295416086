import { Box, Flex } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

interface ExposureFlowProps {
  type: 'counterparty' | 'indirect' | 'ownership';
}

const ExposureFlow: React.FC<ExposureFlowProps> = ({ type }) => {
  const renderDot = (color: string) => <Box w="3" h="3" borderRadius="full" bg={color} display="inline-block" />;

  const renderArrow = () => <Icon icon="heroicons:arrow-small-right" fontSize="16px" color="gray.400" />;

  const flows = {
    counterparty: (
      <Flex align="center" gap={1}>
        {renderDot('#DC2626')} {/* rojo */}
        {renderArrow()}
        {renderDot('#1F2937')} {/* negro */}
      </Flex>
    ),
    indirect: (
      <Flex align="center" gap={1}>
        {renderDot('#DC2626')}
        {renderArrow()}
        {renderDot('#9CA3AF')}
        {renderArrow()}
        {renderDot('#1F2937')}
      </Flex>
    ),
    ownership: (
      <Flex align="center" gap={1}>
        {renderDot('#DC2626')}
      </Flex>
    )
  };

  return flows[type];
};

export default ExposureFlow;
