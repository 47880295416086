import { Thead, Tr } from '@chakra-ui/react';
import { type Table as ITable } from '@tanstack/react-table';

import SortableTableHeader from './SortableTableHeader';

interface HeaderProps {
  table: ITable<any>;
}

const THeader: React.FC<HeaderProps> = ({ table }) => {
  return (
    <Thead position="sticky" top={0} bg="white" zIndex={2}>
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <SortableTableHeader key={header.id} header={header} />
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

export default THeader;
