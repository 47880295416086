import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { capitalize } from '../utils';
import { RiskThreshold } from './ExposureTable.component';

interface RiskRanges {
  LOW: { min: number; max: number };
  MEDIUM: { min: number; max: number };
  HIGH: { min: number; max: number };
  SEVERE: { min: number; max: number };
}

interface RiskPercentBarProps {
  riskCategory: string;
  ranges: RiskRanges;
  value: number;
  riskLevel: string;
  thresholds: RiskThreshold[];
}

const riskLevels = ['LOW', 'MEDIUM', 'HIGH', 'SEVERE'] as const;

const RiskPercentBar: React.FC<RiskPercentBarProps> = ({ riskCategory, ranges, value, thresholds, riskLevel }) => {
  const getRiskColor = (risk: string): string => {
    return `risk.light.${risk.toUpperCase()}`;
  };

  const getValueColor = (value: number): string => {
    for (const level of riskLevels) {
      const range = ranges[level];
      if (value > range.min && value <= range.max) {
        return `risk.${level.toUpperCase()}`;
      }
    }
    return '#94A3B8';
  };

  const TooltipMessage: React.FC<{ riskCategory: string; ranges: RiskRanges }> = ({ riskCategory, ranges }) => {
    const origin = thresholds.find((threshold) => threshold.risk.toLowerCase() === riskLevel.toLowerCase())?.origin;
    let message = '';
    const validRanges = Object.entries(ranges)
      .filter(([_, range]) => range.max > 0)
      .map(([key, range]) => ({
        risk: key,
        min: range.min,
        max: range.max
      }));
    if (validRanges.length === 0) {
      return (
        <Text>
          <strong>{capitalize(riskCategory)}</strong>: No valid ranges found
        </Text>
      );
    } else if (validRanges.length === 1) {
      return (
        <Text>
          <strong>{capitalize(riskCategory)}</strong>: According to{' '}
          {origin === 'organization'
            ? "your organization's"
            : origin === 'default'
              ? 'the default'
              : `your profile group's (${origin})`}{' '}
          risk settings, any exposure to this category (0-100%) results in a {validRanges[0].risk.toLowerCase()} risk
          rating. Even minimal exposure triggers this rating.
        </Text>
      );
    }
    message = `According to ${origin === 'organization' ? "your organization's" : origin === 'default' ? 'the default' : `your profile group's (${origin})`} risk settings, funds with ${validRanges[0].min}%–${validRanges[0].max}% exposure to this category are considered ${validRanges[0].risk.toLowerCase()} risk, while exposure above ${validRanges[0].max}% (up to ${validRanges[1].max}%) triggers a ${validRanges[1].risk.toLowerCase()} risk rating.`;

    return (
      <Text>
        <strong>{capitalize(riskCategory)}</strong>: {message}
      </Text>
    );
  };

  const renderSegments = () => {
    const riskLevels = ['LOW', 'MEDIUM', 'HIGH', 'SEVERE'] as const;
    const segments = riskLevels
      .map((level) => ({
        min: ranges[level].min,
        max: ranges[level].max,
        color: getRiskColor(level)
      }))
      .filter((segment) => segment.max > 0);

    if (segments.length === 0) return null;

    return segments.map((segment, index) => {
      const width = segment.max - segment.min;
      return (
        <Box
          key={index}
          h="full"
          w={`${width}%`}
          ml={`${segment.min}%`}
          bg={segment.color}
          position="absolute"
          left={0}
        />
      );
    });
  };

  return (
    <Box>
      <Tooltip
        hasArrow
        bg="white"
        color="gray.700"
        p={3}
        label={<TooltipMessage riskCategory={riskCategory} ranges={ranges} />}
      >
        <Box h="32px" bg="gray.100" overflow="hidden" position="relative" cursor="help">
          <Flex h="full" w="full">
            {renderSegments()}
            <Box
              position="absolute"
              left={0}
              h="16px"
              w={`${value}%`}
              bg={getValueColor(value)}
              top="50%"
              transform="translateY(-50%)"
              zIndex={2}
            />
          </Flex>
          <Flex position="absolute" top={0} right={2} h="full" align="center" zIndex={3}>
            <Text fontSize="xs" fontWeight="medium" color="gray.700" textShadow="0px 0px 2px white">
              {value}%
            </Text>
          </Flex>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default RiskPercentBar;
