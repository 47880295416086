import { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import NotesDisplay from '../NotesDisplay.component';

export const DynamicNotesContainer: React.FC<{
  data: any;
  walletID: string;
  orgId: string;
  updateWallet: any;
  updateWalletIsLoading: boolean;
  newWalletInquiry: any;
  newWalletInquiryLoading: boolean;
}> = ({ data, walletID, orgId, updateWallet, updateWalletIsLoading }) => {
  const [notesExpanded, setNotesExpanded] = useState(false);
  return (
    <>
      <Flex flexDirection="row" flexWrap="wrap" gap={3} alignItems="stretch">
        <Box display="flex" flexDirection="column" flex="1">
          {!notesExpanded && (
            <NotesDisplay
              notes={data.notes}
              walletId={walletID}
              orgId={orgId}
              updateWallet={updateWallet}
              updateWalletIsLoading={updateWalletIsLoading}
              isExpanded={notesExpanded}
              onExpand={() => {
                setNotesExpanded((prev) => !prev);
              }}
            />
          )}
        </Box>
      </Flex>
      {notesExpanded && (
        <Box mt={4}>
          <NotesDisplay
            notes={data.notes}
            walletId={walletID}
            orgId={orgId}
            updateWallet={updateWallet}
            updateWalletIsLoading={updateWalletIsLoading}
            isExpanded={notesExpanded}
            onExpand={() => {
              setNotesExpanded((prev) => !prev);
            }}
          />
        </Box>
      )}
    </>
  );
};
