import { useToast } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { type IWallet } from '@models/walletTypes';
import {
  useRegisterWalletMutation,
  useGetBlockchainNetworksQuery,
  useValidateAddressMutation,
  useGetWalletByAddressImperativeMutation
} from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';

interface WalletFormReturn {
  validationMessage: string;
  messageColor: string;
  canAddWallet: boolean;
  isLoading: boolean;
  isValidationLoading: boolean;
  isBlockchainNetworksLoading: boolean;
  networks: Array<{ value: string; label: string; layer: string }>;
  existingWallet: IWallet | null;
  handleValidateAddress: (values: any) => Promise<void>;
  handleSubmit: (values: any, skipConfirmation?: boolean) => Promise<object | undefined>;
  handleFieldChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleClose: () => void;
}

export const useWalletForm = (
  orgId: string,
  profileId: string | undefined,
  showConfirmModal?: () => void,
  onSuccess?: () => void
): WalletFormReturn => {
  const toast = useToast();
  const activeOrgID = useSelector(selectActiveOrgID);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [messageColor, setMessageColor] = useState('gray');
  const [canAddWallet, setCanAddWallet] = useState<boolean>(false);
  const [existingWallet, setExistingWallet] = useState<IWallet | null>(null);

  const [getWalletByAddressImperative] = useGetWalletByAddressImperativeMutation();
  const [registerWallet, { isLoading }] = useRegisterWalletMutation();
  const { data: networks, isLoading: isBlockchainNetworksLoading } = useGetBlockchainNetworksQuery({
    orgId: activeOrgID
  });
  const [validateAddress, { isLoading: isValidationLoading }] = useValidateAddressMutation();

  const handleValidateAddress = async (values: any): Promise<void> => {
    try {
      if (values.blockchain == null || values.address == null) {
        toast({
          title: 'Error',
          description: 'Address and blockchain are required',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
        return;
      }

      const result = await validateAddress({
        orgId,
        address: values.address,
        blockchain: values.blockchain
      }).unwrap();

      if (!result.canBeValidated) {
        setValidationMessage(
          'We currently do not support validation for this blockchain. If the address is correct feel free to ignore this message.'
        );
        setMessageColor('orange');
        setCanAddWallet(true);
      } else if (result.isValid) {
        let message = `The format of the address is OK.`;
        if (result.validatedAddress !== values.address) {
          message += `\nNew vs Old address:\n${values.address}\n${result.validatedAddress}`;
          message += `\nThis new address is a checksumed version of the old one.`;
        }
        if (values.blockchain === 'ENS') {
          message = `You provided an ENS address that currently translates to ${result.validatedAddress}.`;
        }
        setValidationMessage(message);
        setMessageColor('green');
        setCanAddWallet(true);
      } else {
        let message = 'The address you entered is not valid.';
        if (values.blockchain === 'ENS') {
          message = 'Your current ENS domain does not translate to an actual address.';
        } else if (result.isChecksumed) {
          message += ` Your address have lower and upper case letters. It was validated as a checksum address and it failed.`;
        }
        setValidationMessage(message);
        setMessageColor('red');
        setCanAddWallet(false);
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: 'An internal error has occurred',
        status: 'error',
        isClosable: true
      });
      setCanAddWallet(false);
      throw err;
    }
  };

  const handleSubmit = async (values: any, skipConfirmation = false): Promise<object | undefined> => {
    try {
      const result = await getWalletByAddressImperative({
        orgId,
        walletQuery: values.address
      });
      const wallet = 'data' in result ? result.data?.results?.[0] : undefined;

      if (wallet != null) {
        // if wallet exists, there are two cases in which we can't call the api to register the wallet:
        // 1. the profile is already associated with the wallet
        // 2. the wallet is already registered in the organization, which means we are using the wallet form from the wallet page
        const inWalletPage = profileId == null; // if no profileId is provided, it means we are in the wallet page
        const isProfileAlreadyAssociated =
          wallet.profiles?.some((profile) => profile.id === Number(profileId)) ?? false;

        if (inWalletPage || isProfileAlreadyAssociated === true) {
          toast({
            title: 'Error',
            description:
              isProfileAlreadyAssociated === true
                ? 'Profile is already associated with this wallet'
                : 'Wallet already registered in organization',
            status: 'error',
            isClosable: true
          });
          return;
        }

        if (!skipConfirmation) {
          setExistingWallet(wallet);
          showConfirmModal?.();
          return;
        }
      }

      const response = await registerWallet({ ...values, orgId, profile: profileId });
      if ('error' in response) {
        toast({
          title: 'Error',
          description: 'An error occurred while registering the wallet',
          status: 'error',
          isClosable: true
        });
        return convertObjectKeysToCamelCase((response.error as { data: object }).data);
      }
      setExistingWallet(null);
      onSuccess?.();
    } catch (error) {
      console.error(error);
    }
  };

  const resetAddressValidation = (): void => {
    setValidationMessage('');
    setMessageColor('gray');
    setCanAddWallet(false);
  };

  const handleFieldChange = resetAddressValidation;
  const handleClose = resetAddressValidation;

  const mappedNetworks = useMemo(() => {
    return networks
      ?.map((network) => ({
        value: network.value,
        label: network.name,
        layer: network.layer
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [networks]);

  return {
    validationMessage,
    messageColor,
    canAddWallet,
    isLoading,
    isValidationLoading,
    isBlockchainNetworksLoading,
    networks: mappedNetworks ?? [],
    existingWallet,
    handleValidateAddress,
    handleSubmit,
    handleFieldChange,
    handleClose
  };
};
