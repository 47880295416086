import { Container, Heading, Flex, Button } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { Box, ThemeProvider, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { selectActiveOrgID, selectUser } from '@features/user-settings/userSlice';
import { useGetAlertsQuery } from '@services/canaria.services';
import { defaultMaterialTheme } from '@utils/consts';
import analyticsInstance from '@features/analytics/analytics';

const STATUS_COLORS = {
  O: {
    bg: '#00A676',
    text: '#FFFFFF'
  },
  C: {
    bg: '#6C757D',
    text: '#FFFFFF'
  },
  R: {
    bg: '#FF9F1C',
    text: '#000000'
  },
  E: {
    bg: '#E71D36',
    text: '#FFFFFF'
  }
};

const columns: Array<MRT_ColumnDef<any>> = [
  {
    header: 'ID',
    accessorKey: 'id',
    size: 4,
    Cell: ({ cell: { getValue } }: any) => <Link to={`/dashboard/alerts/${getValue()}`}>{getValue()}</Link>
  },
  {
    header: 'Status',
    accessorKey: 'status',
    Cell: ({ cell: { getValue } }) => {
      const status = getValue() as keyof typeof STATUS_COLORS;
      const statusColor = STATUS_COLORS[status] ?? { bg: '#E2E8F0', text: '#1A202C' };

      return (
        <Box
          sx={{
            backgroundColor: statusColor.bg,
            color: statusColor.text,
            padding: '2px 8px',
            borderRadius: '6px',
            display: 'inline-block',
            fontSize: '0.875rem',
            fontWeight: '500',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            textTransform: 'uppercase'
          }}
        >
          {(() => {
            switch (status) {
              case 'O':
                return 'Open';
              case 'C':
                return 'Closed';
              case 'R':
                return 'Under Review';
              case 'E':
                return 'Escalated';
              default:
                return 'Unknown';
            }
          })()}
        </Box>
      );
    },
    size: 4
  },
  {
    header: 'Name',
    accessorFn(originalRow) {
      if (originalRow.resourcetype === 'WatchlistAlert') {
        return originalRow.name;
      } else if (originalRow.resourcetype === 'WalletAlert') {
        return originalRow.wallet?.name;
      }
      return '-';
    },
    Cell: ({ cell: { getValue } }: any) => <>{getValue()}</>,
    size: 8
  },
  {
    header: 'Source',
    id: 'resourcetype',
    accessorFn(originalRow) {
      if (originalRow.resourcetype === 'WatchlistAlert') {
        return 'Watchlist Screening';
      } else if (originalRow.resourcetype === 'WalletAlert') {
        return 'Wallet Screening';
      } else if (originalRow.resourcetype === 'NegativeNewsAlert') {
        return 'Adverse Media';
      }
      return '-';
    },
    Cell: ({ cell: { getValue } }: any) => {
      return <>{getValue()}</>;
    },
    size: 8
  },
  {
    header: 'Created at',
    accessorKey: 'created_at',
    Cell: ({ cell: { getValue } }: any) => dayjs(getValue()).format('YYYY-MM-DD hh:mm:ss A'),
    size: 6
  },
  {
    header: 'Assigned to',
    size: 6,
    accessorFn: (row) => {
      if (row.reviewed_by == null) return '-';
      const fullName = `${row.reviewed_by?.first_name ?? ''} ${row.reviewed_by?.last_name ?? ''}`.trim();
      return fullName === '' ? '-' : fullName;
    }
  }
];

const TableContent: React.FC<{ orgId: string }> = ({ orgId }) => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });

  const { data, isFetching, isLoading } = useGetAlertsQuery({
    orgId,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter
    }
  });

  const { table } = useCustomMaterialTable({
    columns,
    data: data?.results ?? [],
    rowCount: data?.count ?? 0,
    isLoading,
    isFetching,
    pagination,
    setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting
  });

  return <MaterialReactTable table={table} />;
};

const AlertsPanel: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  analyticsInstance.trackAlertsPanelOpened(user);

  return (
    <Container maxW="8xl">
      <Flex ml={10} gap={4} alignItems="center" mb={4}>
        <Button
          px={0}
          onClick={() => {
            navigate(-1);
          }}
          variant="secondary"
        >
          <Icon icon="ion:arrow-back-outline" />
        </Button>
        <Heading as="h3" size="md">
          Alerts
        </Heading>
      </Flex>

      <ThemeProvider theme={defaultMaterialTheme}>
        <Paper
          elevation={2}
          style={{
            maxWidth: '100%',
            overflowX: 'auto',
            padding: '1em',
            borderRadius: '12px'
          }}
        >
          <TableContent orgId={activeOrgID} />
        </Paper>
      </ThemeProvider>
    </Container>
  );
};

export default AlertsPanel;
