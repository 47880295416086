import { useNavigate } from 'react-router-dom';
import { Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { BACKEND_URL } from '../../env';

/**
 * Component that provides a button to open the backend admin interface in a new tab
 */
const AdminRedirect: React.FC = () => {
  const navigate = useNavigate();
  
  const handleOpenAdmin = () => {
    window.open(`${BACKEND_URL}/admin/`, '_blank', 'noopener,noreferrer');
  };
  
  const handleGoBack = () => {
    navigate(-1);
  };
  
  return (
    <Flex 
      width="100%" 
      height="100vh" 
      justifyContent="center" 
      alignItems="center"
    >
      <VStack spacing={8} p={8} borderWidth="1px" borderRadius="lg" shadow="md">
        <Heading size="lg">Admin Access</Heading>
        <Text>Click the button below to open the admin interface in a new tab</Text>
        <Button 
          colorScheme="blue" 
          size="lg" 
          onClick={handleOpenAdmin}
        >
          Open Admin
        </Button>
        <Button 
          variant="outline" 
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </VStack>
    </Flex>
  );
};

export default AdminRedirect; 