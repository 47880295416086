import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Text,
  VStack,
  Flex
} from '@chakra-ui/react';
import { SearchIcon, InfoIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import { glossary, type GlossaryItem } from './glossary';

interface InfoPanelProps {
  isOpen: boolean;
  onClose: () => void;
  highlightedTerm?: string;
}

const InfoPanel: React.FC<InfoPanelProps> = ({ isOpen, onClose, highlightedTerm }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredTerms, setFilteredTerms] = useState<GlossaryItem[]>(glossary);

  useEffect(() => {
    if (isOpen && highlightedTerm) {
      setTimeout(() => {
        const element = document.getElementById(highlightedTerm);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  }, [isOpen, highlightedTerm]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = glossary.filter(
        (term) =>
          term.term?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          term.definition?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredTerms(filtered);
    } else {
      setFilteredTerms(glossary);
    }
  }, [searchTerm]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md" closeOnOverlayClick={true}>
      <DrawerContent boxShadow="-4px 0 6px -1px rgba(0, 0, 0, 0.1)" bg="white">
        <DrawerHeader py={4}>
          <Flex align="center" justify="space-between" px={6} h="full">
            <Flex align="center" gap={2}>
              <InfoIcon color="gray.500" />
              <Text>Glossary</Text>
            </Flex>
            <DrawerCloseButton
              position="relative"
              top="unset"
              right="unset"
              bg="gray.100"
              borderRadius="md"
              _hover={{ bg: 'gray.200' }}
            />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </InputGroup>

          <VStack align="stretch" spacing={4}>
            {filteredTerms.map((item) => (
              item.id === 'divisor' && !item.term && !item.definition ? (
                <Box key={item.id} h="1px" bg="gray.200" my={2} />
              ) : (
                <Box
                  key={item.id}
                  id={item.id}
                  p={4}
                  borderRadius="md"
                  bg={highlightedTerm === item.id ? '#f3f5e1' : 'white'}
                  transition="background-color 0.3s"
                >
                  <Text fontWeight="bold" mb={2}>
                    {item.term}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {item.definition}
                  </Text>
                </Box>
              )
            ))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default InfoPanel;
