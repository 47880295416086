import { combineReducers } from 'redux';

import aiChatReducer from '@features/ai-chat/aiChatSlice';
import asyncJobsReducer from '@features/async-jobs/asyncJobsSlice';
import authReducer from '@features/auth/authSlice';
import profileReducer from '@features/profile/profile_slice';
import userReducer from '@features/user-settings/userSlice';
import { canariaFormApi } from '@services/canaria.form.services';
import { canariaRFIApi } from '@services/canaria.rfi.services';
import { canariaApi } from '@services/canaria.services';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  [canariaApi.reducerPath]: canariaApi.reducer,
  [canariaFormApi.reducerPath]: canariaFormApi.reducer,
  [canariaRFIApi.reducerPath]: canariaRFIApi.reducer,
  aiChat: aiChatReducer,
  asyncJobs: asyncJobsReducer,
  profile: profileReducer
});
