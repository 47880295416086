import { Box, Flex, Text, Link as ChakraLink, Button, Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getStatusText, truncateAddress } from '@features/panels/alertsPanel/utils/alertUtils';
import { selectUser } from '@features/user-settings/userSlice';
import type { Alert } from '@models/alert';

interface AlertHeaderProps {
  alert: Alert;
  onReassign: () => void;
}

const AlertHeader: React.FC<AlertHeaderProps> = ({ alert, onReassign }) => {
  const isWatchlist = alert.resourcetype === 'WatchlistAlert';
  const currentUser = useSelector(selectUser);

  const formatDateTime = (date: string | null): { date: string; time: string } => {
    if (date == null) return { date: '-', time: '-' };
    return {
      date: dayjs(date).format('YYYY-MM-DD'),
      time: dayjs(date).format('hh:mm:ss A')
    };
  };

  const createdDateTime = formatDateTime(alert.createdAt);
  const modifiedDateTime = formatDateTime(alert.closedDate);

  const renderDivider = (): React.ReactNode => (
    <Divider orientation="vertical" borderColor="#E0E0E0" height="auto" mx={2} />
  );

  const getBadgeColorScheme = (status: string): { bg: string; color: string; border: string } => {
    switch (status.toLowerCase()) {
      case 'open':
        return { bg: '#C6F6D5', color: '#22543D', border: '#C6F6D5' };
      case 'closed':
        return { bg: '#FDECEA', color: '#611A15', border: '#611A15' };
      case 'escalated':
        return { bg: '#FFF4E5', color: '#663C00', border: '#663C00' };
      default:
        return { bg: 'transparent', color: 'inherit', border: 'inherit' };
    }
  };

  const statusColors = getBadgeColorScheme(getStatusText(alert.status));

  return (
    <Box p={5} bg="white" mb={4} layerStyle="container" py={7}>
      <Flex gap={1} alignItems="stretch">
        <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
          <Text fontWeight="bold" color="#7C7E7E">
            Creation Date
          </Text>
          <Box>
            <Text fontWeight="medium">{createdDateTime.date}</Text>
            <Text fontWeight="medium">{createdDateTime.time}</Text>
          </Box>
        </Box>

        {renderDivider()}

        <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
          <Text fontWeight="bold" color="#7C7E7E">
            Last Modification
          </Text>
          <Box>
            <Text fontWeight="medium">{modifiedDateTime.date}</Text>
            <Text fontWeight="medium">{modifiedDateTime.time}</Text>
          </Box>
        </Box>

        {renderDivider()}

        <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
          <Text fontWeight="bold" color="#7C7E7E">
            Alert Type
          </Text>
          <Box display="inline-block" px={2} py={1} border="1px solid" borderColor="#2081C3" borderRadius="md">
            <Text color="#2081C3" fontWeight="medium" fontSize="sm" textTransform="uppercase">
              {alert.resourcetype}
            </Text>
          </Box>
        </Box>

        {renderDivider()}

        <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
          <Text fontWeight="bold" color="#7C7E7E">
            Alert Status
          </Text>
          <Box
            display="inline-flex"
            alignSelf="flex-start"
            px={1.5}
            py={0.5}
            bg={statusColors.bg}
            border="1px solid"
            borderColor={statusColors.border}
            borderRadius="md"
          >
            <Text color={statusColors.color} fontWeight="medium" fontSize="sm" textTransform="uppercase">
              {getStatusText(alert.status)}
            </Text>
          </Box>
        </Box>

        {renderDivider()}

        <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
          <Text fontWeight="bold" color="#7C7E7E">
            {isWatchlist ? 'Profile ID' : 'Wallet Address'}
          </Text>
          <ChakraLink
            as={Link}
            to={`/dashboard/${isWatchlist ? 'profiles' : 'wallets'}/${isWatchlist ? alert.profile.id : alert.wallet.id}`}
            color="blue.400"
            textDecoration={isWatchlist ? 'none' : 'underline'}
          >
            <Text fontWeight="medium">{isWatchlist ? alert.profile.id : truncateAddress(alert.wallet.address)}</Text>
          </ChakraLink>
        </Box>

        {alert.reviewedBy != null && (
          <>
            {renderDivider()}
            <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
              <Text fontWeight="bold" color="#7C7E7E">
                Assigned to
              </Text>
              <Box maxW="200px">
                <Text fontWeight="medium" whiteSpace="normal" wordBreak="break-word">
                  {alert.reviewedBy.firstName} {alert.reviewedBy.lastName}
                </Text>
                <Text fontWeight="medium" whiteSpace="normal" wordBreak="break-all">
                  ({alert.reviewedBy.email})
                </Text>
                {currentUser.email !== alert.reviewedBy.email && (
                  <Button size="sm" onClick={onReassign} bg="black" color="white" _hover={{ bg: 'gray.800' }} mt={2}>
                    Reassign to me
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}

        {isWatchlist ? (
          <>
            {renderDivider()}
            <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
              <Text fontWeight="bold" color="#7C7E7E">
                Total Hits
              </Text>
              <Text fontWeight="medium" color={alert.profileInquiries?.length === 0 ? '#E0E0E0' : 'inherit'}>
                {alert.profileInquiries?.length}
              </Text>
            </Box>

            {renderDivider()}
            <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
              <Text fontWeight="bold" color="#7C7E7E">
                Total False Positives
              </Text>
              <Text
                fontWeight="medium"
                color={
                  alert.profileInquiries?.filter((inquiry) => inquiry.resolution === 'FALSE_POSITIVE').length === 0
                    ? '#E0E0E0'
                    : 'inherit'
                }
              >
                {alert.profileInquiries?.filter((inquiry) => inquiry.resolution === 'FALSE_POSITIVE').length}
              </Text>
            </Box>

            {renderDivider()}
            <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
              <Text fontWeight="bold" color="#7C7E7E">
                Total True Matches
              </Text>
              <Text
                fontWeight="medium"
                color={
                  alert.profileInquiries?.filter((inquiry) => inquiry.resolution === 'TRUE_MATCH').length === 0
                    ? '#E0E0E0'
                    : 'inherit'
                }
              >
                {alert.profileInquiries?.filter((inquiry) => inquiry.resolution === 'TRUE_MATCH').length}
              </Text>
            </Box>
          </>
        ) : (
          <>
            {renderDivider()}
            <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
              <Text fontWeight="bold" color="#7C7E7E">
                Previous Wallet Exposure
              </Text>
              <Box
                layerStyle="subContainer"
                bgColor={`risk.light.${alert.prevInquiryResult?.result?.risk?.toUpperCase()}`}
                borderColor={`risk.${alert.prevInquiryResult?.result?.risk?.toUpperCase()}`}
                px={1.5}
                py={0.5}
                alignSelf="flex-start"
              >
                <Text color={`risk.${alert.prevInquiryResult?.result?.risk?.toUpperCase()}`} fontWeight="bold">
                  {alert.prevInquiryResult?.result?.risk}
                </Text>
              </Box>
            </Box>

            {renderDivider()}
            <Box display="flex" flexDirection="column" justifyContent="space-between" minH="100px">
              <Text fontWeight="bold" color="#7C7E7E">
                New Wallet Exposure
              </Text>
              <Box
                layerStyle="subContainer"
                bgColor={`risk.light.${alert.currentInquiryResult?.result?.risk?.toUpperCase()}`}
                borderColor={`risk.${alert.currentInquiryResult?.result?.risk?.toUpperCase()}`}
                px={1.5}
                py={0.5}
                alignSelf="flex-start"
              >
                <Text color={`risk.${alert.currentInquiryResult?.result?.risk?.toUpperCase()}`} fontWeight="bold">
                  {alert.currentInquiryResult?.result?.risk}
                </Text>
              </Box>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default AlertHeader;
