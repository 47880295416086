import { Box, Button, Flex, IconButton, Input, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { StyledSelect } from '@components/common/StyledSelect';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetProfileGroupsQuery } from '@services/canaria.services';

enum ProfileStatus {
  PR = 'Pending Review',
  UR = 'Under Review',
  RS = 'RFI Sent',
  RR = 'RFI Responded',
  ES = 'Escalated',
  AP = 'Approved',
  DE = 'Declined',
  AR = 'Archived'
}

export interface ProfilesFilterState {
  profileStatus: string[];
  profileGroup: string[];
  profileType: string[];
  createdDateFrom: Date | null;
  createdDateTo: Date | null;
  modifiedDateFrom: Date | null;
  modifiedDateTo: Date | null;
}

interface ProfilesFilterProps {
  filters: ProfilesFilterState;
  setFilters: React.Dispatch<React.SetStateAction<ProfilesFilterState>>;
}

const ENTITY_PROFILE = 'EntityProfile';
const INDIVIDUAL_PROFILE = 'IndividualProfile';

export const defaultFilters: ProfilesFilterState = {
  profileStatus: Object.keys(ProfileStatus),
  profileGroup: [],
  profileType: [ENTITY_PROFILE, INDIVIDUAL_PROFILE],
  createdDateFrom: dayjs().subtract(6, 'month').startOf('day').toDate(),
  createdDateTo: dayjs().endOf('day').toDate(),
  modifiedDateFrom: null,
  modifiedDateTo: null
};

export const emptyFilters: ProfilesFilterState = {
  profileStatus: Object.keys(ProfileStatus),
  profileGroup: [],
  profileType: [ENTITY_PROFILE, INDIVIDUAL_PROFILE],
  createdDateFrom: null,
  createdDateTo: null,
  modifiedDateFrom: null,
  modifiedDateTo: null
};

const SelectableButton: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
  selected: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}> = ({ children, onClick, selected, size = 'md' }) => (
  <Button
    onClick={onClick}
    size={size}
    backgroundColor={selected ? '#BED903' : 'gray.100'}
    sx={{
      _hover: {
        backgroundColor: selected ? '#BED903' : 'gray.200'
      }
    }}
    variant={selected ? 'solid' : 'outline'}
  >
    {children}
  </Button>
);

const ProfilesFilter: React.FC<ProfilesFilterProps> = ({ filters, setFilters }) => {
  const activeOrgID = useSelector(selectActiveOrgID);

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const { data: profileGroups } = useGetProfileGroupsQuery({ orgId: activeOrgID });
  const [localFilters, setLocalFilters] = useState<ProfilesFilterState>(filters);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpen = (): void => {
    setIsMenuOpen(true);
  };

  const handleClose = (): void => {
    setIsMenuOpen(false);
  };

  const handleButtonChange = (field: keyof ProfilesFilterState, option: string): void => {
    setLocalFilters((prev) => {
      const current = prev[field] as string[];
      return current.includes(option)
        ? { ...prev, [field]: current.filter((val) => val !== option) }
        : { ...prev, [field]: [...current, option] };
    });
  };

  const handleApply = (): void => {
    setFilters(localFilters);
    setIsMenuOpen(false);
  };

  const handleClearAll = (): void => {
    setLocalFilters(emptyFilters);
    setFilters(emptyFilters);
    setIsMenuOpen(false);
  };

  return (
    <Menu isOpen={isMenuOpen} onClose={handleClose}>
      <MenuButton
        as={IconButton}
        icon={<Icon icon="gridicons:filter" />}
        onClick={handleOpen}
        color="gray.500"
        aria-label="filter-profiles"
      />
      <MenuList minWidth="380px" p={4}>
        <Text fontWeight="bold" mb={2}>
          Filter Profiles
        </Text>

        <Box mb={4}>
          <Text fontSize="sm" fontWeight="semibold">
            Profile Status
          </Text>
          <Flex gap={2} mt={1} wrap="wrap" maxW="96">
            <Flex key="ALL" flex="1 1 calc(33.33% - 8px)" mb={1}>
              <SelectableButton
                selected={localFilters.profileStatus.length === Object.keys(ProfileStatus).length}
                onClick={() => {
                  if (localFilters.profileStatus.length === Object.keys(ProfileStatus).length) {
                    setLocalFilters({ ...localFilters, profileStatus: [] });
                    return;
                  }
                  setLocalFilters({ ...localFilters, profileStatus: Object.keys(ProfileStatus) });
                }}
                size="xs"
              >
                All
              </SelectableButton>
            </Flex>
            {Object.entries(ProfileStatus).map(([key, value]) => (
              <Flex key={key} flex="1 1 calc(33.33% - 8px)" mb={1}>
                <SelectableButton
                  selected={localFilters.profileStatus.includes(key)}
                  onClick={() => {
                    handleButtonChange('profileStatus', key);
                  }}
                  size="xs"
                >
                  {value}
                </SelectableButton>
              </Flex>
            ))}
          </Flex>
        </Box>

        <Box mb={4}>
          <Text fontSize="sm" fontWeight="semibold">
            Profile Group
          </Text>
          <StyledSelect
            placeholder="Select profile groups"
            isMulti
            options={
              profileGroups?.results?.map((group) => ({
                label: group.name,
                value: group.id
              })) ?? []
            }
            onChange={(selected: any) => {
              setLocalFilters({ ...localFilters, profileGroup: selected.map((s) => s.value) });
            }}
          />
        </Box>

        <Box mb={4}>
          <Text fontSize="sm" fontWeight="semibold">
            Profile Type
          </Text>
          <Flex gap={16} justifyContent="center" mt={1}>
            <SelectableButton
              onClick={() => {
                handleButtonChange('profileType', INDIVIDUAL_PROFILE);
              }}
              size="sm"
              selected={localFilters.profileType.includes(INDIVIDUAL_PROFILE)}
            >
              Individual
            </SelectableButton>
            <SelectableButton
              onClick={() => {
                handleButtonChange('profileType', ENTITY_PROFILE);
              }}
              size="sm"
              selected={localFilters.profileType.includes(ENTITY_PROFILE)}
            >
              Entity
            </SelectableButton>
          </Flex>
        </Box>

        <Box mb={4}>
          <Text fontSize="sm" fontWeight="semibold">
            Created Date Range
          </Text>
          <DatePicker
            selectsRange
            monthsShown={2}
            startDate={localFilters.createdDateFrom ?? undefined}
            endDate={localFilters.createdDateTo ?? undefined}
            onChange={(dates: [Date | null, Date | null]) => {
              setLocalFilters({ ...localFilters, createdDateFrom: dates[0], createdDateTo: dates[1] });
            }}
            renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  onClick={decreaseMonth}
                  aria-label="Previous Month"
                  style={customHeaderCount === 1 ? { visibility: 'hidden' } : {}}
                  size="sm"
                >
                  &lt;
                </Button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString('en-US', {
                    month: 'long',
                    year: 'numeric'
                  })}
                </span>
                <Button
                  onClick={increaseMonth}
                  aria-label="Next Month"
                  style={customHeaderCount === 0 ? { visibility: 'hidden' } : {}}
                  size="sm"
                >
                  &gt;
                </Button>
              </div>
            )}
            customInput={<Input maxW="530px" w="100%" textAlign="center" />}
            dateFormat="yyyy-MM-dd"
            isClearable
            clearButtonClassName="react-datepicker__close-icon"
            placeholderText="Select date range"
          />
        </Box>

        <Box mb={4}>
          <Text fontSize="sm" fontWeight="semibold">
            Last modified Date Range
          </Text>
          <DatePicker
            selectsRange
            monthsShown={2}
            startDate={localFilters.modifiedDateFrom ?? undefined}
            endDate={localFilters.modifiedDateTo ?? undefined}
            onChange={(dates: [Date | null, Date | null]) => {
              setLocalFilters({ ...localFilters, modifiedDateFrom: dates[0], modifiedDateTo: dates[1] });
            }}
            renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  onClick={decreaseMonth}
                  aria-label="Previous Month"
                  style={customHeaderCount === 1 ? { visibility: 'hidden' } : {}}
                  size="sm"
                >
                  &lt;
                </Button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString('en-US', {
                    month: 'long',
                    year: 'numeric'
                  })}
                </span>
                <Button
                  onClick={increaseMonth}
                  aria-label="Next Month"
                  style={customHeaderCount === 0 ? { visibility: 'hidden' } : {}}
                  size="sm"
                >
                  &gt;
                </Button>
              </div>
            )}
            customInput={<Input maxW="530px" w="100%" textAlign="center" />}
            dateFormat="yyyy-MM-dd"
            isClearable
            placeholderText="Select date range"
          />
        </Box>

        <Flex justifyContent="flex-end" gap={2}>
          <Button variant="outline" size="sm" onClick={handleClearAll}>
            Clear
          </Button>
          <Button size="sm" onClick={handleApply} backgroundColor="#BED903">
            Apply
          </Button>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default ProfilesFilter;
