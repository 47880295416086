import { Box, Flex, Text, Tooltip, Grid, Select, Button, useToast } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import * as Sentry from '@sentry/browser';
import RiskBar from './RiskBar.component';
import ExposureTable from './ExposureTable.component';
import LinkedProfiles from './LinkedProfiles.component';
import { cadenceToFriendlyDisplay } from '../utils';
import { useState } from 'react';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useSelector } from 'react-redux';

interface WalletLeftColumnProps {
  data: any;
  updateWallet?: (params: { orgId: string; walletId: string; frequency: string }) => Promise<any> & { unwrap: () => Promise<any> };
  updateWalletIsLoading?: boolean;
}

const calculateExposureDetails = (exposures: any[]) => {
  const totalExposure = exposures.reduce((acc, exposure) => acc + exposure.totalUSDAmount, 0);

  const exposuresByRiskLevel = exposures.reduce(
    (acc, exposure) => {
      const { riskLevel, totalUSDAmount } = exposure;
      if (!acc[riskLevel]) {
        acc[riskLevel] = { amount: 0, percentage: 0 };
      }
      acc[riskLevel].amount += totalUSDAmount;
      acc[riskLevel].percentage = (acc[riskLevel].amount / totalExposure) * 100;
      return acc;
    },
    {
      Low: { amount: 0, percentage: 0 },
      Medium: { amount: 0, percentage: 0 },
      High: { amount: 0, percentage: 0 },
      Severe: { amount: 0, percentage: 0 }
    }
  );

  const directExposures = exposures.filter(({ riskType }) => riskType === 'counterparty');
  const indirectExposures = exposures.filter(({ riskType }) => riskType === 'indirect');
  const ownershipExposures = exposures.filter(({ riskType }) => riskType === 'ownership');

  const directExposureUSD = directExposures.reduce((acc, exposure) => acc + exposure.totalUSDAmount, 0);
  const indirectExposureUSD = indirectExposures.reduce((acc, exposure) => acc + exposure.totalUSDAmount, 0);
  const ownershipExposureUSD = ownershipExposures.reduce((acc, exposure) => acc + exposure.totalUSDAmount, 0);

  const directExposurePercent = (directExposureUSD / totalExposure) * 100;
  const indirectExposurePercent = (indirectExposureUSD / totalExposure) * 100;
  const ownershipExposurePercent = (ownershipExposureUSD / totalExposure) * 100;

  return {
    directExposures,
    indirectExposures,
    ownershipExposures,
    totalExposure,
    directExposureUSD,
    indirectExposureUSD,
    ownershipExposureUSD,
    directExposurePercent: Math.trunc(directExposurePercent * 100) / 100,
    indirectExposurePercent: Math.trunc(indirectExposurePercent * 100) / 100,
    ownershipExposurePercent: Math.trunc(ownershipExposurePercent * 100) / 100,
    exposuresByRiskLevel
  };
};

const WalletLeftColumn: React.FC<WalletLeftColumnProps> = ({ data, updateWallet, updateWalletIsLoading }) => {
  const [riskFilter, setRiskFilter] = useState('all');
  const [isEditingCadence, setIsEditingCadence] = useState(false);
  const [cadenceValue, setCadenceValue] = useState(data.schedule.frequency);
  const toast = useToast();
  const activeOrgId = useSelector(selectActiveOrgID);

  const {
    directExposures,
    indirectExposures,
    ownershipExposures,
    totalExposure,
    directExposureUSD,
    indirectExposureUSD,
    ownershipExposureUSD,
    directExposurePercent,
    indirectExposurePercent,
    ownershipExposurePercent,
    exposuresByRiskLevel
  } = calculateExposureDetails(data.lastInquiry?.result?.exposures ?? []);

  return (
    <Box
      flex="2"
      minW="0"
      maxH={'calc(100vh - 150px)'}
      overflowY="auto"
      overflowX="hidden"
      position="relative"
      pr={4}
      sx={{
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-track': {
          width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray.300',
          borderRadius: '24px'
        }
      }}
    >
      <Box bg="white" p={5} mb={4} borderRadius="md" boxShadow="sm">
        <Text fontWeight="bold" color="gray.700" mb={4}>
          Risk Analysis
        </Text>
        {data.lastInquiry ? (
          <>
            <RiskBar risks={exposuresByRiskLevel} />

            <Flex justify="flex-end" align="center" mt={6} mb={4}>
              <Flex align="center" gap={2}>
                <Text fontSize="sm" color="gray.500">
                  Filter by Risk
                </Text>
                <Select
                  size="sm"
                  w="100px"
                  value={riskFilter}
                  onChange={(e) => setRiskFilter(e.target.value)}
                  borderColor="gray.200"
                >
                  <option value="all">All</option>
                  <option value="severe">Severe</option>
                  <option value="high">High</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </Select>
              </Flex>
            </Flex>

            <Box display="flex" flexDirection="column" gap={6}>
              {data.lastInquiry.result.exposures.length === 0 && <Text>No exposure records found.</Text>}
              {ownershipExposures.length > 0 && (
                <ExposureTable
                  type="ownership"
                  title="Ownership Exposure"
                  items={ownershipExposures}
                  riskFilter={riskFilter}
                  total={{
                    value: ownershipExposureUSD,
                    percent: ownershipExposurePercent
                  }}
                  riskThresholds={data.lastInquiry.riskThresholds}
                />
              )}
              {directExposures.length > 0 && (
                <ExposureTable
                  type="counterparty"
                  title="Counterparty Exposure"
                  items={directExposures}
                  riskFilter={riskFilter}
                  total={{
                    value: directExposureUSD,
                    percent: directExposurePercent
                  }}
                  riskThresholds={data.lastInquiry.riskThresholds}
                />
              )}
              {indirectExposures.length > 0 && (
                <ExposureTable
                  type="indirect"
                  title="Indirect Exposure"
                  items={indirectExposures}
                  riskFilter={riskFilter}
                  total={{
                    value: indirectExposureUSD,
                    percent: indirectExposurePercent
                  }}
                  riskThresholds={data.lastInquiry.riskThresholds}
                />
              )}

              <Box borderTop="1px" borderColor="gray.200" pt={4}>
                <Flex justify="space-between" fontWeight="bold">
                  <Text>TOTAL EXPOSURE</Text>
                  <Text>${totalExposure.toLocaleString()}</Text>
                  <Text>100%</Text>
                </Flex>
              </Box>
            </Box>
          </>
        ) : (
          <Text color="gray.500">No risk analysis available yet.</Text>
        )}
      </Box>
      <LinkedProfiles linkedProfiles={data.profiles} />

      {/* Screening Settings Card */}
      <Box bg="white" p={5} mb={4} borderRadius="md" boxShadow="sm">
        <Flex alignItems="center" mb={4}>
          <Text fontWeight="bold" color="gray.700">
            Screening Settings
          </Text>
          <Tooltip label="Information about screening settings">
            <InfoIcon ml={2} color="gray.400" fontSize="sm" />
          </Tooltip>
        </Flex>
        <Flex flexDirection="column" gap={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <Box layerStyle="subContainer">
              <Text fontSize="xs" color="gray.500" mb={1}>
                First Screening
              </Text>
              <Text fontWeight="medium">{format(new Date(data.createdAt), 'MMM d, yyyy')}</Text>
            </Box>
            <Box layerStyle="subContainer">
              <Text fontSize="xs" color="gray.500" mb={1}>
                Last Screening
              </Text>
              <Text fontWeight="medium">{data.lastInquiry?.createdAt ? format(new Date(data.lastInquiry.createdAt), 'MMM d, yyyy') : 'Never'}</Text>
            </Box>
          </Grid>
          <Box>
            <Text fontSize="xs" color="gray.500" mb={1}>
              Screening Frequency
            </Text>
            {!isEditingCadence ? (
              <Box position="relative" border="1px" borderColor="gray.200" borderRadius="md" p={2}>
                <Flex justify="space-between" align="center">
                  <Text>{cadenceToFriendlyDisplay(data.schedule.frequency)}</Text>
                  {updateWallet && (
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => setIsEditingCadence(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Flex>
              </Box>
            ) : (
              <Box>
                <Select
                  size="sm"
                  mb={2}
                  value={cadenceValue}
                  onChange={(e) => setCadenceValue(e.target.value)}
                >
                  <option value="N">None</option>
                  <option value="D">Daily</option>
                  <option value="W">Weekly</option>
                  <option value="M">Monthly</option>
                </Select>
                <Flex gap={2}>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => {
                      setCadenceValue(data.schedule.frequency);
                      setIsEditingCadence(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="green"
                    isLoading={updateWalletIsLoading}
                    onClick={async () => {
                      try {
                        if (updateWallet) {
                          await updateWallet({
                            orgId: activeOrgId as string,
                            walletId: data.id,
                            frequency: cadenceValue
                          }).unwrap();
                          setIsEditingCadence(false);
                        }
                      } catch (err) {
                        toast({
                          status: 'error',
                          title: 'Error',
                          description: 'Failed to update screening frequency',
                          isClosable: true
                        });
                        Sentry.captureException(err);
                      }
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default WalletLeftColumn;
